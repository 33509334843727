import {
  Button,
  Col,
  Drawer,
  Dropdown,
  Form,
  Image,
  Input,
  Row,
  Space,
  Typography,
  List,
  Flex,
  Divider,
  Card,
} from 'antd';
import styles from '../../style/mainHeader.module.scss';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import {
  BellOutlined,
  CalendarOutlined,
  HomeOutlined,
  MessageOutlined,
  ProfileOutlined,
  ShopOutlined,
  TeamOutlined,
  CloseOutlined,
  UserOutlined,
} from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  REQUIRED_RULE,
  specialCharacters,
  truncateText,
} from '../../helpers/constants/constantHelpers';
import { CountrySelector, SelectComponent } from '../form/filters/Inputs';
import { useContext, useState, useEffect } from 'react';
import { AuthContext } from '../../providers/AuthProvider';
import { faUsersViewfinder } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import defaultProfilePhoto from '../../assets/images/defaultProfilePhoto.jpeg';
import { useGetNotifications, useGetRequests } from '../../hooks/queries/UserQueries';
import { UtilityContext } from '../../providers/UtilityProvider';
import { uploadFile } from '../../requests/UtilityManager';
import { useClientError } from '../../hooks/useClientError';
import { useCreateOrganizations } from '../../hooks/queries/OrganizationQueries';
import { useGetOrganizationDetailByCodeHandler } from '../../hooks/queries/OrganizationQueries';
import CropperComponent from '../../helpers/utils/CropperComponent.tsx';

const { Text, Link } = Typography;

const LinksWithProfileMobile = () => {
  const { user, logout, userOwnedPages, hasUnreadMessages = [] } = useContext(AuthContext);
  const [teamDrawerOpen, setTeamDrawerOpen] = useState(false);
  const [userDrawerOpen, setUserDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [codeSlug, setCodeSlug] = useState('');
  const [teamForm] = Form.useForm();
  const [imageObject, setImageObject] = useState(null);
  const translator = useIntl();
  const { data: notifications } = useGetNotifications(user?.id);
  const { data: requests = [] } = useGetRequests(user?.id);
  const { openNotification, organizationCategories, theme } = useContext(UtilityContext);
  const { mutateAsync: createOrganization, isPending: createOrganizationPending } =
    useCreateOrganizations(user?.id);
  const { data: organizationByCode } = useGetOrganizationDetailByCodeHandler(codeSlug);

  const onClientError = useClientError();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const teamNameSlugCreator = (inputValue) => {
    //Karakterler ingilizce karaktere çevrilmeli, küçük I küçük i olmalı Ü u olmalı gibi
    let charMap = {
      Ç: 'C',
      Ö: 'O',
      Ş: 'S',
      İ: 'I',
      I: 'i',
      Ü: 'U',
      Ğ: 'G',
      ç: 'c',
      ö: 'o',
      ş: 's',
      ı: 'i',
      ü: 'u',
      ğ: 'g',
    };

    let str_array = inputValue.split('');

    for (let i = 0, len = str_array.length; i < len; i++) {
      str_array[i] = charMap[str_array[i]] || str_array[i];
    }

    let newValue = str_array.join('').replace(/[çöşüğı]/gi, '');
    let slug = newValue.toLocaleLowerCase().replaceAll(' ', '-');

    if (slug[slug.length - 1] !== '-') {
      setCodeSlug(slug);
    }
  };

  useEffect(() => {
    if (codeSlug !== '' && organizationByCode?.code === codeSlug) {
      let randomNum = Math.floor(Math.random() * 10);
      setCodeSlug(`${codeSlug}_${randomNum}`);
    }
    teamForm.setFieldValue('code', codeSlug);
  }, [codeSlug, organizationByCode]);

  const createTeamHandler = () => {
    let formValues = teamForm.getFieldsValue();
    if (specialCharacters.test(formValues.name)) {
      openNotification('organizationNameNotAccepted', 'error');
    } else {
      teamForm.submit();
    }
  };

  const createOrganizationHandler = (formValues) => {
    setLoading(true);
    if (imageObject?.length === 0 || !imageObject) {
      setLoading(false);
      return openNotification('missingLogo', 'error');
    }

    uploadFile(imageObject)
      .then((response) => {
        if (response.status === 202) {
          formValues['logo_url'] = response.data.data;
          formValues['userId'] = user?.id;
          formValues['founder_id'] = user?.id;
          createOrganization(formValues)
            .then((response) => {
              if (response.status === 202) {
                openNotification('organizationCreated', 'success');
              } else {
                openNotification('organizationCreateFailed', 'error');
              }
            })
            .catch((err) => {
              onClientError(err);
            })
            .finally(() => {
              teamForm.resetFields();
              setCodeSlug('');
              setTeamDrawerOpen(false);
              setLoading(false);
            });
        }
      })
      .catch((err) => {
        onClientError(err);
        setLoading(false);
      });
  };

  const organizationLinks = userOwnedPages.map((page) => (
    <NavLink
      key={page.id}
      to={`/organization/${page.code}`}
      onClick={() => setUserDrawerOpen(false)}
    >
      <Text>{truncateText(page.name, 20)}</Text>
    </NavLink>
  ));

  return (
    <div className={`${styles['mobileMenu']} w-full`}>
      <Card bordered={true} className='!p-0 [&>.ant-card-body]:!p-0'>
        <div className={styles['mainHeader']}>
          <Col
            id='linkWrapper'
            className={styles['linkWrapper']}
            xxl={14}
            xl={14}
            lg={14}
            md={24}
            sm={24}
            xs={24}
          >
            <NavLink
              className='flex-col items-center'
              to='/feed'
              onClick={() => {
                if (pathname === '/feed') {
                  navigate('/feed');
                  window.location.reload();
                }
              }}
            >
              <HomeOutlined className={styles['linkIcon']} />
            </NavLink>
            {/*<NavLink className={styles['disabledLink']} to='/lobby'>
            <FontAwesomeIcon icon={faUsersViewfinder} />
          </NavLink>*/}
            <NavLink className='flex flex-col items-center' to='/events'>
              <CalendarOutlined className={styles['linkIcon']} />
            </NavLink>
            <NavLink className='flex flex-col items-center' to='/messages'>
              <MessageOutlined className={styles['linkIcon']} />
            </NavLink>
            <NavLink className=' flex flex-col items-center' to='/notifications'>
              <div className={styles['notifications']}>
                <BellOutlined className={styles['linkIcon']} />
                {notifications?.filter((x) => !x.read).length > 0 || requests.length > 0 ? (
                  <i></i>
                ) : (
                  ''
                )}
              </div>
            </NavLink>
            <div className={styles['userDropdownWrapper']}>
              <Col span={24} className={styles['userInfoWrapper']}>
                <Image
                  className='rounded-full'
                  width={50}
                  height={50}
                  preview={false}
                  src={
                    user?.userPhotos && user.userPhotos[0]?.profile_photo
                      ? user?.userPhotos && user.userPhotos[0]?.profile_photo
                      : defaultProfilePhoto
                  }
                  onClick={() => setUserDrawerOpen(true)}
                />
              </Col>
            </div>
            <Drawer
              closeIcon={<CloseOutlined className='duration-300' />}
              width={720}
              destroyOnClose={true}
              onClose={() => setUserDrawerOpen(false)}
              open={userDrawerOpen}
            >
              <Flex vertical={true} align='center' gap='2rem'>
                <div className='flex'>
                  <UserOutlined className='mr-2' />
                  <FormattedMessage id='account' />
                </div>
                <NavLink to={`/profile/${user?.username}`} onClick={() => setUserDrawerOpen(false)}>
                  <Text>
                    <FormattedMessage id='seeProfile' />
                  </Text>
                </NavLink>
                <NavLink to={'/settings'} onClick={() => setUserDrawerOpen(false)}>
                  <Text>
                    <FormattedMessage id='settings' />
                  </Text>
                </NavLink>
                <Link onClick={logout}>
                  <Text>
                    <FormattedMessage id='logout' />
                  </Text>
                </Link>
                <Divider className='my-0' />
                <div className='flex'>
                  <TeamOutlined className='mr-2' />
                  <FormattedMessage id='team' />
                </div>
                <Button
                  className={`${styles['ghostButtonText']} `}
                  onClick={() => setTeamDrawerOpen(true)}
                >
                  <Text>
                    <FormattedMessage id='createOrganization' />
                  </Text>
                </Button>
                <Divider className='my-0' />
                <div className='flex'>
                  <ShopOutlined className='mr-2' />
                  <FormattedMessage id='myOrganizations' />
                </div>
                {organizationLinks}
              </Flex>
            </Drawer>

            <Drawer
              closeIcon={<CloseOutlined className='duration-300' />}
              title={translator.formatMessage({ id: 'createOrganization' })}
              width={720}
              destroyOnClose={true}
              onClose={() => setTeamDrawerOpen(false)}
              open={teamDrawerOpen}
              extra={
                <Space>
                  <Button onClick={() => setTeamDrawerOpen(false)}>
                    <Text className='text-inherit'>
                      <FormattedMessage id='cancel' />
                    </Text>
                  </Button>
                  <Button loading={loading} onClick={createTeamHandler} type='primary'>
                    <Text className='text-inherit'>
                      <FormattedMessage id='submit' />
                    </Text>
                  </Button>
                </Space>
              }
            >
              <Form
                initialValues={{
                  slug: translator.formatMessage({ id: 'organizationUrl' }),
                }}
                form={teamForm}
                layout='vertical'
                onFinish={createOrganizationHandler}
              >
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item
                      name='name'
                      label={translator.formatMessage({ id: 'name' })}
                      rules={[
                        { required: true, message: '' },
                        {
                          max: 30,
                          message: translator.formatMessage({ id: 'formLengthMessageShort' }),
                        },
                      ]}
                    >
                      <Input
                        onChange={(element) => teamNameSlugCreator(element.target.value)}
                        placeholder={translator.formatMessage({ id: 'enterOrganizationName' })}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item name='code' label={translator.formatMessage({ id: 'yourUrl' })}>
                      <Input
                        disabled={true}
                        placeholder={translator.formatMessage({ id: 'organizationUrl' })}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <CountrySelector isRequired={true} formName='country' />
                  </Col>
                  <Col span={24}>
                    <SelectComponent
                      labelId='category'
                      translationId='category'
                      serviceUrl='getCategories'
                      options={organizationCategories}
                      fieldNames={{
                        label: 'name',
                        value: 'id',
                      }}
                      formName='category_id'
                      required={true}
                    />
                  </Col>
                </Row>
                <Row gutter={16}>
                  <Col span={24}>
                    <Form.Item
                      name='description'
                      label={translator.formatMessage({ id: 'description' })}
                      rules={[
                        {
                          max: 250,
                          message: translator.formatMessage({ id: 'formLengthMessageLong' }),
                        },
                      ]}
                    >
                      <Input.TextArea
                        style={{ height: 100 }}
                        rows={4}
                        placeholder={translator.formatMessage({ id: 'enterDescriptionForTeam' })}
                      />
                    </Form.Item>
                    <CropperComponent
                      isLoading={loading}
                      label='addTeamLogo'
                      returnedData={(value) => setImageObject(value)}
                      formName='logo_url'
                      required={true}
                      forOrganization={true}
                    />
                  </Col>
                </Row>
              </Form>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Text italic type='secondary'>
                    <FormattedMessage id='organizationPageDescription' />
                  </Text>
                </Col>
                <Col span={24}>
                  <Text italic type='secondary'>
                    <FormattedMessage id='organizationPageSettingInfo' />
                  </Text>
                </Col>
              </Row>
            </Drawer>
          </Col>
        </div>
      </Card>
    </div>
  );
};

export default LinksWithProfileMobile;
