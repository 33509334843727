import { IntlProvider } from 'react-intl';
import { Layout, ConfigProvider } from 'antd';
import AuthProvider, { AuthContext } from './providers/AuthProvider';
import InnerLayout from './layouts/InnerLayout';
import ScrollToTop from './components/hoc/ScrollToTop';
import MainHeader from './components/header/MainHeader';
import useIsPath from './hooks/useIsPath';
import UtilityProvider, { UtilityContext } from './providers/UtilityProvider';
import { intlLanguageList } from './helpers/constants/localization';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import 'dayjs/locale/tr';
import dayjs from 'dayjs';
import ModalProvider from './providers/ModalProvider';
import DrawerProvider from './providers/DrawerProvider';
import { useEffect, useState } from 'react';
import usePageSEO from './hooks/usePageSEO';
import LayoutContent from './layouts/LayoutContent';

const queryClient = new QueryClient();

function App() {
  const { Content } = Layout;
  const [lang, setLang] = useState('tr');

  usePageSEO({
    title: 'Game Actor',
    description: 'View the latest posts on Game Actor.',
    keywords: ['game', 'actor', 'posts', 'gaming', 'social', 'media', 'esport'],
    ogTitle: 'Game Actor',
    ogDescription: 'View the latest posts on Game Actor.',
    ogImage: 'src/assets/images/game-actor-star-circle.png',
    ogUrl: window.location.href,
  });

  useEffect(() => {
    let language = localStorage.getItem('language');

    if (language) {
      setLang(language);
    } else {
      if (navigator.language === 'tr-TR' || navigator.language === 'tr') {
        setLang('tr');
        localStorage.setItem('language', 'tr');
      } else if (navigator.language === 'es-ES' || navigator.language === 'es') {
        setLang('es');
        localStorage.setItem('language', 'es');
      } else {
        setLang('en');
        localStorage.setItem('language', 'en');
      }
    }
    if (language === 'tr') {
      dayjs.locale('tr');
    } else if (language === 'en') {
      dayjs.locale('en');
    } else if (language === 'es') {
      dayjs.locale('es');
    }
  }, [localStorage.getItem('language')]);

  return (
    <QueryClientProvider client={queryClient}>
      <ModalProvider>
        <DrawerProvider>
          <IntlProvider locale={lang} messages={intlLanguageList[lang]}>
            <AuthProvider>
              <UtilityProvider>
                <LayoutContent lang={lang} />
              </UtilityProvider>
            </AuthProvider>
          </IntlProvider>
        </DrawerProvider>
      </ModalProvider>
    </QueryClientProvider>
  );
}

export default App;
