import styles from '../../../style/chat.module.scss';
import { Flex, Image, Typography } from 'antd';
import profileAvatar from '../../../assets/images/defaultProfilePhoto.jpeg';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';

const { Paragraph } = Typography;

const UserMessage = ({ owner, content, isMobile = false, otherUser, user }) => {
  const sanitizedHtml = parse(DOMPurify.sanitize(content, { ADD_TAGS: ['iframe'] }));

  if (isMobile) {
    return (
      <Flex gap='.5rem'>
        {!owner && (
          <Image
            preview={false}
            className='rounded-[8px] min-w-[40px]'
            width={40}
            height={30}
            src={
              otherUser?.userPhotos[0]?.profile_photo
                ? otherUser?.userPhotos[0]?.profile_photo
                : profileAvatar
            }
          />
        )}
        <div className={styles['messageContent']}>
          <Paragraph
            className={
              owner
                ? '[&>p]:mb-0 max-w-60 p-2 rounded-[8px] bg-container-color dark:bg-dark-mode-light-container-color'
                : '[&>p]:mb-0 max-w-60 p-2 rounded-[8px] bg-container-color dark:bg-dark-mode-light-container-color'
            }
          >
            {sanitizedHtml}
          </Paragraph>
        </div>
      </Flex>
    );
  } else {
    return (
      <div>
        <Paragraph
          className={
            owner
              ? '[&>p]:mb-0 max-w-60 ml-auto p-2 rounded-[8px] bg-container-color dark:bg-dark-mode-light-container-color'
              : '[&>p]:mb-0 max-w-60 p-2 rounded-[8px] bg-container-color dark:bg-dark-mode-light-container-color'
          }
        >
          {sanitizedHtml}
        </Paragraph>
      </div>
    );
  }
};

export default UserMessage;
