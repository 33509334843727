import { Col, Divider, Flex, Image, Row, Skeleton, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import styles from '../../style/notifications.module.scss';
import { useUserDetailsWithId, useUserDetailsWithCode } from '../../hooks/queries/UserQueries';
import { dateFormatter } from '../../helpers/utils/DateFormatter';
import { FieldTimeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import defaultProfilePhoto from '../../assets/images/defaultProfilePhoto.jpeg';
import { useGetFeedDetails } from '../../hooks/queries/FeedQueries';
import SpinWrapper from '../utility/wrappers/SpinWrapper';
import { useGetOrganizationDetailByCodeHandler } from '../../hooks/queries/OrganizationQueries';

const MentionNotification = ({ notification }) => {
  const { data: feedDetails } = useGetFeedDetails(parseInt(notification.related_parameter));
  const { data: profileDetailsById } = useUserDetailsWithId(notification.extra_parameter);
  const { data: profileDetailsByCode } = useUserDetailsWithCode(notification.extra_parameter);
  const { data: organizationInformationByCode } = useGetOrganizationDetailByCodeHandler(
    notification.extra_parameter,
  );

  const profileDetails = profileDetailsById || profileDetailsByCode;
  const Text = Typography;

  let timeStamp = dateFormatter(notification.created_at);

  if (!profileDetails?.id && !organizationInformationByCode?.id) {
    return <SpinWrapper />;
  }

  const profilePhoto = profileDetails
    ? profileDetails?.userPhotos?.length > 0
      ? profileDetails?.userPhotos[0]?.profile_photo?.length > 0
        ? profileDetails?.userPhotos[0]?.profile_photo
        : defaultProfilePhoto
      : defaultProfilePhoto
    : organizationInformationByCode?.logo_url
    ? organizationInformationByCode?.logo_url
    : defaultProfilePhoto;

  const name = profileDetails?.id
    ? `${profileDetails?.first_name} ${profileDetails?.last_name}`
    : organizationInformationByCode?.name;

  return (
    <div className='w-full margin-0 cursor-default'>
      <Flex gap='1rem'>
        <Link to={`/profile/${notification?.extra_parameter}`}>
          <Image
            className='rounded-full object-cover'
            width={40}
            height={40}
            preview={false}
            placeholder={<Skeleton avatar active />}
            src={profilePhoto}
          />
        </Link>
        <Link to={`/shared/${feedDetails?.share}`}>
          <Text>
            {name} <FormattedMessage id='mentionNotification' />
          </Text>
        </Link>
      </Flex>
      <div className={styles['timeStamp']}>
        <Text className={styles['timeStampText']}>
          <FieldTimeOutlined /> {timeStamp[0]} <FormattedMessage id={timeStamp[1]} />
        </Text>
      </div>
    </div>
  );
};

export default MentionNotification;
