import axiosInstance from '../lib/service/axios';

export const getUserOrganizationsHandler = async (userId) => {
  let formObject = {
    action: 'getUserOrganizations',
    getOrganizationsPayload: {
      userId: userId,
      isManager: 1,
    },
  };
  const response = await axiosInstance.post('securedHandle', formObject);
  return response.data.data;
};

export const createOrganization = async (formValues) => {
  let formObject = {
    action: 'createOrganization',
    organizationpayload: formValues,
  };
  return await axiosInstance.post('securedHandle', formObject);
};

export const updateOrganization = async (formValues) => {
  let formObject = {
    action: 'updateOrganization',
    organizationpayload: formValues,
  };
  return await axiosInstance.post('securedHandle', formObject);
};

export const deleteOrganization = async (formValues) => {
  let formObject = {
    action: 'deleteorganization',
    organizationpayload: formValues,
  };
  return await axiosInstance.post('securedHandle', formObject);
};

export const getOrganizationsDetail = async (organizationId, page) => {
  let formObject = {
    action: 'organizationsDetail',
    users: {
      userIds: organizationId,
      page: page,
    },
  };
  const response = await axiosInstance.post('securedHandle', formObject);
  return response.data.data;
};

export const getOrganizationFollowers = async (organizationId) => {
  let formObject = {
    action: 'organizationUsers',
    organizationUsers: {
      organizationId: organizationId,
      isManager: 0,
      isMember: 0,
    },
  };
  const response = await axiosInstance.post('securedHandle', formObject);
  return response.data.data;
};

export const getOrganizationMembers = async (organizationId) => {
  let formObject = {
    action: 'organizationUsers',
    organizationUsers: {
      organizationId: organizationId,
      isMember: 1,
    },
  };
  const response = await axiosInstance.post('securedHandle', formObject);
  return response.data.data;
};

export const getOrganizationDetailByCode = async (code) => {
  let formObject = {
    action: 'organizationCode',
    name: {
      name: code,
    },
  };
  const response = await axiosInstance.post('securedHandle', formObject);
  return response.data.data;
};

export const getOrganizationMembersDetail = async (userArray, organizationId) => {
  let formObject = {
    action: 'organizationUsersDetail',
    organizationUser: {
      userIds: userArray,
      organizationId: organizationId,
    },
  };
  const response = await axiosInstance.post('securedHandle', formObject);
  return response.data.data.followDetails;
};

export const handleOrganizationMember = async (
  userIds,
  organizationId,
  role,
  isManager,
  isMember,
) => {
  let formObject = {
    action: 'setUserOrganizationRole',
    organizationUser: {
      userIds: userIds,
      organizationId: organizationId,
      role: role,
      isManager: isManager,
      isMember: isMember,
    },
  };
  return await axiosInstance.post('securedHandle', formObject);
};

export const getOrganizationFollowerDetail = async (followers) => {
  let formObject = {
    action: 'followerDetail',
    users: {
      userIds: followers,
    },
  };

  const response = await axiosInstance.post('securedHandle', formObject);
  return response.data.data.followDetails;
};

export const getOrganizationCategories = async () => {
  let formObject = {
    action: 'getCategories',
  };
  const response = await axiosInstance.post('securedHandle', formObject);
  return response.data.data;
};

export const followOrganization = async (formValues) => {
  let formObject = {
    action: 'followOrganization',
    followorganization: {
      userId: formValues.userId,
      organization_id: formValues.organization_id,
    },
  };
  return await axiosInstance.post('securedHandle', formObject);
};

export const getOrganizationGallery = async (organizationId, page) => {
  let formObject = {
    action: 'getFeedAttachments',
    getFeed: {
      organizationId: organizationId,
      page: page,
      limit: 6,
    },
  };

  const response = await axiosInstance.post('securedHandle', formObject);
  return response.data.data;
};

export const inviteConnectionsHandler = async (organizationId, connectionsId) => {
  let formObject = {
    action: 'inviteToOrganization',
    organizationUser: {
      userIds: connectionsId,
      organizationId: organizationId,
    },
  };

  const response = await axiosInstance.post('securedHandle', formObject);
  return response;
};
