import { useEffect, useRef } from 'react';

const OutsideClickDetector = ({ onOutsideClick, children }) => {
  const buttonRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        buttonRef.current &&
        !buttonRef.current.contains(event.target) &&
        event.target?.srcElement?.className !== 'gpr-category-list' &&
        event.target.className !== 'gpr-category-name' &&
        event.target.className !== 'gpr-category-overlay' &&
        event.target.innerText !== 'Trending GIFs'
      ) {
        onOutsideClick();
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [onOutsideClick]);

  return <div ref={buttonRef}>{children}</div>;
};

export default OutsideClickDetector;
