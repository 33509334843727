import { Flex, Image, Skeleton, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import styles from '../../style/notifications.module.scss';
import { dateFormatter } from '../../helpers/utils/DateFormatter';
import { FieldTimeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useGetOrganizationDetailByCodeHandler } from '../../hooks/queries/OrganizationQueries';
import defaultOrganizationPhoto from '../../assets/images/defaultOrganizationPhoto.png';
import SpinWrapper from '../utility/wrappers/SpinWrapper';

const OrganizationRoleUpdateNotification = ({ notification }) => {
  const { data: organizationDetails } = useGetOrganizationDetailByCodeHandler(
    notification.related_parameter,
  );
  const Text = Typography;

  let timeStamp = dateFormatter(notification.created_at);

  if (!organizationDetails?.code) {
    return <SpinWrapper />;
  }

  if (!organizationDetails?.code) {
    return (
      <div className='w-full p-4 border-0 border-b border-solid border-white-gray margin-0 cursor-default'>
        <Flex gap='1rem'>
          <Link to={`/organization/${organizationDetails?.code}`}>
            <Image
              className='rounded-full object-cover'
              width={40}
              height={40}
              preview={false}
              src={defaultOrganizationPhoto}
              placeholder={<Skeleton avatar active />}
            />
          </Link>
          <Link to={`/organization/${organizationDetails?.code}`}>
            <Text>
              <FormattedMessage id='deletedOrganization' /> {}
              <FormattedMessage id='organizationRoleUpdateNotification' />
            </Text>
          </Link>
        </Flex>
        <div className={styles['timeStamp']}>
          <Text className={styles['timeStampText']}>
            <FieldTimeOutlined /> {timeStamp[0]} <FormattedMessage id={timeStamp[1]} />
          </Text>
        </div>
      </div>
    );
  } else {
    return (
      <div className='w-full p-4 border-0 border-b border-solid border-white-gray margin-0 cursor-default'>
        <Flex gap='1rem'>
          <Link to={`/organization/${organizationDetails?.code}`}>
            <Image
              className='rounded-[8px] object-cover'
              width={40}
              height={40}
              preview={false}
              placeholder={<Skeleton avatar active />}
              src={
                organizationDetails?.logo_url
                  ? organizationDetails?.logo_url
                  : defaultOrganizationPhoto
              }
            />
          </Link>
          <Link to={`/organization/${organizationDetails?.code}`}>
            <Text>
              {organizationDetails?.name} {}
              <FormattedMessage id='organizationRoleUpdateNotification' />
            </Text>
          </Link>
        </Flex>
        <div className={styles['timeStamp']}>
          <Text className={styles['timeStampText']}>
            <FieldTimeOutlined /> {timeStamp[0]} <FormattedMessage id={timeStamp[1]} />
          </Text>
        </div>
      </div>
    );
  }
};

export default OrganizationRoleUpdateNotification;
