import PrimaryCard from '../cards/PrimaryCard';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BoostedStamp from '../utility/BoostedStamp';
import CreateComment from './CreateComment';
import { useDeleteFeed, useDeleteOrganizationFeed } from '../../hooks/queries/FeedQueries';
import {
  DeleteTwoTone,
  LeftOutlined,
  LinkOutlined,
  MessageOutlined,
  RightOutlined,
  SendOutlined,
  SettingTwoTone,
  EditTwoTone,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  SettingOutlined,
  HeartTwoTone,
  HeartFilled,
} from '@ant-design/icons';
import {
  Button,
  Col,
  Divider,
  Drawer,
  Dropdown,
  Flex,
  Image,
  message,
  Modal,
  Popover,
  Space,
  Typography,
} from 'antd';
import { faBomb } from '@fortawesome/free-solid-svg-icons';
import { UtilityContext } from '../../providers/UtilityProvider';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import Carousel from 'nuka-carousel';
import variables from '../../style/_variables.module.scss';
import styles from '../../style/postpage.module.scss';
import layoutStyles from '../../style/layout.module.scss';
import { AuthContext } from '../../providers/AuthProvider';
import { getRelativeTime } from '../../helpers/utils/TimeFormatters';
import defaultProfilePhoto from '../../assets/images/defaultProfilePhoto.jpeg';
import { useClientError } from '../../hooks/useClientError';
import SpinWrapper from '../utility/wrappers/SpinWrapper';
import {
  FacebookShareButton,
  FacebookIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TelegramShareButton,
  TelegramIcon,
  TwitterShareButton,
  XIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from 'react-share';
import GameActorStar from '../../assets/svg/game-actor-star.svg';
import CreatePostForm from '../form/post/CreatePostForm';
import { ReactSVG } from 'react-svg';
import { useGetLikes, useLikeHandler } from '../../hooks/queries/PostActionQueries';
import ListUsers from '../feed/ListUsers';
import VideoPlayer from './VideoPlayer';
import useWindowSize from '../../hooks/useWindowSize';
import EditPostForm from '../form/post/EditPostForm';
import { differenceInMinutes } from 'date-fns';

const { Text, Paragraph } = Typography;

const PostCard = ({
  boosted = false,
  reposted = false,
  edited = false,
  postId,
  postDetail,
  repostLoading,
}) => {
  const { openNotification, theme } = useContext(UtilityContext);
  const { user, userOwnedPages } = useContext(AuthContext);
  const { data: likes, fetchNextPage, isLoading: likeListLoading } = useGetLikes(postId, user?.id);
  const { mutate: likeAndMutate } = useLikeHandler();
  const { mutateAsync: deleteFeed, isLoading } = useDeleteFeed();
  const { mutateAsync: deleteOrganizationFeed, isOrganizationLoading } = useDeleteOrganizationFeed(
    postDetail?.organizationId,
  );
  const [hitList, setHitList] = useState(false);
  const [repost, setRepost] = useState(false);
  const [commentSection, setCommentSection] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editModal, setEditModal] = useState(false);

  const { organizationCategories } = useContext(UtilityContext);
  const contentRef = useRef(null);
  const videoPlayerRef = useRef();
  const translator = useIntl();

  const onClientError = useClientError();

  const { width } = useWindowSize();
  const fromUser = postDetail?.userId;
  const isOwner =
    postDetail?.userId === user?.id ||
    userOwnedPages.find((page) => page.id === postDetail?.organizationId);

  let imageUrl = fromUser
    ? postDetail?.user?.userPhotos[0]?.profile_photo
    : postDetail?.organization?.logo_url;
  let fullName = fromUser
    ? postDetail?.user?.first_name + ' ' + postDetail?.user?.last_name
    : postDetail?.organization?.name;
  let label = fromUser
    ? postDetail?.user?.username
    : organizationCategories?.find(
        (category) => category.id === postDetail?.organization?.categoryId,
      )?.name;
  let profileLink = fromUser
    ? `/profile/${postDetail?.user?.username}`
    : `/organization/${postDetail?.organization?.code}`;

  const { focusedPrimaryColor } = variables;

  useEffect(() => {
    const divElement = contentRef.current?.querySelector('div');

    const iframeElement = divElement?.querySelector('iframe');

    if (iframeElement) {
      const src = iframeElement.src;
      if (!src.includes('enablejsapi=1')) {
        iframeElement.src = src.includes('?') ? `${src}&enablejsapi=1` : `${src}?enablejsapi=1`;
      }

      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (!entry.isIntersecting) {
            iframeElement?.contentWindow?.postMessage(
              '{"event":"command","func":"pauseVideo","args":""}',
              '*',
            );
          }
        });
      });

      observer.observe(iframeElement);
      return () => observer.unobserve(iframeElement);
    }
  }, [contentRef?.current]);

  useEffect(() => {
    const videoElement = videoPlayerRef?.current;
    if (videoElement) {
      const observer = new IntersectionObserver((entries) => {
        entries.forEach((entry) => {
          if (!entry.isIntersecting) {
            if (!videoElement?.paused) {
              videoElement?.pause();
            }
          }
        });
      });

      observer.observe(videoElement);
      return () => observer.unobserve(videoElement);
    }
  }, [postDetail?.feedAttachments]);

  useEffect(() => {
    if (!postDetail?.content) {
      if (contentRef.current) {
        contentRef.current.innerHTML = null;
      }
      return;
    }

    let message = postDetail.content;
    const regex = /(<[^>]+>)|(\B#\w\w+\b)/g;

    message = message.replace(regex, (match, htmlTag, hashtag) => {
      if (htmlTag) {
        return htmlTag;
      }
      if (hashtag) {
        return `<a href='/hash/${hashtag.replace('#', '$')}' class='hashtag_link'>${hashtag}</a>`;
      }
      return match;
    });

    if (contentRef.current) {
      contentRef.current.innerHTML = message;
    }

    if (edited && contentRef.current) {
      contentRef.current.innerHTML = null;
    }
  }, [postDetail]);

  if (reposted && repostLoading) {
    return <SpinWrapper />;
  }

  const copyToClipboardHandler = (shareUid) => {
    navigator.clipboard.writeText(`https://www.game.actor/shared/${shareUid}`);
    openNotification('shareLinkCopied', 'success');
  };

  const items = [
    {
      key: '1',
      label: (
        <Flex align='center' gap='1rem'>
          <Button
            className='rounded-full'
            onClick={() => copyToClipboardHandler(postDetail?.share)}
            icon={<LinkOutlined />}
          />
          <Button
            className='rounded-full'
            onClick={() => setRepost(true)}
            icon={<ReactSVG src={GameActorStar} />}
          />
          <FacebookShareButton url={`https://www.game.actor/shared/${postDetail?.share}`}>
            <FacebookIcon size={32} round={true} />
          </FacebookShareButton>
          <LinkedinShareButton url={`https://www.game.actor/shared/${postDetail?.share}`}>
            <LinkedinIcon size={32} round={true} />
          </LinkedinShareButton>
          <TelegramShareButton url={`https://www.game.actor/shared/${postDetail?.share}`}>
            <TelegramIcon size={32} round={true} />
          </TelegramShareButton>
          <TwitterShareButton url={`https://www.game.actor/shared/${postDetail?.share}`}>
            <XIcon size={32} round={true} />
          </TwitterShareButton>
          <WhatsappShareButton url={`https://www.game.actor/shared/${postDetail?.share}`}>
            <WhatsappIcon size={32} round={true} />
          </WhatsappShareButton>
        </Flex>
      ),
    },
  ];

  let hitIcon = (
    <FontAwesomeIcon
      icon={faBomb}
      style={{ color: likes?.pages[0].is_liked ? '#c31d1d' : '#959595', marginRight: '.5rem' }}
    />
  );

  const commentSectionHandler = () => {
    setCommentSection(!commentSection);
  };

  const likeHandler = () => {
    likeAndMutate(postId);
  };

  const deletePostHandler = () => {
    if (postDetail?.organizationId > 0) {
      deleteOrganizationFeed(postId)
        .then((response) => {
          if (response.status === 202) {
            openNotification('feedDeleted', 'success');
          } else {
            openNotification('feedDeleteFailed', 'error');
          }
        })
        .catch((err) => {
          onClientError(err);
        });
    } else {
      deleteFeed(postId)
        .then((response) => {
          if (response.status === 202) {
            openNotification('feedDeleted', 'success');
          } else {
            openNotification('feedDeleteFailed', 'error');
          }
        })
        .catch((err) => {
          onClientError(err);
        });
    }
    setDeleteModal(false);
  };

  const popOverContent = (
    <Flex align='start' vertical={true} gap='.5rem'>
      <Button
        onClick={() => setDeleteModal(true)}
        className='p-2 font-thin !justify-start w-full'
        type='link'
        icon={<DeleteOutlined className='cursor-pointer text-red-600' />}
      >
        <Text>
          <FormattedMessage id='remove' />
        </Text>
      </Button>
      <Button
        onClick={() => setEditModal(true)}
        className='p-2 font-thin !justify-start w-full'
        type='link'
        icon={
          <EditOutlined className='cursor-pointer text-yellow-600 hover:bg-yellow-200 p-2 rounded-full transition-all dark:hover:bg-yellow-600 dark:hover:text-white' />
        }
      >
        <Text>
          <FormattedMessage id='edit' />
        </Text>
      </Button>
    </Flex>
  );

  const closeEditModal = () => {
    setEditModal(false);
  };

  if (postId) {
    return (
      <PrimaryCard grid={false}>
        {edited ? null : (
          <>
            <Flex className='mb-3' gap='1rem' align='center'>
              {!user && !repost ? (
                <Image
                  className='rounded-full object-cover shadow-glow'
                  width={50}
                  height={50}
                  src={imageUrl ? imageUrl : defaultProfilePhoto}
                  preview={false}
                />
              ) : (
                <Link to={profileLink}>
                  <Image
                    className='rounded-full object-cover shadow-glow'
                    width={50}
                    height={50}
                    src={imageUrl ? imageUrl : defaultProfilePhoto}
                    preview={false}
                  />
                </Link>
              )}
              <div className={styles['userInfo']}>
                {!user && !repost ? (
                  <div>
                    {postDetail?.organizationId > 0 ? (
                      <Text className={`${styles['userName']}`}>
                        {postDetail?.organization?.name}
                      </Text>
                    ) : (
                      <Text className={styles['userName']}>
                        {label}
                        <span className={`${styles['userNick']}`}>• {fullName}</span>
                      </Text>
                    )}
                  </div>
                ) : (
                  <Link to={profileLink}>
                    {postDetail?.organizationId > 0 ? (
                      <Text className={`${styles['userName']}`}>
                        {postDetail?.organization?.name}
                        <span className={`${styles['userNick']}`}>• {label}</span>
                      </Text>
                    ) : (
                      <Text className={styles['userName']}>
                        {label}
                        <span className={`${styles['userNick']}`}>• {fullName}</span>
                      </Text>
                    )}
                  </Link>
                )}
                <Text className='text-xs'>{getRelativeTime(postDetail?.created_at)}</Text>
                {differenceInMinutes(postDetail?.updated_at, postDetail?.created_at) >= 1 && (
                  <span className='!text-xs !text-gray-600 ml-1'>
                    {' '}
                    {'('}
                    <FormattedMessage id='edited' />
                    {')'}
                  </span>
                )}
              </div>
              <div className={`${styles['postStampAndActions']} ml-auto`}>
                {boosted && <BoostedStamp />}
                {isOwner && !reposted && (
                  <Popover placement='right' content={popOverContent}>
                    {theme ? (
                      <SettingOutlined />
                    ) : (
                      <SettingTwoTone className='ml-4' twoToneColor={focusedPrimaryColor} />
                    )}
                  </Popover>
                )}
              </div>
            </Flex>
            <div className={styles['postText']}>
              <Text ref={contentRef}></Text>
            </div>
          </>
        )}
        {postDetail?.feedAttachments?.length > 1 ? (
          <div>
            <Carousel
              defaultControlsConfig={{
                nextButtonText: <RightOutlined />,
                prevButtonText: <LeftOutlined />,
              }}
            >
              {postDetail?.feedAttachments.map((attachment, index) => {
                if (attachment.type === 'video') {
                  return (
                    <div key={index}>
                      <VideoPlayer
                        videoUrl={attachment.attachmentUrl}
                        height={width < 760 ? 200 : 500}
                      />
                    </div>
                  );
                } else if (attachment.type === 'image') {
                  return (
                    <div className={styles['postImageWrapper']} key={index}>
                      <Image preview={true} src={attachment.attachmentUrl} />
                    </div>
                  );
                }
              })}
            </Carousel>
          </div>
        ) : (
          <div>
            {postDetail?.feedAttachments &&
              postDetail?.feedAttachments.map((attachment, index) => {
                if (attachment.type === 'video') {
                  return (
                    <div key={index}>
                      <VideoPlayer
                        videoUrl={attachment.attachmentUrl}
                        height={width < 760 ? 200 : 500}
                      />
                    </div>
                  );
                } else if (attachment.type === 'image') {
                  return (
                    <div className={styles['postImageWrapper']} key={index}>
                      {attachment?.attachmentUrl && (
                        <Image preview={true} src={attachment.attachmentUrl} />
                      )}
                    </div>
                  );
                }
              })}
          </div>
        )}
        {!reposted && (
          <>
            <Divider />
            <div>
              <Flex justify='space-between' align='center' className='mb-4'>
                {likes?.pages[0].like_count > 0 && (
                  <Text className='cursor-pointer' onClick={() => setHitList(true)}>
                    <FontAwesomeIcon className='text-red-600 mr-2' icon={faBomb} />
                    {likes?.pages[0].like_count}
                  </Text>
                )}
                {postDetail?.comment_count > 0 && (
                  <Text className='cursor-pointer' onClick={commentSectionHandler}>
                    {postDetail?.comment_count} <FormattedMessage id='comment' />
                  </Text>
                )}
              </Flex>
              <Flex justify='space-between' className={styles['postActions']}>
                <Flex gap='1rem'>
                  <Button onClick={likeHandler} className={styles['hitIconButton']} icon={hitIcon}>
                    <Text className='text-inherit'>Hit</Text>
                  </Button>
                  <Button
                    type={commentSection ? 'primary' : 'default'}
                    onClick={commentSectionHandler}
                    icon={<MessageOutlined />}
                  >
                    <Text className='text-inherit'>
                      <FormattedMessage id='comment' />
                    </Text>
                  </Button>
                </Flex>
                <div className={styles['shareButtonWrapper']}>
                  <Dropdown
                    menu={{
                      items,
                    }}
                  >
                    <Button icon={<SendOutlined />}>
                      <Text className='text-inherit'>
                        <FormattedMessage id='share' />
                      </Text>
                    </Button>
                  </Dropdown>
                </div>
              </Flex>
              {commentSection && (
                <div className={styles['commentSection']}>
                  <CreateComment postDetails={postDetail} />
                </div>
              )}
            </div>
          </>
        )}
        <Modal
          closeIcon={
            <CloseOutlined className='dark:text-dark-mode-text-color dark:hover:text-white duration-300 text-black' />
          }
          title={translator.formatMessage({ id: 'attention' })}
          open={deleteModal}
          onOk={deletePostHandler}
          onCancel={() => setDeleteModal(false)}
          okType='danger'
          okText={translator.formatMessage({ id: 'delete' })}
          okButtonProps={{ loading: isLoading || isOrganizationLoading }}
          cancelText={translator.formatMessage({ id: 'cancel' })}
        >
          <FormattedMessage id='postDeleteWarn' />
        </Modal>
        <Modal
          closeIcon={
            <CloseOutlined className='dark:text-dark-mode-text-color dark:hover:text-white duration-300 text-black' />
          }
          title={translator.formatMessage({ id: 'repost' })}
          open={repost}
          onCancel={() => setRepost(false)}
          footer={null}
          width={840}
          destroyOnClose={true}
        >
          <CreatePostForm postDetail={postDetail} />
        </Modal>
        <Modal
          closeIcon={
            <CloseOutlined className='dark:text-dark-mode-text-color dark:hover:text-white duration-300 text-black' />
          }
          title={translator.formatMessage({ id: 'edit' })}
          open={editModal}
          onCancel={() => setEditModal(false)}
          footer={null}
          width={840}
          destroyOnClose={true}
        >
          <EditPostForm postDetail={postDetail} setEditModal={closeEditModal} />
        </Modal>
        <Drawer
          closeIcon={
            <CloseOutlined className='dark:text-dark-mode-text-color dark:hover:text-white duration-300 text-black' />
          }
          width={320}
          destroyOnClose={true}
          onClose={() => setHitList(false)}
          open={hitList}
          placement={width < 480 ? 'bottom' : 'right'}
        >
          <ListUsers fetchHandler={fetchNextPage} isLoading={likeListLoading} list={likes} />
        </Drawer>
      </PrimaryCard>
    );
  }
};

export default PostCard;
