import { Card, Row } from 'antd';

const PrimaryCard = ({ children, className, grid = true, bodyStyle, heightFull = false }) => {
  return (
    <Card styles={{ body: bodyStyle }} className={`${className}`} bordered={false}>
      <>
        {grid ? (
          <Row className={heightFull ? 'h-full' : ''} gutter={8}>
            {children}
          </Row>
        ) : (
          children
        )}
      </>
    </Card>
  );
};

export default PrimaryCard;
