import { FormattedMessage, useIntl } from 'react-intl';
import { Col, Row, Space, Typography } from 'antd';
import { useGetUserSteamLibrary } from '../../../hooks/queries/IntegrationQueries';
import RiotGamesLibrary from '../../integrations/riot/RiotGamesLibrary';

const { Text } = Typography;

const RiotGamesIntegrationTab = ({ isOwner, profileDetails }) => {
  const userRiotGamesId = profileDetails?.riot_id;
  const comingSoon = true;
  const translator = useIntl();

  const renderNoRiotIntegrated = () => (
    <Text>
      <FormattedMessage id='noRiotGamesIntegrated' />
    </Text>
  );

  const renderRiotLeagues = () => (
    <Row gutter={[16, 16]}>
      <Col span={24}>leagues</Col>
    </Row>
  );

  if (comingSoon) {
    return <FormattedMessage id='comingSoon' />;
  }

  return (
    <Space direction='vertical' className='gap-[16px]'>
      {isOwner ? (
        <RiotGamesLibrary profileDetails={profileDetails} userRiotGamesId={userRiotGamesId} />
      ) : (
        ''
      )}
      {!userRiotGamesId || userRiotGamesId?.length === 0
        ? renderNoRiotIntegrated()
        : renderRiotLeagues()}
    </Space>
  );
};

export default RiotGamesIntegrationTab;
