import { SelectComponent } from '../filters/Inputs';
import { Button, Checkbox, Col, Form, Input, notification, Row, Typography } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  useCreateOrganizationMember,
  useGetOrganizationFollowersDetail,
} from '../../../hooks/queries/OrganizationQueries';
import React, { useContext, useEffect } from 'react';
import { UtilityContext } from '../../../providers/UtilityProvider';

const { Text } = Typography;

const OrganizationMemberForm = ({ organizationId, followers, memberIds }) => {
  const { openNotification } = useContext(UtilityContext);
  const {
    mutate: createMember,
    isPending,
    isSuccess,
    isError,
  } = useCreateOrganizationMember(organizationId);
  const { data: followerDetails = [] } = useGetOrganizationFollowersDetail(
    followers?.filter((id) => !memberIds.includes(id)),
    organizationId,
  );
  const translator = useIntl();

  const [organizationMembersForm] = Form.useForm();

  const submitMembers = (formValues) => {
    formValues['isMember'] = true;
    if (formValues.userIds?.length > 0 && formValues.role?.replaceAll(' ', '').length > 0) {
      createMember(formValues);
    }
    if (formValues.userIds?.length === 0 || formValues.role?.replaceAll(' ', '').length === 0) {
      openNotification('memberAddFailed', 'error');
    }
  };

  useEffect(() => {
    if (isSuccess) {
      openNotification('memberAdded', 'success');
    }
  }, [isSuccess]);

  return (
    <Form
      initialValues={{
        userIds: [],
        role: '',
        isAdmin: false,
      }}
      layout='vertical'
      onFinish={submitMembers}
      form={organizationMembersForm}
    >
      <Row className='gap-4'>
        <Col span={24} md={11}>
          <SelectComponent
            options={followerDetails}
            formName='userIds'
            multi={true}
            translationId='chooseMembers'
            fieldNames={{
              label: 'fullname',
              value: 'id',
            }}
            filterOption={(input, option) =>
              (option.fullname.toLocaleLowerCase() ?? '').includes(input.toLocaleLowerCase())
            }
          />
          <Form.Item valuePropName='checked' name='isManager'>
            <Checkbox>
              <FormattedMessage id='isAdmin' />
            </Checkbox>
          </Form.Item>
        </Col>
        <Col span={24} md={11}>
          <Form.Item name='role'>
            <Input placeholder={translator.formatMessage({ id: 'enterUserRole' })} />
          </Form.Item>
          <div className='flex justify-end'>
            <Button loading={isPending} htmlType='submit' type='primary'>
              <Text className='text-inherit'>
                <FormattedMessage id='save' />
              </Text>
            </Button>
          </div>
        </Col>
      </Row>
    </Form>
  );
};

export default OrganizationMemberForm;
