import { Image, Button, Col, Drawer, Dropdown, Form, Input, Row, Space, Typography } from 'antd';
import styles from '../../style/mainHeader.module.scss';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import {
  BellOutlined,
  CalendarOutlined,
  HomeOutlined,
  MoonOutlined,
  NotificationOutlined,
  SunOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  REQUIRED_RULE,
  specialCharacters,
  truncateText,
} from '../../helpers/constants/constantHelpers';
import { CountrySelector, SelectComponent } from '../form/filters/Inputs';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../providers/AuthProvider';
import { UtilityContext } from '../../providers/UtilityProvider';
import { useClientError } from '../../hooks/useClientError';
import { uploadFile } from '../../requests/UtilityManager';
import Notifications from '../notifications/Notifications';
import { useGetNotifications, useGetRequests } from '../../hooks/queries/UserQueries';
import Requests from '../notifications/Requests';
import defaultProfilePhoto from '../../assets/images/defaultProfilePhoto.jpeg';
import { useCreateOrganizations } from '../../hooks/queries/OrganizationQueries';
import OutsideClickDetector from '../hoc/OutsideClickDetector';
import { readNotifications } from '../../requests/UserManager';
import { useGetOrganizationDetailByCodeHandler } from '../../hooks/queries/OrganizationQueries';
import CropperComponent from '../../helpers/utils/CropperComponent.tsx';

const { Text, Link } = Typography;

const LinksWithProfile = () => {
  const { logout, userOwnedPages, user } = useContext(AuthContext);

  const { openNotification, organizationCategories, theme } = useContext(UtilityContext);
  const navigate = useNavigate();
  const [codeSlug, setCodeSlug] = useState('');
  const { pathname } = useLocation();
  const { data: notifications, isLoading: notificationsLoading } = useGetNotifications(user?.id);
  const { data: requests } = useGetRequests(user?.id);
  const { mutateAsync: createOrganization, isPending: createOrganizationPending } =
    useCreateOrganizations(user?.id);
  const { data: organizationByCode } = useGetOrganizationDetailByCodeHandler(codeSlug);

  const [teamDrawerOpen, setTeamDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [notificationVisibility, setNotificationVisibility] = useState(false);
  const [requestVisibility, setRequestVisibility] = useState(false);
  const [imageObject, setImageObject] = useState(null);

  const onClientError = useClientError();
  const translator = useIntl();
  const [teamForm] = Form.useForm();

  useEffect(() => {
    setNotificationVisibility(false);
    setRequestVisibility(false);
  }, [window.location.pathname]);

  let items = [
    {
      key: '1',
      type: 'group',
      label: (
        <Text className='!text-base'>
          <FormattedMessage id='account' />
        </Text>
      ),
      children: [
        {
          key: '1-1',
          label: (
            <NavLink to={`/profile/${user?.username}`}>
              <Text className='!text-base'>
                <FormattedMessage id='seeProfile' />
              </Text>
            </NavLink>
          ),
        },
        {
          key: '1-2',
          label: (
            <NavLink to={'/settings'}>
              <Text className='!text-base'>
                <FormattedMessage id='settings' />
              </Text>
            </NavLink>
          ),
        },
        {
          key: '1-3',
          label: (
            <Link onClick={logout}>
              <Text className='!text-base'>
                <FormattedMessage id='logout' />
              </Text>
            </Link>
          ),
        },
      ],
    },
    {
      key: '2',
      type: 'group',
      label: (
        <Text className='!text-base'>
          <FormattedMessage id='team' />
        </Text>
      ),
      children: [
        {
          key: '2-1',
          label: (
            <Text onClick={() => setTeamDrawerOpen(true)} className='!text-base w-[140px]'>
              <FormattedMessage id='createOrganization' />
            </Text>
          ),
        },
        {
          key: '2-2',
          type: 'group',
          label: (
            <Text className='!text-base'>
              <FormattedMessage id='myOrganizations' />
            </Text>
          ),
          children: [],
        },
      ],
    },
  ];

  for (let page of userOwnedPages) {
    let menuItemObject = {
      key: `3-${page.id}`,
      label: (
        <NavLink to={`/organization/${page.code}`} className='whitespace-nowrap'>
          {truncateText(page.name, 20)}
        </NavLink>
      ),
    };
    items[1].children[1].children.push(menuItemObject);
  }

  const teamNameSlugCreator = (inputValue) => {
    //Karakterler ingilizce karaktere çevrilmeli, küçük I küçük i olmalı Ü u olmalı gibi
    let charMap = {
      Ç: 'C',
      Ö: 'O',
      Ş: 'S',
      İ: 'I',
      I: 'i',
      Ü: 'U',
      Ğ: 'G',
      ç: 'c',
      ö: 'o',
      ş: 's',
      ı: 'i',
      ü: 'u',
      ğ: 'g',
    };

    let str_array = inputValue.split('');

    for (let i = 0, len = str_array.length; i < len; i++) {
      str_array[i] = charMap[str_array[i]] || str_array[i];
    }

    let newValue = str_array.join('').replace(/[çöşüğı]/gi, '');
    let slug = newValue.toLocaleLowerCase().replaceAll(' ', '-');

    if (slug[slug.length - 1] !== '-') {
      setCodeSlug(slug);
    }
  };

  useEffect(() => {
    if (codeSlug !== '' && organizationByCode?.code === codeSlug) {
      let randomNum = Math.floor(Math.random() * 10);
      setCodeSlug(`${codeSlug}_${randomNum}`);
    }
    teamForm.setFieldValue('code', codeSlug);
  }, [codeSlug, organizationByCode]);

  const createTeamHandler = () => {
    let formValues = teamForm.getFieldsValue();
    if (specialCharacters.test(formValues.name)) {
      openNotification('organizationNameNotAccepted', 'error');
    } else {
      teamForm.submit();
    }
  };

  const createOrganizationHandler = (formValues) => {
    setLoading(true);
    if (imageObject?.length === 0 || !imageObject) {
      setLoading(false);
      return openNotification('missingLogo', 'error');
    }

    uploadFile(imageObject)
      .then((response) => {
        if (response.status === 202) {
          formValues['logo_url'] = response.data.data;
          formValues['userId'] = user?.id;
          formValues['founder_id'] = user?.id;
          createOrganization(formValues)
            .then((response) => {
              if (response.status === 202) {
                openNotification('organizationCreated', 'success');
              } else {
                openNotification('organizationCreateFailed', 'error');
              }
            })
            .catch((err) => {
              onClientError(err);
            })
            .finally(() => {
              teamForm.resetFields();
              setCodeSlug('');
              setTeamDrawerOpen(false);
              setLoading(false);
            });
        }
      })
      .catch((err) => {
        onClientError(err);
        setLoading(false);
      });
  };

  const notificationVisibilityHandler = async (from) => {
    if (from === 'outside') {
      setNotificationVisibility(false);
    } else {
      setNotificationVisibility(!notificationVisibility);
      await readNotifications(notifications?.map((x) => x.id)).then((response) => {
        notifications.forEach((notification) => {
          notification.read = true;
        });
      });
    }
  };

  const requestVisibilityHandler = (from) => {
    from === 'outside' ? setRequestVisibility(false) : setRequestVisibility(!requestVisibility);
  };

  return (
    <Col
      id='linkWrapper'
      className={styles['linkWrapper']}
      xxl={14}
      xl={14}
      lg={14}
      md={24}
      sm={24}
      xs={24}
    >
      <NavLink
        className='flex flex-col items-center'
        to='/feed'
        onClick={() => {
          if (pathname === '/feed') {
            navigate('/feed');
            window.location.reload();
          }
        }}
      >
        <HomeOutlined className='text-lg' />
        <FormattedMessage id='home' />
      </NavLink>
      {/*<NavLink className={styles['disabledLink']} to='/lobby'>
        <FontAwesomeIcon className='h-[20px]' icon={faUsersViewfinder} />
        <FormattedMessage id='lobby' />
      </NavLink>*/}
      <NavLink className='flex flex-col items-center' to='/events'>
        <CalendarOutlined className='text-lg' />
        <FormattedMessage id='events' />
      </NavLink>
      <div className={styles['link']}>
        <OutsideClickDetector onOutsideClick={() => notificationVisibilityHandler('outside')}>
          <Button
            className='flex flex-col items-center gap-0 p-0'
            type='link'
            onClick={notificationVisibilityHandler}
            icon={<BellOutlined className='text-lg' />}
          >
            <Text className='font-varela text-inherit font-normal'>
              <FormattedMessage id='notifications' />
            </Text>
          </Button>
          {notificationVisibility && (
            <Notifications notificationList={notifications} loading={notificationsLoading} />
          )}
        </OutsideClickDetector>
        {notifications?.filter((x) => !x.read)?.length > 0 && (
          <div className={styles['notificationsBeacon']}></div>
        )}
      </div>
      <div className={styles['link']}>
        <OutsideClickDetector onOutsideClick={() => requestVisibilityHandler('outside')}>
          <Button
            className='flex flex-col items-center gap-0 p-0 text-sm'
            type='link'
            onClick={requestVisibilityHandler}
            icon={<NotificationOutlined className='text-lg' />}
          >
            <Text className='font-varela text-inherit font-normal'>
              <FormattedMessage id='requests' />
            </Text>
          </Button>
          {requestVisibility && (
            <Requests notificationList={notifications} requestList={requests} />
          )}
        </OutsideClickDetector>
        {requests?.length > 0 && <div className={styles['notificationsBeacon']}></div>}
      </div>
      <div className={styles['userDropdownWrapper']}>
        <Dropdown
          menu={{
            items,
          }}
          placement='bottom'
          getPopupContainer={(trigger) => trigger.parentElement}
        >
          <Col span={24} className={styles['userInfoWrapper']}>
            <Image
              className='rounded-full object-cover shadow-glow'
              width={50}
              height={50}
              preview={false}
              src={
                user?.userPhotos && user.userPhotos[0]?.profile_photo
                  ? user?.userPhotos && user.userPhotos[0]?.profile_photo
                  : defaultProfilePhoto
              }
            />
          </Col>
        </Dropdown>
      </div>
      <Drawer
        closeIcon={<CloseOutlined className='duration-300' />}
        title={translator.formatMessage({ id: 'createOrganization' })}
        width={720}
        destroyOnClose={true}
        onClose={() => setTeamDrawerOpen(false)}
        open={teamDrawerOpen}
        extra={
          <Space>
            <Button onClick={() => setTeamDrawerOpen(false)}>
              <Text className='text-inherit'>
                <FormattedMessage id='cancel' />
              </Text>
            </Button>
            <Button loading={loading} onClick={createTeamHandler} type='primary'>
              <Text className='text-inherit'>
                <FormattedMessage id='submit' />
              </Text>
            </Button>
          </Space>
        }
      >
        <Form
          initialValues={{
            slug: translator.formatMessage({ id: 'organizationUrl' }),
          }}
          form={teamForm}
          layout='vertical'
          onFinish={createOrganizationHandler}
        >
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                name='name'
                label={translator.formatMessage({ id: 'name' })}
                rules={[
                  { required: true, message: '' },
                  { max: 30, message: translator.formatMessage({ id: 'formLengthMessageShort' }) },
                ]}
              >
                <Input
                  className='text-black'
                  onChange={(element) => teamNameSlugCreator(element.target.value)}
                  placeholder={translator.formatMessage({ id: 'enterOrganizationName' })}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name='code' label={translator.formatMessage({ id: 'yourUrl' })}>
                <Input
                  style={{
                    width: '100%',
                  }}
                  disabled={true}
                  placeholder={translator.formatMessage({ id: 'organizationUrl' })}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <CountrySelector isRequired={true} formName='country' />
            </Col>
            <Col span={12}>
              <SelectComponent
                labelId='category'
                translationId='category'
                serviceUrl='getCategories'
                options={organizationCategories}
                fieldNames={{
                  label: 'name',
                  value: 'id',
                }}
                formName='category_id'
                required={true}
              />
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item name='description' label={translator.formatMessage({ id: 'description' })}>
                <Input.TextArea
                  style={{ height: 100 }}
                  rows={4}
                  placeholder={translator.formatMessage({ id: 'enterDescriptionForTeam' })}
                  rules={[
                    {
                      max: 250,
                      message: translator.formatMessage({ id: 'formLengthMessageLong' }),
                    },
                  ]}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <CropperComponent
                isLoading={loading}
                label='addTeamLogo'
                returnedData={(value) => setImageObject(value)}
                formName='logo_url'
                required={true}
                forOrganization={true}
              />
            </Col>
          </Row>
        </Form>
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Text italic type='secondary'>
              <FormattedMessage id='organizationPageDescription' />
            </Text>
          </Col>
          <Col span={24}>
            <Text italic type='secondary'>
              <FormattedMessage id='organizationPageSettingInfo' />
            </Text>
          </Col>
        </Row>
      </Drawer>
    </Col>
  );
};

export default LinksWithProfile;
