import React, { useContext } from 'react';
import { Typography, Flex, Button } from 'antd';
import { SunOutlined, MoonOutlined } from '@ant-design/icons';
import { UtilityContext } from '../../providers/UtilityProvider';
import { FormattedMessage } from 'react-intl';
const ThemeSettings = ({ hasText = false }) => {
  const { theme, changeThemeHandler } = useContext(UtilityContext);
  const { Text } = Typography;
  return (
    <>
      {!hasText && (
        <Text className='text-lg ml-1'>
          <FormattedMessage id='theme' />
        </Text>
      )}
      <Flex className='mt-2' gap='1rem'>
        <Button
          onClick={changeThemeHandler}
          type={!theme ? 'primary' : 'default'}
          icon={<SunOutlined />}
        />
        <Button
          onClick={changeThemeHandler}
          type={theme ? 'primary' : 'default'}
          icon={<MoonOutlined />}
        />
      </Flex>
    </>
  );
};

export default ThemeSettings;
