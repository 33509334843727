import NotificationItem from './NotificationItem';
import styles from '../../style/notifications.module.scss';
import { Card, Divider } from 'antd';
import SpinWrapper from '../utility/wrappers/SpinWrapper';

const Notifications = ({ notificationList, loading }) => {
  return (
    <Card className='absolute top-[60px] left-[-75px]'>
      <div className={`${styles['notificationsWrapper']} `}>
        {loading ? (
          <SpinWrapper />
        ) : (
          notificationList.map((item, index) => (
            <div key={index}>
              <NotificationItem item={item} />
              {item.type !== 'request' && <Divider />}
            </div>
          ))
        )}
      </div>
    </Card>
  );
};

export default Notifications;
