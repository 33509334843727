import { Link } from 'react-router-dom';
import { Typography } from 'antd';

const { Text } = Typography;

const useGetOwnerName = (property, isLink = false) => {
  const { organizationId, user, organization } = property;

  const userName = `${user?.first_name} "${user?.username}" ${user?.last_name}`;
  const organizationName = organization?.name;

  if (organizationId === 0) {
    return isLink ? (
      <Link to={`/profile/${user?.username}`}>
        <Text className='hover:!text-blue-500 duration-300'>{userName}</Text>
      </Link>
    ) : (
      <Text>{userName}</Text>
    );
  }

  return isLink ? (
    <Link
      className='hover:text-blue-500 dark:text-dark-mode-text-color'
      to={`/organization/${organization?.code}`}
    >
      {organizationName}
    </Link>
  ) : (
    <Text>{organizationName}</Text>
  );
};

export default useGetOwnerName;
