import { Button, Flex, Image, Typography, Progress } from 'antd';
import React, { useContext } from 'react';
import AchievementItem from './AchievementItem';
import TipTap from '../../editor/TipTap';
import { GifOutlined, SmileOutlined } from '@ant-design/icons';
import { useState } from 'react';
import OutsideClickDetector from '../../hoc/OutsideClickDetector';
import { FormattedMessage } from 'react-intl';

import { usePostFeed } from '../../../hooks/queries/FeedQueries';
import { UtilityContext } from '../../../providers/UtilityProvider';
import { useClientError } from '../../../hooks/useClientError';
import { timeStampToDate } from '../../../helpers/utils/DateFormatter';

const ShareSteamAchievement = ({ eventDetail, image, name, achieved, unlockTime, callBack }) => {
  const { Text } = Typography;
  const [emojiPickerDisplay, setEmojiPickerDisplay] = useState(false);
  const [gifPickerDisplay, setGifPickerDisplay] = useState(false);
  const [tipTapContent, setTipTapContent] = useState('');
  const { openNotification } = useContext(UtilityContext);
  const onClientError = useClientError();

  const { mutateAsync: postFeed } = usePostFeed();
  const backFireOfEmojiArea = () => {
    if (emojiPickerDisplay) {
      setEmojiPickerDisplay(!emojiPickerDisplay);
    }
  };
  const browserLang = navigator.language;

  const tipTapOnChangeHandler = (value) => {
    setTipTapContent(value);
  };
  const date = achieved ? timeStampToDate(unlockTime) : '-';
  const shareEventHandler = () => {
    let attachmentArray = [];
    let description =
      tipTapContent +
      `${
        browserLang === 'tr'
          ? `
  <div style='display: flex; align-items: flex-start; gap: 10px;'>
    <img src='${eventDetail.icon}' style='width: 64px; height: 64px; border-radius: 8px;' alt='Başarım İkonu' />
    <div style='display: flex; flex-direction: column; gap: 3px;'>
        <span style='overflow-wrap: break-word;'>${name} "${eventDetail.display_name}" başarımını kazandım!</span> 
        <span style='font-size: 13px'>${date}</span>
        <div style='display:flex; align-items:center; gap: 10px;'>
          <div style='width: 75%; height: 6px; background-color: rgba(0, 0, 0, 0.3); border-radius: 5px; overflow: hidden;'>
            <div 
              style='width: ${eventDetail.percentage}%; height: 100%;
               background: linear-gradient(to right, rgb(178, 36, 139), rgb(64, 52, 144));
               border-radius: 5px 0 0 5px;'>
            </div>
          </div>
          <span style='font-size: 13px'>${eventDetail.percentage}%</span></div>
    </div>
</div> `
          : `
  <div style='display: flex; align-items: flex-start; gap: 10px;'>
    <img src='${eventDetail.icon}' style='width: 64px; height: 64px; border-radius: 12px;' alt='Başarım İkonu' />
    <div style='display: flex; flex-direction: column; gap: 3px;'>
        <span style='overflow-wrap: break-word;'>I got the  "${eventDetail.display_name}" achievement in ${name}</span> 
        <span style='font-size: 13px'>${date}</span>
        <div style='display:flex; align-items:center; gap: 10px;'>
          <div style='width: 75%; height: 6px; background-color: rgba(0, 0, 0, 0.3); border-radius: 5px; overflow: hidden;'>
            <div 
              style='width: ${eventDetail.percentage}%; height: 100%;
               background: linear-gradient(to right, rgb(178, 36, 139), rgb(64, 52, 144));
               border-radius: 5px 0 0 5px;'>
            </div>
          </div>
          <span style='font-size: 13px'>${eventDetail.percentage}%</span></div>
    </div>
</div> `
      }`;
    attachmentArray.push({
      type: 'image',
    });

    postFeed({
      content: description ? description : '',
      attachmentArray: attachmentArray,
    })
      .then((response) => {
        if (response.status === 202) {
          openNotification('eventShared', 'success');
          callBack();
        }
      })
      .catch((err) => onClientError(err));
  };
  return (
    <Flex className='flex-col'>
      <OutsideClickDetector onOutsideClick={backFireOfEmojiArea}>
        <TipTap
          forwardOnChange={tipTapOnChangeHandler}
          isEmojiVisible={emojiPickerDisplay}
          isGifVisible={gifPickerDisplay}
          emojiViewHandler={() => setEmojiPickerDisplay(!emojiPickerDisplay)}
          gifViewHandler={() => setGifPickerDisplay(!gifPickerDisplay)}
          toolbar={false}
        />
        <Flex className='mt-4 w-full' gap='1rem'>
          <SmileOutlined
            onClick={() => {
              setEmojiPickerDisplay(!emojiPickerDisplay);
              setGifPickerDisplay(false);
            }}
            className='p-2 rounded-full transition-all bg-container-color hover:bg-primary hover:text-white dark:bg-dark-mode-shadow-color dark:hover:bg-primary'
          />
          <GifOutlined
            onClick={() => {
              setGifPickerDisplay(!gifPickerDisplay);
              setEmojiPickerDisplay(false);
            }}
            className='p-2 rounded-full transition-all bg-container-color hover:bg-primary hover:text-white dark:bg-dark-mode-shadow-color dark:hover:bg-primary'
          />
        </Flex>

        <Flex className='mt-4' gap='1rem'>
          <Image width={128} className='rounded-[12px]' preview={false} src={eventDetail.icon} />
          <Flex className='w-full'>
            {browserLang === 'tr' && (
              <Flex vertical={true} gap='3px' className='w-full'>
                <Flex gap='3px' justify='space-between' align='center'>
                  <Flex vertical={true}>
                    <Text>
                      {name} "${eventDetail.display_name}" başarımını kazandım!
                    </Text>
                    <Text className='text-sm'>{achieved ? timeStampToDate(unlockTime) : '-'}</Text>
                  </Flex>
                </Flex>
                <Progress
                  percent={eventDetail.percentage}
                  status='active'
                  strokeColor={
                    achieved
                      ? {
                          from: '#b2248b',
                          to: '#403490',
                        }
                      : {
                          from: '#b1b1b1',
                          to: '#2a2a2a',
                        }
                  }
                />
              </Flex>
            )}
            {browserLang === 'en' && (
              <Flex vertical={true} gap='3px' className='w-full'>
                <Flex gap='3px' justify='space-between' align='center'>
                  <Flex vertical={true}>
                    <Text>
                      I got the "{eventDetail.display_name}" achievement in ${name}
                    </Text>
                    <Text className='text-sm'>{achieved ? timeStampToDate(unlockTime) : '-'}</Text>
                  </Flex>
                </Flex>
                <Progress
                  percent={eventDetail.percentage}
                  status='active'
                  strokeColor={
                    achieved
                      ? {
                          from: '#b2248b',
                          to: '#403490',
                        }
                      : {
                          from: '#b1b1b1',
                          to: '#2a2a2a',
                        }
                  }
                />
              </Flex>
            )}
          </Flex>
        </Flex>
        <Flex className='mt-4' justify='end'>
          <Button type='primary' onClick={shareEventHandler}>
            <FormattedMessage id='create' />
          </Button>
        </Flex>
      </OutsideClickDetector>
    </Flex>
  );
};

export default ShareSteamAchievement;
