import { Col, Row, Image, Drawer } from 'antd';
import styles from '../../style/gallery.module.scss';
import { useGetOrganizationGallery } from '../../hooks/queries/OrganizationQueries';
import SpinWrapper from '../utility/wrappers/SpinWrapper';
import { useState, useEffect, useContext } from 'react';
import VideoPlayerGallery from './VideoPlayerGallery';
import PostDetail from '../post/PostDetail';
import EmptyWrapper from '../utility/wrappers/EmptyWrapper';
import { useIntl } from 'react-intl';
import { CloseOutlined } from '@ant-design/icons';
import { UtilityContext } from '../../providers/UtilityProvider';
const MiniOrganizationGallery = ({ organizationId }) => {
  const [postId, setPostId] = useState(0);
  const { data, isLoading } = useGetOrganizationGallery(organizationId, 0);
  const [initialData, setInitialData] = useState([]);
  const { theme } = useContext(UtilityContext);
  const translator = useIntl();

  useEffect(() => {
    if (data && initialData.length === 0) {
      setInitialData(data?.pages?.[0]?.rows ?? []);
    }
  }, [data]);

  if (data?.pages[0].rows.length === 0) {
    return <EmptyWrapper description={translator.formatMessage({ id: 'noImageLoaded' })} />;
  }

  return (
    <Row className={styles['galleryRow']} gutter={[8, 8]}>
      {isLoading ? (
        <SpinWrapper />
      ) : (
        initialData.map(({ attachmentUrl, feedId, type }, index) => (
          <Col
            onClick={() => setPostId(feedId)}
            key={index}
            className={styles['galleryImageWrapper']}
            span={8}
          >
            {type === 'image' ? (
              <div className={styles['imageInnerWrapper']}>
                <div className={styles['imageLinkWrapper']}>
                  <div className={styles['imageContainer']}>
                    <Image width='100%' preview={false} src={attachmentUrl} alt='exampleImg' />
                  </div>
                </div>
              </div>
            ) : (
              <div className={styles['imageInnerWrapper']}>
                <div className={styles['imageLinkWrapper']}>
                  <div className={styles['imageContainer']}>
                    <VideoPlayerGallery videoUrl={attachmentUrl} height={133} />
                  </div>
                </div>
              </div>
            )}
          </Col>
        ))
      )}
      <Drawer
        closeIcon={<CloseOutlined />}
        onClose={() => setPostId(0)}
        open={postId > 0}
        destroyOnClose={true}
        centered={true}
        width={window.screen.width < 768 ? '100%' : '60%'}
        height='100%'
        footer={null}
      >
        {postId && <PostDetail postId={postId} />}
      </Drawer>
    </Row>
  );
};

export default MiniOrganizationGallery;
