import { BgColorsOutlined, TableOutlined } from '@ant-design/icons';
import { Button, Col, Dropdown, Flex, Row } from 'antd';

import { BUTTON_LIST } from './Buttons';
import { useState, useEffect } from 'react';
import styles from '../../../style/editor.module.scss';

const Toolbar = ({ editor }) => {
  const [colorChart, setColorChart] = useState(false);
  const [colorTableCell, setColorTableCell] = useState(false);
  const [colorTableRow, setColorTableRow] = useState(false);

  useEffect(() => {
    if (colorTableRow) {
      setColorChart(true);
    }
  }, [colorTableRow]);

  useEffect(() => {
    if (colorTableCell) {
      setColorChart(true);
    }
  }, [colorTableCell]);

  const checkClass = (checkType, params) => {
    if (editor) {
      return editor.isActive(checkType, params)
        ? `${styles['is-active']} ant-btn-icon-only`
        : `${styles['is-passive']} ant-btn-icon-only`;
    }
  };

  const runFunc = (funcName, params) => {
    if (funcName === 'colorCell') {
      setColorTableCell(true);
    } else {
      editor.chain().focus()[funcName](params).run();
    }
  };

  const colorPalette = [
    '#fff',
    '#000',
    '#eeece1',
    '#1f497d',
    '#4f81bd',
    '#c0504d',
    '#9bbb59',
    '#8064a2',
    '#4bacc6',
    '#f79646',
    '#ffff00',
    '#f2f2f2',
    '#ddd9c3',
    '#c6d9f0',
    '#dbe5f1',
    '#f2dcdb',
    '#ebf1dd',
    '#e5e0ec',
    '#dbeef3',
    '#fdeada',
    '#fff2ca',
    '#d8d8d8',
    '#595959',
    '#c4bd97',
    '#8db3e2',
    '#b8cce4',
    '#e5b9b7',
    '#d7e3bc',
    '#ccc1d9',
    '#fbd5b5',
    '#ffe694',
    '#bfbfbf',
    '#3f3f3f',
    '#938953',
    '#548dd4',
    '#95b3d7',
    '#d99694',
    '#c3d69b',
    '#b2a2c7',
    '#fac08f',
    '#f2c314',
    '#a5a5a5',
    '#262626',
    '#494429',
    '#17365d',
    '#366092',
    '#953734',
    '#76923c',
    '#5f497a',
    '#92cddc',
    '#e36c09',
    '#c09100',
    '#7f7f7f',
    '#0c0c0c',
    '#1d1b10',
    '#0f243e',
    '#244061',
    '#632423',
    '#4f6128',
    '#3f3151',
    '#31859b',
    '#974806',
    '#7f6000',
  ];

  return (
    <div>
      <Flex>
        {BUTTON_LIST.map(({ icon, textIcon, funcName, checkType, params }) => (
          <Button
            key={checkType}
            icon={icon}
            onClick={() => runFunc(funcName, params)}
            className={checkClass(checkType, params)}
            type='text'
          >
            {textIcon}
          </Button>
        ))}
        <Button
          icon={<BgColorsOutlined />}
          className={colorChart ? 'is-active ant-btn-icon-only' : 'is-passive ant-btn-icon-only'}
          onClick={() => setColorChart(!colorChart)}
          type='text'
        />
      </Flex>

      {colorChart && (
        <div className={`${styles['chartRow']} `}>
          {colorPalette.map((colorHex, index) => (
            <span
              key={index}
              style={{ backgroundColor: colorHex }}
              onClick={() => {
                if (colorTableCell) {
                  editor.chain().focus().setCellAttribute('backgroundColor', colorHex).run();
                  setColorTableCell(false);
                } else {
                  editor.chain().focus().toggleHighlight({ color: colorHex }).run();
                }
                setColorChart(false);
              }}
            />
          ))}
          <input
            onChange={(input) =>
              editor
                .chain()
                .focus()
                .toggleHighlight({ color: `${input.target.value}` })
                .run()
            }
            type='color'
          />
        </div>
      )}
    </div>
  );
};

export default Toolbar;
