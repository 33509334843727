import { Link, useSearchParams } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { steamIntegrateUrl } from '../../../helpers/constants/constantHelpers';
import { Button, Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import layoutStyles from '../../../style/layout.module.scss';
import { faSteam } from '@fortawesome/free-brands-svg-icons';
import { FormattedMessage } from 'react-intl';
import { useSaveSteamId, useSyncSteam } from '../../../hooks/queries/IntegrationQueries';
import { UtilityContext } from '../../../providers/UtilityProvider';
import { useClientError } from '../../../hooks/useClientError';
import { AuthContext } from '../../../providers/AuthProvider';
import { SyncOutlined } from '@ant-design/icons';
import useWindowSize from '../../../hooks/useWindowSize';
import SpinWrapper from '../../utility/wrappers/SpinWrapper';

const { Text } = Typography;

const SteamLibrary = ({ profileDetails, userSteamId }) => {
  const { checkUserInformation } = useContext(AuthContext);
  const { width } = useWindowSize();
  const { openNotification } = useContext(UtilityContext);
  const { mutateAsync: saveSteamIdHandler } = useSaveSteamId(profileDetails?.username);
  const {
    mutateAsync: syncSteamHandler,
    isPending,
    isSuccess,
  } = useSyncSteam(profileDetails?.username, profileDetails?.id);
  const [isSteamIntegrated, setIsSteamIntegrated] = useState(false);
  const [searchParams] = useSearchParams();
  const onClientError = useClientError();

  const checkSteamIntegration = () => {
    if (userSteamId?.length > 0) {
      setIsSteamIntegrated(true);
    } else {
      setIsSteamIntegrated(false);
    }
  };

  useEffect(() => {
    checkSteamIntegration();
  }, [userSteamId]);

  useEffect(() => {
    if (!isSteamIntegrated && !userSteamId) {
      const userSteamId = searchParams.get('openid.claimed_id')?.split('/id/')[1];
      if (userSteamId) {
        saveSteamIdHandler(userSteamId)
          .then((response) => {
            if (response.status === 202) {
              openNotification('steamIntegrationSuccess', 'success');
              return syncSteamHandler();
            }
          })
          .then((syncResponse) => {
            if (syncResponse?.status === 202) {
              setIsSteamIntegrated(true);
              checkUserInformation();
            }
          })
          .catch((err) => onClientError(err));
      }
    }
  }, [searchParams]);

  useEffect(() => {
    if (isSuccess) {
      openNotification('steamSynced', 'success');
      checkSteamIntegration();
    }
  }, [isSuccess]);

  if (isPending) {
    return <SpinWrapper />;
  }

  if (isSteamIntegrated) {
    return (
      <Button onClick={syncSteamHandler} loading={isPending} type='primary' icon={<SyncOutlined />}>
        {width > 900 ? (
          <Text className='text-inherit'>
            <FormattedMessage id='sync' />
          </Text>
        ) : (
          ''
        )}
      </Button>
    );
  } else {
    return (
      <Link to={steamIntegrateUrl(profileDetails?.username)}>
        <Button
          type='primary'
          icon={
            <FontAwesomeIcon
              className={layoutStyles['fontAwesomeIcon']}
              icon={faSteam}
              style={{ color: '#FFF' }}
            />
          }
        >
          <Text className='text-inherit'>
            {width > 900 ? <FormattedMessage id='integrate' /> : ''}
          </Text>
        </Button>
      </Link>
    );
  }
};

export default SteamLibrary;
