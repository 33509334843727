import { Route, Routes } from 'react-router-dom';
import ProtectedRoute from '../helpers/ProtectedRoute';
import Profile from '../pages/Profile';
import OrganizationPage from '../pages/OrganizationPage';
import PostPage from '../pages/PostPage';
import NotificationsMobile from '../pages/NotificationsMobile';
import PreRegister from '../pages/PreRegister';
import SharedPost from '../components/post/SharedPost';
import ResetPassword from '../pages/ResetPassword';
import Messages from '../pages/Messages';
import UserSettings from '../pages/UserSettings';
import PageNotFound from '../pages/PageNotFound';
import EventList from '../pages/EventList';
import RiotFile from '../components/integrations/riot/RiotFile';
import PrivacyPoliciy from '../pages/PrivacyPoliciy';
import TermsofServices from '../pages/TermsofServices';
import ActivationUser from '../pages/ActivationUser';

const AppRouter = () => {
  return (
    <Routes>
      <Route path='/' element={<PreRegister />} />
      <Route path='/privacy-policy' element={<PrivacyPoliciy />} />
      <Route path='/terms-of-service' element={<TermsofServices />} />
      <Route path='/activate/:userKey' element={<ActivationUser />} />
      <Route path='/login/:userKey' element={<PreRegister oauthMode={true} />} />
      <Route path='/reset-password/:userKey' element={<ResetPassword />} />
      <Route path='/shared/:uid' element={<SharedPost />} />
      <Route element={<ProtectedRoute />}>
        <Route path='/feed' element={<PostPage />} />
        <Route path='/hash/:hashtag' element={<PostPage />} />
        <Route path='/profile/:code' element={<Profile />} />
        <Route path='/organization/:code' element={<OrganizationPage />} />
        {/*<Route path='/lobby' element={<GameLobby />} />*/}
        <Route path='/settings' element={<UserSettings />} />
        <Route path='/events' element={<EventList />} />
        <Route path='/riot.txt' element={<RiotFile />} />
        {window.screen.width < 768 && (
          <>
            <Route path='/notifications' element={<NotificationsMobile />} />
            <Route path='/messages' element={<Messages />} />
          </>
        )}
        <Route path='*' element={<PageNotFound />} />
      </Route>
    </Routes>
  );
};

export default AppRouter;
