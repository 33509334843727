import { Button, Col, Row, Form, Image, Typography, Flex } from 'antd';
import { FormattedMessage } from 'react-intl';

import styles from '../../../style/postCreate.module.scss';
import React, { useContext, useState, useEffect } from 'react';
import { SmileOutlined, GifOutlined } from '@ant-design/icons';
import PrimaryCard from '../../cards/PrimaryCard';
import { UtilityContext } from '../../../providers/UtilityProvider';
import { AuthContext } from '../../../providers/AuthProvider';
import TipTap from '../../editor/TipTap';
import { useEditFeed } from '../../../hooks/queries/FeedQueries';
import defaultProfilePhoto from '../../../assets/images/defaultProfilePhoto.jpeg';
import PostCard from '../../post/PostCard';
import OutsideClickDetector from '../../hoc/OutsideClickDetector';
import { useGetFeedDetails } from '../../../hooks/queries/FeedQueries';

const { Text } = Typography;

const EditPostForm = ({ postDetail, setEditModal }) => {
  const { openNotification, organizationCategories } = useContext(UtilityContext);
  const { user } = useContext(AuthContext);
  const [emojiPickerDisplay, setEmojiPickerDisplay] = useState(false);
  const [gifPickerDisplay, setGifPickerDisplay] = useState(false);
  const [postLoading, setPostLoading] = useState(false);
  const initialCaption = postDetail?.content;
  const [editorString, setEditorString] = useState(initialCaption);
  const [createButtonDisabled, setCreateButtonDisabled] = useState(true);
  const { data: repostDetail, isLoading: repostDetailLoading } = useGetFeedDetails(
    postDetail?.relatedFeedId,
  );

  const { mutateAsync: editFeed } = useEditFeed(postDetail?.organizationId);

  let [postForm] = Form.useForm();

  useEffect(() => {
    if (
      editorString === initialCaption ||
      (postDetail?.feedAttachments.length === 0 &&
        editorString.replace(/<(?!img\b|iframe\b)[^>]*>|\s+/g, '') === '')
    ) {
      setCreateButtonDisabled(true);
    } else {
      setCreateButtonDisabled(false);
    }
  }, [editorString]);

  const editPostHandler = async (formValues) => {
    setPostLoading(true);

    let feedObject = {
      content: formValues.content,
    };

    if (postDetail) {
      feedObject['relatedFeedId'] = postDetail?.id;
    }

    editFeed(feedObject)
      .then((response) => {
        if (response.status === 202) {
          postForm.resetFields();
          openNotification('postEdited', 'success');
        } else {
          openNotification('editFailed', 'error');
        }
        setCreateButtonDisabled(true);
        setEditModal();
      })
      .finally(() => setPostLoading(false));
  };

  const tipTapOnChangeHandler = (tipTapValue) => {
    postForm.setFieldValue('content', tipTapValue);
    setEditorString(tipTapValue);
  };

  const backFireOfEmojiArea = () => {
    if (emojiPickerDisplay) {
      setEmojiPickerDisplay(!emojiPickerDisplay);
    }
  };

  return (
    <PrimaryCard>
      <Col span={24}>
        <Form
          initialValues={{
            content: initialCaption || '',
          }}
          className={postDetail ? 'w-full mb-4' : 'w-full'}
          onFinish={editPostHandler}
          form={postForm}
        >
          <Row gutter={[16, 0]} className={styles['postCreationRow']}>
            <Col span={24}>
              <Flex gap='1rem' className='mb-[1rem]'>
                <Image
                  width={55}
                  height={45}
                  className='rounded-[8px] object-cover'
                  preview={false}
                  src={
                    postDetail?.organizationId > 0
                      ? postDetail?.organization?.logo_url
                      : user?.userPhotos && user?.userPhotos[0]?.profile_photo
                      ? user?.userPhotos && user?.userPhotos[0]?.profile_photo
                      : defaultProfilePhoto
                  }
                />
                <div>
                  <Text className='d-block'>
                    {postDetail?.organizationId > 0
                      ? postDetail?.organization?.name
                      : user?.username}
                  </Text>
                  <span className={`${styles['userNick']}`}>
                    {postDetail?.organizationId > 0
                      ? organizationCategories?.find(
                          (category) => category.id === postDetail?.organization?.categoryId,
                        )?.name
                      : `${user?.first_name} ${user?.last_name}`}
                  </span>
                </div>
              </Flex>
            </Col>
            <Col span={24}>
              <OutsideClickDetector onOutsideClick={backFireOfEmojiArea}>
                <Col span={24}>
                  <Form.Item name='content'>
                    <TipTap
                      defaultValue={initialCaption || ''}
                      isEmojiVisible={emojiPickerDisplay}
                      isGifVisible={gifPickerDisplay}
                      emojiViewHandler={() => setEmojiPickerDisplay(!emojiPickerDisplay)}
                      gifViewHandler={() => setGifPickerDisplay(!gifPickerDisplay)}
                      forwardOnChange={tipTapOnChangeHandler}
                    />
                  </Form.Item>
                </Col>
                <Col className={styles['actionWrapper']} span={6}>
                  <Flex gap='1rem'>
                    <SmileOutlined
                      onClick={() => {
                        setEmojiPickerDisplay(!emojiPickerDisplay);
                        setGifPickerDisplay(false);
                      }}
                      className='p-2 rounded-full transition-all bg-container-color hover:bg-primary hover:text-white dark:bg-dark-mode-shadow-color dark:hover:bg-primary'
                    />
                    <GifOutlined
                      onClick={() => {
                        setEmojiPickerDisplay(!emojiPickerDisplay);
                        setGifPickerDisplay(false);
                      }}
                      className='p-2 rounded-full transition-all bg-container-color hover:bg-primary hover:text-white dark:bg-dark-mode-shadow-color dark:hover:bg-primary'
                    />
                  </Flex>
                </Col>
                <Col span={24}>
                  {postDetail && postDetail?.relatedFeedId > 0 ? (
                    <PostCard
                      reposted={true}
                      repostLoading={repostDetailLoading}
                      postDetail={repostDetail}
                      postId={repostDetail?.id}
                    />
                  ) : (
                    <PostCard
                      edited={true}
                      reposted={true}
                      postId={postDetail?.id}
                      postDetail={postDetail}
                    />
                  )}
                </Col>
                <Button
                  loading={postLoading}
                  type='primary'
                  htmlType='submit'
                  disabled={createButtonDisabled}
                >
                  <Text className='text-inherit'>
                    <FormattedMessage id='create' />
                  </Text>
                </Button>
              </OutsideClickDetector>
            </Col>
          </Row>
        </Form>
      </Col>
    </PrimaryCard>
  );
};

export default EditPostForm;
