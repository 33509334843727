import React from 'react';

const RiotGamesSVG = ({ dark, width, height }) => (
  <svg width={width} height={height} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 32 32'>
    <circle cx='16' cy='16' r='16' fill={dark ? '#f1f5f1' : 'red'}></circle>
    <polygon
      points='16.42 8 6.75 12.47 9.16 21.64 11 21.41 10.49 15.65 11.1 15.38 12.13 21.27 15.27 20.89 14.71 14.53 15.3 14.27 16.45 20.75 19.62 20.36 19.01 13.38 19.61 13.12 20.86 20.2 24 19.82 24 9.89 16.42 8'
      fill={dark ? '#050505' : 'white'}
    ></polygon>
    <polygon
      points='16.65 21.9 16.81 22.8 24 24 24 21 16.66 21.9 16.65 21.9'
      fill={dark ? '#050505' : 'white'}
    ></polygon>
  </svg>
);

export default RiotGamesSVG;
