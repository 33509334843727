import { ReactRenderer } from '@tiptap/react';
import tippy from 'tippy.js';
import { getSearchedPage } from '../../requests/UtilityManager';
import { Typography } from 'antd';

import MentionList from './MentionList.jsx';

const { Text } = Typography;

export default {
  items: async ({ query }) => {
    let options = [];
    if (query.length > 3) {
      await getSearchedPage(query).then((response) => {
        if (response.status === 202) {
          for (let data of response.data.data) {
            let dataObject = data.username;
            options.push({ name: data.username, type: data.type });
          }
        }
      });
    }

    return options.slice(0, 5);
  },

  render: () => {
    let component;
    let popup;

    return {
      onStart: (props) => {
        component = new ReactRenderer(MentionList, {
          props,
          editor: props.editor,
        });

        if (!props.clientRect) {
          return;
        }

        popup = tippy('body', {
          getReferenceClientRect: props.clientRect,
          appendTo: () => document.body,
          content: component.element,
          showOnCreate: true,
          interactive: true,
          trigger: 'manual',
          placement: 'bottom-start',
        });
      },

      onUpdate(props) {
        component.updateProps(props);

        if (!props.clientRect) {
          return;
        }

        popup[0].setProps({
          getReferenceClientRect: props.clientRect,
        });
      },

      onKeyDown(props) {
        if (props.event.key === 'Escape') {
          popup[0].hide();

          return true;
        }

        return component.ref?.onKeyDown(props);
      },

      onExit() {
        popup[0].destroy();
        component.destroy();
      },
    };
  },
};
