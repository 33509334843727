import { Button, Form, Input, Typography } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import layoutStyles from '../../../style/layout.module.scss';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  faCamera,
  faChair,
  faDesktop,
  faHeadphones,
  faKeyboard,
  faMicrophone,
  faMouse,
} from '@fortawesome/free-solid-svg-icons';
import styles from '../../../style/form.module.scss';
import { CheckCircleFilled, CloseCircleFilled } from '@ant-design/icons';
import { useContext } from 'react';
import { UtilityContext } from '../../../providers/UtilityProvider';
import { useClientError } from '../../../hooks/useClientError';
import { AuthContext } from '../../../providers/AuthProvider';
import { useUpdateAccessoriesInformation } from '../../../hooks/queries/UserQueries';
import PropChecker from '../../../helpers/utils/PropChecker';

const { Text } = Typography;

const AccessoryForm = ({ modalHandler, accessoryProperties }) => {
  const { user } = useContext(AuthContext);
  const { mutateAsync, isPending } = useUpdateAccessoriesInformation(user?.id);
  const { openNotification } = useContext(UtilityContext);
  const onClientError = useClientError();
  const [accessoryForm] = Form.useForm();
  const translator = useIntl();

  const accessoryFormHandler = (formValues) => {
    if (PropChecker(formValues)) {
      formValues['userId'] = user.id;
      mutateAsync(formValues)
        .then((response) => {
          if (response.status === 202) {
            openNotification('accessoryInformationSaved', 'success');
          }
        })
        .catch((err) => onClientError(err))
        .finally(() => modalHandler());
    } else {
      return openNotification('noBlankSpaceAllowed', 'error');
    }
  };

  return (
    <Form
      layout='vertical'
      onFinish={accessoryFormHandler}
      initialValues={accessoryProperties}
      form={accessoryForm}
    >
      <Form.Item name='keyboard' label={translator.formatMessage({ id: 'keyboardFormLabel' })}>
        <Input
          placeholder={translator.formatMessage({ id: 'keyboardPlaceHolder' })}
          prefix={
            <FontAwesomeIcon
              className={layoutStyles['fontAwesomeIcon']}
              icon={faKeyboard}
              style={{ color: '#0f7c10', marginRight: '.5rem' }}
            />
          }
        />
      </Form.Item>
      <Form.Item name='mouse' label={translator.formatMessage({ id: 'mouseFormLabel' })}>
        <Input
          placeholder={translator.formatMessage({ id: 'mousePlaceHolder' })}
          prefix={
            <FontAwesomeIcon
              className={layoutStyles['fontAwesomeIcon']}
              icon={faMouse}
              style={{ color: '#0f7c10', marginRight: '.5rem' }}
            />
          }
        />
      </Form.Item>
      <Form.Item name='headset' label={translator.formatMessage({ id: 'headphoneFormLabel' })}>
        <Input
          placeholder={translator.formatMessage({ id: 'headphonePlaceHolder' })}
          prefix={
            <FontAwesomeIcon
              className={layoutStyles['fontAwesomeIcon']}
              icon={faHeadphones}
              style={{ color: '#0f7c10', marginRight: '.5rem' }}
            />
          }
        />
      </Form.Item>
      <Form.Item name='camera' label={translator.formatMessage({ id: 'cameraFormLabel' })}>
        <Input
          placeholder={translator.formatMessage({ id: 'cameraPlaceHolder' })}
          prefix={
            <FontAwesomeIcon
              className={layoutStyles['fontAwesomeIcon']}
              icon={faCamera}
              style={{ color: '#0f7c10', marginRight: '.5rem' }}
            />
          }
        />
      </Form.Item>
      <Form.Item name='microphone' label={translator.formatMessage({ id: 'microphoneFormLabel' })}>
        <Input
          placeholder={translator.formatMessage({ id: 'microphonePlaceHolder' })}
          prefix={
            <FontAwesomeIcon
              className={layoutStyles['fontAwesomeIcon']}
              icon={faMicrophone}
              style={{ color: '#0f7c10', marginRight: '.5rem' }}
            />
          }
        />
      </Form.Item>
      <Form.Item name='chair' label={translator.formatMessage({ id: 'chairFormLabel' })}>
        <Input
          placeholder={translator.formatMessage({ id: 'chairPlaceHolder' })}
          prefix={
            <FontAwesomeIcon
              className={layoutStyles['fontAwesomeIcon']}
              icon={faChair}
              style={{ color: '#0f7c10', marginRight: '.5rem' }}
            />
          }
        />
      </Form.Item>
      <Form.Item name='monitor' label={translator.formatMessage({ id: 'desktopFormLabel' })}>
        <Input
          placeholder={translator.formatMessage({ id: 'desktopPlaceHolder' })}
          prefix={
            <FontAwesomeIcon
              className={layoutStyles['fontAwesomeIcon']}
              icon={faDesktop}
              style={{ color: '#0f7c10', marginRight: '.5rem' }}
            />
          }
        />
      </Form.Item>
      <div className={styles['actionWrapper']}>
        <Button
          onClick={modalHandler}
          icon={<CloseCircleFilled style={{ color: '#D61C1C', marginRight: '.5rem' }} />}
        >
          <Text className='text-inherit'>
            <FormattedMessage id='cancel' />
          </Text>
        </Button>
        <Button
          loading={isPending}
          htmlType='submit'
          type='primary'
          icon={<CheckCircleFilled style={{ color: '#fff', marginRight: '.5rem' }} />}
        >
          <Text className='text-inherit'>
            <FormattedMessage id='ok' />
          </Text>
        </Button>
      </div>
    </Form>
  );
};

export default AccessoryForm;
