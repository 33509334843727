import ReactPlayer from 'react-player';
import { useRef } from 'react';
import { PlayCircleOutlined } from '@ant-design/icons';

const VideoPlayerGallery = ({ videoUrl, height }) => {
  const videoRef = useRef();
  return (
    <div className='relative w-full'>
      <PlayCircleOutlined className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-white text-5xl cursor-pointer z-10' />
      <ReactPlayer
        url={videoUrl}
        playing={false}
        loop={true}
        controls={false}
        width='100%'
        height={height}
        volume={1}
        muted={true}
        playsinline={true}
        onLoad={() => {
          videoRef.current.seek(0);
        }}
      />
    </div>
  );
};

export default VideoPlayerGallery;
