import { riotIntegrateUrl, steamIntegrateUrl } from '../../../helpers/constants/constantHelpers';
import { Button, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import useWindowSize from '../../../hooks/useWindowSize';
import RiotGamesSVG from '../../../assets/svg/RiotGamesSVG';
import { useContext } from 'react';
import { UtilityContext } from '../../../providers/UtilityProvider';

const { Text } = Typography;

const RiotGamesLibrary = ({ profileDetails }) => {
  const { width } = useWindowSize();
  const { theme } = useContext(UtilityContext);

  return (
    <div>
      <Link to={riotIntegrateUrl(profileDetails?.username)}>
        <Button type='primary' icon={<RiotGamesSVG dark={theme} width={20} height={20} />}>
          <Text className='text-inherit'>
            {width > 900 ? <FormattedMessage id='integrate' /> : ''}
          </Text>
        </Button>
      </Link>
    </div>
  );
};

export default RiotGamesLibrary;
