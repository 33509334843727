import '../assets/styles/main.css';
import '../assets/styles/responsive.css';
import styles from '../style/profile.module.scss';
import {
  Row,
  Col,
  Card,
  Radio,
  Typography,
  Image,
  Button,
  Modal,
  Flex,
  Tooltip,
  Dropdown,
} from 'antd';

import BgProfile from '../assets/images/bg-profile.jpg';
import { FormattedMessage, useIntl } from 'react-intl';
import PostCard from '../components/post/PostCard';
import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react';
import ProfileAboutSection from '../components/profile/ProfileAboutSection.jsx';
import EquipmentInformation from '../components/profile/EquipmentInformation.jsx';
import Connections from '../components/profile/Connections.jsx';
import ProfileGallery from '../components/galleries/ProfileGallery';
import { AuthContext } from '../providers/AuthProvider';
import { useParams } from 'react-router-dom';
import {
  EditOutlined,
  LineChartOutlined,
  MessageOutlined,
  MinusOutlined,
  MoreOutlined,
  PictureOutlined,
  PlusOutlined,
  ProfileOutlined,
  ToolOutlined,
  UserAddOutlined,
  UserDeleteOutlined,
  UsergroupAddOutlined,
  UserSwitchOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import {
  useConnectionRequest,
  useFollowUser,
  useUserConnections,
  useUserDetailsWithCode,
  useFollowers,
  useUserFollowedOrganizations,
  useUpdateUserImages,
  useCheckConnectionRequest,
  useAnswerRequest,
  useRemoveConnection,
  useCreateChatChannel,
  useUserBlock,
} from '../hooks/queries/UserQueries';

import { useInView } from 'react-intersection-observer';
import defaultProfileImage from '../assets/images/defaultProfilePhoto.jpeg';
import { UtilityContext } from '../providers/UtilityProvider';
import { useClientError } from '../hooks/useClientError';
import { uploadFile } from '../requests/UtilityManager';
import CreatePostForm from '../components/form/post/CreatePostForm';
import { useGetFeedHandler } from '../hooks/queries/FeedQueries';
import Repost from '../components/post/Repost';
import CropperComponent from '../helpers/utils/CropperComponent.tsx';
import SpinWrapper from '../components/utility/wrappers/SpinWrapper';
import EmptyWrapper from '../components/utility/wrappers/EmptyWrapper';
import Compressor from 'compressorjs';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useWindowSize from '../hooks/useWindowSize';
import UserGameStatistics from '../components/profile/UserGameStatistics';
import { getGenreIcon } from '../helpers/constants/constantHelpers';
import IconWrapper from '../components/hoc/IconWrapperHOC';

const { Text, Title } = Typography;

function Profile() {
  const { mutateAsync: updateUserImage } = useUpdateUserImages();
  const { ref, inView } = useInView();
  const [profileTab, setProfileTab] = useState('about');
  const [isOwner, setIsOwner] = useState(false);
  const [profileUpload, setProfileUpload] = useState(false);
  const [bannerUpload, setBannerUpload] = useState(false);
  const [loading, setLoading] = useState(false);

  const { user, openFromProfile } = useContext(AuthContext);
  const { openNotification, theme } = useContext(UtilityContext);
  const { code } = useParams();
  const { data: profileDetails, isLoading: profileDetailsLoading } = useUserDetailsWithCode(code);

  const { data: feed, fetchNextPage } = useGetFeedHandler(profileDetails?.id);
  const { data: userFollowers = [] } = useFollowers(profileDetails?.id);
  const { data: userConnections = [] } = useUserConnections(profileDetails?.id);
  const { data: userFollowedOrganizations = [] } = useUserFollowedOrganizations(profileDetails?.id);
  const { mutateAsync: followUser } = useFollowUser(profileDetails?.id);
  const { mutateAsync: sendConnection } = useConnectionRequest(profileDetails?.id);
  const { mutateAsync: removeConnection } = useRemoveConnection();
  const { data: connectionStatus = [] } = useCheckConnectionRequest(profileDetails?.id);
  const { mutateAsync: answerRequest } = useAnswerRequest();
  const { mutateAsync: createChat } = useCreateChatChannel();
  const { mutateAsync: blockUser } = useUserBlock();
  const { width } = useWindowSize();

  const translator = useIntl();
  const onClientError = useClientError();

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);

  useEffect(() => {
    if (profileDetails && user) {
      setIsOwner(profileDetails?.id === user.id);
      setProfileTab('about');
    }
  }, [profileDetails, user]);

  const profileTabController = useCallback((radioElement) => {
    setProfileTab(radioElement.target.value);
  }, []);

  let backgroundImageUrl =
    profileDetails?.userPhotos?.length > 0
      ? profileDetails?.userPhotos[0]?.banner_photo
      : BgProfile;
  let profileImageUrl =
    profileDetails?.userPhotos?.length > 0
      ? profileDetails?.userPhotos[0]?.profile_photo
      : defaultProfileImage;

  const changeProfileTab = (tabKey) => {
    setProfileTab(tabKey);
  };

  const profileDeterminer = () => {
    if (profileTab === 'about') {
      return (
        <ProfileAboutSection
          showMoreSteam={() => setProfileTab('statistics')}
          profileDetails={profileDetails}
          isOwner={isOwner}
          changeProfileTab={changeProfileTab}
        />
      );
    }
    if (profileTab === 'equipment') {
      return <EquipmentInformation profileDetails={profileDetails} isOwner={isOwner} />;
    }
    if (profileTab === 'follows') {
      return (
        <Connections
          profileId={profileDetails?.id}
          userConnections={userConnections}
          userFollowers={userFollowers}
          userFollowedOrganizations={userFollowedOrganizations}
          profileDetails={profileDetails}
          removeConnection={removeConnection}
          blockFollower={blockUser}
          isOwner={isOwner}
        />
      );
    }
  };

  function base64ToBlob(base64String, contentType) {
    contentType = contentType || '';

    let byteCharacters = atob(base64String);
    let byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      let slice = byteCharacters.slice(offset, offset + 512);

      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      let byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    let blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  function blobToBase64(blob, callback) {
    let reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      let base64String = reader.result;

      let base64WithoutPrefix = base64String.replace(/^data:[^;]+;base64,/, '');
      if (typeof callback === 'function') {
        callback(base64WithoutPrefix);
      }
    };
  }

  const profileUploadHandler = (data) => {
    setLoading(true);
    let formObject = {
      userId: user.id,
      banner_photo: backgroundImageUrl,
    };

    if (data.contentType === 'image/gif') {
      uploadFile(data)
        .then((response) => {
          if (response.status === 202) {
            formObject['profile_photo'] = response.data.data;
          }
        })
        .then(() => {
          updateUserImage(formObject)
            .then((response) => {
              if (response.status === 202) {
                openNotification('infoSaved', 'success');
                setProfileUpload(false);
              } else {
                openNotification('infoSaveError', 'error');
              }
            })
            .catch((err) => {
              onClientError(err);
            });
        })
        .finally(() => setLoading(false));
    } else {
      new Compressor(base64ToBlob(data.base64, data.contentType), {
        quality: 0.8,
        error: () => {
          openNotification('infoSaveError', 'error');
          setLoading(false);
        },
        success: (compressedResult) => {
          blobToBase64(compressedResult, function (base64String) {
            let newData = { ...data };
            newData['base64'] = base64String;

            uploadFile(newData)
              .then((response) => {
                if (response.status === 202) {
                  formObject['profile_photo'] = response.data.data;
                }
              })
              .then(() => {
                updateUserImage(formObject)
                  .then((response) => {
                    if (response.status === 202) {
                      openNotification('infoSaved', 'success');
                      setProfileUpload(false);
                    } else {
                      openNotification('infoSaveError', 'error');
                    }
                  })
                  .catch((err) => {
                    onClientError(err);
                  });
              })
              .finally(() => setLoading(false));
          });
        },
      });
    }
  };
  const bannerDeleteHandler = () => {
    let formObject = {
      userId: user.id,
      profile_photo: profileImageUrl,
      banner_photo: null,
    };
    updateUserImage(formObject)
      .then((response) => {
        if (response.status === 202) {
          openNotification('infoSaved', 'success');
          setBannerUpload(false);
        } else {
          openNotification('infoSaveError', 'error');
        }
      })
      .catch((err) => {
        onClientError(err);
      });
  };
  const profilePhotoDeleteHandler = () => {
    let formObject = {
      userId: user.id,
      profile_photo: null,
      banner_photo: backgroundImageUrl,
    };
    updateUserImage(formObject)
      .then((response) => {
        if (response.status === 202) {
          openNotification('infoSaved', 'success');
          setProfileUpload(false);
        } else {
          openNotification('infoSaveError', 'error');
        }
      })
      .catch((err) => {
        onClientError(err);
      });
  };

  const bannerUploadHandler = (data) => {
    console.log('selamn');
    setLoading(true);
    let formObject = {
      userId: user.id,
      profile_photo: profileImageUrl,
    };

    if (data.contentType === 'image/gif') {
      uploadFile(data)
        .then((response) => {
          if (response.status === 202) {
            formObject['banner_photo'] = response.data.data;
          }
        })
        .then(() => {
          updateUserImage(formObject)
            .then((response) => {
              if (response.status === 202) {
                openNotification('infoSaved', 'success');
                setBannerUpload(false);
              } else {
                openNotification('infoSaveError', 'error');
              }
            })
            .catch((err) => {
              onClientError(err);
            });
        })
        .finally(() => setLoading(false));
    } else {
      new Compressor(base64ToBlob(data.base64, data.contentType), {
        quality: 0.8,
        error: () => {
          openNotification('infoSaveError', 'error');
          setLoading(false);
        },
        success: (compressedResult) => {
          blobToBase64(compressedResult, function (base64String) {
            let newData = { ...data };
            newData['base64'] = base64String;

            uploadFile(newData)
              .then((response) => {
                if (response.status === 202) {
                  formObject['banner_photo'] = response.data.data;
                }
              })
              .then(() => {
                updateUserImage(formObject)
                  .then((response) => {
                    if (response.status === 202) {
                      openNotification('infoSaved', 'success');
                      setBannerUpload(false);
                    } else {
                      openNotification('infoSaveError', 'error');
                    }
                  })
                  .catch((err) => {
                    onClientError(err);
                  });
              })
              .finally(() => setLoading(false));
          });
        },
      });
    }
  };

  const startChat = () => {
    createChat({
      initiatingUserId: user?.id,
      receivingUserId: profileDetails?.id,
    }).then((response) => {
      if (response.status === 202) {
        openFromProfile(response?.data?.data);
      }
    });
  };

  const followHandler = () => {
    let isFollowing = userFollowers.includes(user?.id);
    followUser({
      userId: profileDetails.id,
      follower_user_id: user.id,
    }).then((response) => {
      if (response.status === 202) {
        if (isFollowing) {
          return openNotification('userFollowStopped', 'success');
        }
        return openNotification('userFollowed', 'success');
      }
    });
  };

  const connectionHandler = () => {
    if (userConnections.includes(user?.id)) {
      removeConnection(profileDetails?.id).then((response) => {
        return response.status === 202
          ? openNotification('connectionRemoved', 'success')
          : openNotification('unknownError', 'error');
      });
    } else if (connectionStatus.length > 0 && connectionStatus[0]?.id === user?.id) {
      answerRequest({
        request_id: connectionStatus[0].requestId,
        status: 2,
      }).then((response) => {
        return response.status === 202
          ? openNotification('networkRequestCancelled', 'success')
          : openNotification('unknownError', 'error');
      });
    } else if (connectionStatus.length > 0 && connectionStatus[0]?.id !== user?.id) {
      answerRequest({
        request_id: connectionStatus[0].requestId,
        status: 1,
      }).then((response) => {
        return response.status === 202
          ? openNotification('connectionApproved', 'success')
          : openNotification('unknownError', 'error');
      });
    } else {
      sendConnection({
        userId: user.id,
        follower_user_id: profileDetails.id,
      }).then((response) => {
        return response.status === 202
          ? openNotification('connectionRequestSent', 'success')
          : openNotification('unknownError', 'error');
      });
    }
  };

  const items = [
    {
      label: (
        <Flex align='center' onClick={connectionHandler} className='border-none duration-300'>
          {userConnections.includes(user?.id) ? (
            <UserDeleteOutlined className='pr-2' />
          ) : connectionStatus[0]?.id === user?.id ? (
            <UserSwitchOutlined className='pr-2' />
          ) : (
            <UserAddOutlined className='pr-2' />
          )}
          <Text className='text-inherit'>
            {userConnections.includes(user?.id) ? (
              <FormattedMessage id='removeConnection' />
            ) : connectionStatus.length === 0 ? (
              <FormattedMessage id='sendConnection' />
            ) : connectionStatus[0].id === user?.id ? (
              <FormattedMessage id='cancelConnection' />
            ) : (
              <FormattedMessage id='approve' />
            )}
          </Text>
        </Flex>
      ),
      key: '0',
    },
    {
      label: (
        <Flex onClick={followHandler} className='border-none duration-300'>
          {userFollowers.includes(user?.id) ? (
            <MinusOutlined className='pr-2' />
          ) : (
            <PlusOutlined className='pr-2' />
          )}
          <Text className='text-inherit'>
            {userFollowers.includes(user?.id) ? (
              <FormattedMessage id='unFollow' />
            ) : (
              <FormattedMessage id='follow' />
            )}
          </Text>
        </Flex>
      ),
      key: '1',
    },
    userConnections.includes(user?.id) && {
      key: '2',
      label: (
        <Flex align='center' className='border-none duration-300' onClick={() => startChat()}>
          <MessageOutlined className='pr-2' />
          <FormattedMessage id='sendMessage' />
        </Flex>
      ),
    },
    {
      key: '3',
      label:
        user?.id === profileDetails?.id ? (
          ''
        ) : (
          <Flex align='center' className='border-none duration-300'>
            <FontAwesomeIcon
              className='text-base rounded-full pr-2 outline-none cursor-pointer '
              icon={faBan}
              onClick={() => {
                confirmDelete(profileDetails?.id);
              }}
            />
            <FormattedMessage id='block' />
          </Flex>
        ),
    },
  ];
  const popOverContent = (
    <Flex className={width < 760 ? ' justify-between w-full' : ''} gap='0.5rem'>
      <Dropdown
        placement={width < 760 ? 'bottomLeft' : 'bottomCenter'}
        menu={{
          items,
        }}
      >
        <MoreOutlined className='p-2 rotate-90 rounded-full transition-all bg-container-color hover:bg-primary hover:text-white dark:bg-dark-mode-shadow-color dark:hover:bg-primary' />
      </Dropdown>
    </Flex>
  );

  if (profileDetailsLoading) {
    return <SpinWrapper />;
  }

  if (!profileDetails) {
    return <EmptyWrapper description={translator.formatMessage({ id: 'noUserMatched' })} />;
  }

  const confirmDelete = (id) => {
    Modal.confirm({
      title: translator.formatMessage({ id: 'blockApprovalMessage' }),
      okText: 'Evet',
      okType: 'danger',
      cancelText: 'İptal',
      onOk() {
        blockUser(id);
      },
    });
  };

  return (
    <div className={styles['profileRow']}>
      <Col span={24} md={24} className='col-info flex profile-nav-bg'>
        <Image
          preview={true}
          src={backgroundImageUrl?.length > 0 ? backgroundImageUrl : BgProfile}
          className='rounded-[8px] object-cover w-full'
          height={300}
          width='100%'
        />
      </Col>
      {isOwner && (
        <EditOutlined
          onClick={() => setBannerUpload(true)}
          className='absolute left-8 top-0 cursor-pointer text-yellow-600 hover:bg-yellow-200 p-2 rounded-full transition-all dark:hover:bg-yellow-600 dark:hover:text-white'
        />
      )}
      <Card
        bordered={false}
        className={`${styles['antdCard']} card-profile-head`}
        styles={{ body: { display: 'none' } }}
        title={
          <Row justify='space-between' align='middle' gutter={[24, 0]}>
            <Col span={24} md={12} className='col-info flex'>
              <Flex gap={width > 500 ? '1rem' : '.5rem'}>
                <Image
                  className='rounded-[8px] object-cover min-w-[110px]'
                  width={110}
                  height={110}
                  preview={true}
                  src={profileImageUrl ? profileImageUrl : defaultProfileImage}
                />
                {isOwner && (
                  <div onClick={() => setProfileUpload(true)}>
                    <EditOutlined className='cursor-pointer text-yellow-600 hover:bg-yellow-200 p-2 rounded-full transition-all dark:hover:bg-yellow-600 dark:hover:text-white absolute left-4 top-1' />
                  </div>
                )}
                <div className='avatar-info '>
                  <Flex align='center' className='items-center'>
                    <Title
                      level={4}
                      className='!mb-0 flex items-center font-semibold p-relative mr-2'
                    >
                      {profileDetails?.first_name} {profileDetails?.last_name}
                      {profileDetails?.badge_active && profileDetails?.steamgenre?.id > 0 && (
                        <div className='ml-2'>
                          <Tooltip
                            title={`${translator.formatMessage({ id: 'mostCategory' })}: ${
                              profileDetails?.steamgenre?.name
                            }`}
                          >
                            {getGenreIcon(profileDetails?.steamgenre?.name)}
                          </Tooltip>
                        </div>
                      )}
                      {/* <CrownTwoTone twoToneColor='#e6b429' className='text-2xl ml-4' /> */}
                    </Title>{' '}
                    {!isOwner && width > 780 && popOverContent}
                    {!isOwner && width < 760 && popOverContent}
                  </Flex>
                  <Text className='dark:text-dark-mode-gray'>{profileDetails?.username}</Text>
                </div>
              </Flex>
            </Col>
            <Col
              span={24}
              md={12}
              className={width < 780 ? styles['profileTabsMobile'] : styles['profileTabs']}
            >
              <Radio.Group onChange={profileTabController} value={profileTab}>
                <Radio.Button value='about'>
                  {width > 780 ? (
                    <FormattedMessage id='overview' />
                  ) : (
                    <IconWrapper>
                      <ProfileOutlined className='p-2' />
                    </IconWrapper>
                  )}
                </Radio.Button>
                <Radio.Button value='equipment'>
                  {width > 780 ? (
                    <FormattedMessage id='equipment' />
                  ) : (
                    <IconWrapper>
                      <ToolOutlined className='p-2' />
                    </IconWrapper>
                  )}
                </Radio.Button>
                <Radio.Button value='follows'>
                  {width > 780 ? (
                    <FormattedMessage id='follows' />
                  ) : (
                    <IconWrapper>
                      <UsergroupAddOutlined className='p-2' />
                    </IconWrapper>
                  )}
                </Radio.Button>
                <Radio.Button value='gallery'>
                  {width > 780 ? (
                    <FormattedMessage id='gallery' />
                  ) : (
                    <IconWrapper>
                      <PictureOutlined className='p-2' />
                    </IconWrapper>
                  )}
                </Radio.Button>
                {profileDetails?.steamgenre?.id > 0 && (
                  <Radio.Button value='statistics'>
                    {width > 780 ? (
                      <FormattedMessage id='gameStatistics' />
                    ) : (
                      <IconWrapper>
                        <LineChartOutlined className='p-2' />
                      </IconWrapper>
                    )}
                  </Radio.Button>
                )}
              </Radio.Group>
            </Col>
          </Row>
        }
      />

      <Row gutter={[16, 16]}>
        {profileTab !== 'gallery' ? (
          profileTab === 'statistics' ? (
            <Col span={24}>
              <UserGameStatistics
                userId={profileDetails?.id}
                profileDetails={profileDetails}
                isOwner={isOwner}
              />
            </Col>
          ) : (
            <>
              <Col xxl={8} xl={8} lg={8} md={8} sm={24} xs={24}>
                {profileDeterminer()}
              </Col>
              <Col
                className={styles['userFeedWrapper']}
                xxl={16}
                xl={16}
                lg={16}
                md={16}
                sm={24}
                xs={24}
              >
                {isOwner && (
                  <div className='mb-[1rem]'>
                    <CreatePostForm />
                  </div>
                )}
                <>
                  {feed?.pages?.map((page, pageIndex) => (
                    <Fragment key={pageIndex}>
                      {page.rows.map((post, index) => {
                        return (
                          <div
                            ref={index + 1 === page.rows.length ? ref : null}
                            className='mb-[1rem]'
                            key={index}
                          >
                            {post.relatedFeedId > 0 ? (
                              <Repost postDetail={post} postId={post.id} boosted={false} />
                            ) : (
                              <PostCard postDetail={post} postId={post.id} boosted={false} />
                            )}
                          </div>
                        );
                      })}
                    </Fragment>
                  ))}
                </>
              </Col>
            </>
          )
        ) : (
          <Col span={24}>
            <ProfileGallery
              userId={profileDetails?.id}
              profileDetails={profileDetails}
              isOwner={isOwner}
            />
          </Col>
        )}
      </Row>

      <Modal
        closeIcon={
          <CloseOutlined className='dark:text-dark-mode-text-color dark:hover:text-white duration-300 text-black' />
        }
        destroyOnClose={true}
        title={translator.formatMessage({ id: 'profilePhotoUpdate' })}
        open={profileUpload}
        onCancel={() => setProfileUpload(false)}
        footer={null}
      >
        <CropperComponent
          profilePhotoDeleteHandler={profilePhotoDeleteHandler}
          isLoading={loading}
          label='profilePhoto'
          returnedData={profileUploadHandler}
        />
      </Modal>
      <Modal
        closeIcon={
          <CloseOutlined className='dark:text-dark-mode-text-color dark:hover:text-white duration-300 text-black' />
        }
        destroyOnClose={true}
        title={translator.formatMessage({ id: 'bannerPhotoUpdate' })}
        open={bannerUpload}
        onCancel={() => setBannerUpload(false)}
        footer={null}
      >
        <CropperComponent
          bannerDeleteHandler={bannerDeleteHandler}
          isLoading={loading}
          label='bannerPhoto'
          returnedData={bannerUploadHandler}
        />
      </Modal>
    </div>
  );
}

export default Profile;
