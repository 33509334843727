import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../providers/AuthProvider';
import { useGetNotifications, useGetRequests } from '../hooks/queries/UserQueries';
import { Button, Col, Empty, Flex, Row, Typography } from 'antd';
import styles from '../style/notifications.module.scss';
import { BellOutlined, NotificationOutlined } from '@ant-design/icons';
import NotificationItem from '../components/notifications/NotificationItem';
import { FormattedMessage, useIntl } from 'react-intl';
import { readNotifications } from '../requests/UserManager';
import SpinWrapper from '../components/utility/wrappers/SpinWrapper';
import RequestNotification from '../components/notifications/RequestNotification';

const { Text } = Typography;

const NotificationsMobile = () => {
  const [pageDisplay, setPageDisplay] = useState('notifications');
  const { user } = useContext(AuthContext);
  const { data: notifications = [], isLoading: notificationsLoading } = useGetNotifications(
    user?.id,
  );
  const { data: requests = [], isLoading: requestsLoading } = useGetRequests(user?.id);
  const translator = useIntl();

  const readAndShowNotification = async () => {
    setPageDisplay('notifications');

    let unreadNotifications = notifications.filter((i) => i.read === false);
    if (unreadNotifications.length > 0) {
      await readNotifications(unreadNotifications.map((x) => x.id));
    }
  };

  const pageDisplayHandler = (value) => {
    value === 'notifications' ? readAndShowNotification() : setPageDisplay('requests');
  };

  if (notificationsLoading || requestsLoading) {
    return <SpinWrapper />;
  }

  return (
    <div className={styles['mobileNotificationsRow']}>
      <Flex gap='1rem' className='mb-8'>
        <Button
          block
          icon={<BellOutlined className='me-2' />}
          onClick={() => pageDisplayHandler('notifications')}
          type={pageDisplay === 'notifications' ? 'primary' : 'default'}
        >
          <Text className='text-inherit'>
            <FormattedMessage id='notifications' />
          </Text>
        </Button>
        <Button
          block
          icon={<NotificationOutlined className='me-2' />}
          onClick={() => pageDisplayHandler('requests')}
          type={pageDisplay === 'requests' ? 'primary' : 'default'}
        >
          <FormattedMessage id='requests' />
        </Button>
      </Flex>
      <div className={styles['mobileNotificationsWrapper']}>
        <>
          {pageDisplay === 'notifications' ? (
            notifications?.length !== 0 ? (
              notifications?.map((item, index) => <NotificationItem key={index} item={item} />)
            ) : (
              <Flex className='w-full' justify='center'>
                <Empty
                  description={translator.formatMessage({
                    id: 'noRequests',
                  })}
                />
              </Flex>
            )
          ) : requests?.length !== 0 ? (
            requests?.map((item, index) => <RequestNotification key={index} notification={item} />)
          ) : (
            <Flex className='w-full' justify='center'>
              <Empty
                description={translator.formatMessage({
                  id: 'noRequests',
                })}
              />
            </Flex>
          )}
        </>
      </div>
    </div>
  );
};

export default NotificationsMobile;
