import { Divider, Flex, Image, Typography } from 'antd';
import { truncateText } from '../../../helpers/constants/constantHelpers';
import { getRelativeTime } from '../../../helpers/utils/TimeFormatters';
import { useIntl } from 'react-intl';
import defaultProfilePhoto from '../../../assets/images/defaultProfilePhoto.jpeg';
import parse from 'html-react-parser';
import DOMPurify from 'dompurify';

const { Paragraph, Text } = Typography;

const MobileChatListItem = ({ onClick, otherUser, channel, unread }) => {
  const translator = useIntl();

  const sanitizedHtml = parse(
    DOMPurify.sanitize(
      truncateText(
        channel?.chatmessages?.contentMessage?.length > 0
          ? JSON.parse(channel?.chatmessages?.contentMessage)?.content?.includes(
              '<div data-youtube-video="">',
            )
            ? translator.formatMessage({ id: 'userSentVideo' })
            : JSON.parse(channel?.chatmessages?.contentMessage)?.content?.includes('<img')
            ? translator.formatMessage({ id: 'userSentGif' })
            : JSON.parse(channel?.chatmessages?.contentMessage)?.content
          : translator.formatMessage({ id: 'sayHi' }),
        30,
      ),
    ),
  );

  return (
    <div onClick={() => onClick(channel)}>
      <Flex className='relative' gap='1rem'>
        {unread && (
          <div className='absolute bottom-1/2 right-4 w-[10px] h-[10px] rounded-full'></div>
        )}
        <Image
          className='rounded-[8px] object-cover'
          width={70}
          height={70}
          preview={false}
          src={
            otherUser.userPhotos[0]?.profile_photo
              ? otherUser.userPhotos[0]?.profile_photo
              : defaultProfilePhoto
          }
        />
        <Flex vertical={true}>
          <Paragraph className='!mb-0'>
            {otherUser?.first_name} "{otherUser?.username}" {otherUser?.last_name}
          </Paragraph>
          <span>{getRelativeTime(channel?.chatmessages?.updated_at)}</span>
          <div className='max-w-[275px] truncate'>
            <Paragraph className='[&>p]:mb-0 !mb-0'>{sanitizedHtml}</Paragraph>
          </div>
        </Flex>
      </Flex>
      <Divider className='mb-0' />
    </div>
  );
};

export default MobileChatListItem;
