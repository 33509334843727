import axiosInstance from '../lib/service/axios';

export const setPersonalInformationRequest = async (formValues) => {
  let formObject = {
    action: 'savePersonals',
    personals: formValues,
  };
  return await axiosInstance.post('securedHandle', formObject);
};

export const checkUserName = async (name) => {
  let formObject = {
    action: 'namecheck',
    name: {
      name: name,
    },
  };
  const response = await axiosInstance.post('handle', formObject);
  return response.data.data;
};

export const setUserLanguage = async (language) => {
  let formObject = {
    action: 'saveUserLanguage',
    languagePayload: {
      language: language,
    },
  };
  return await axiosInstance.post('securedHandle', formObject);
};

export const getPersonalInformation = async (userId) => {
  let formObject = {
    action: 'getPersonals',
    user: {
      userId: userId,
    },
  };
  const response = await axiosInstance.post('securedHandle', formObject);
  return response.data.data;
};

export const updateProfileImages = async (imageObject) => {
  let formObject = {
    action: 'saveUserPhotos',
    userphoto: imageObject,
  };
  return await axiosInstance.post('securedHandle', formObject);
};

export const getUserSystemInformation = async (userId) => {
  let formObject = {
    action: 'getUserSystem',
    user: {
      userId: userId,
    },
  };
  const response = await axiosInstance.post('securedHandle', formObject);
  return response.data.data;
};

export const saveUserSystemInformation = async (formValues) => {
  let formObject = {
    action: 'saveUserSystem',
    usersystem: formValues,
  };
  return await axiosInstance.post('securedHandle', formObject);
};

export const getUserPlatformInformation = async (userId) => {
  let formObject = {
    action: 'getUserPlatform',
    user: {
      userId: userId,
    },
  };
  const response = await axiosInstance.post('securedHandle', formObject);
  return response.data.data;
};

export const saveUserPlatformInformation = async (formValues) => {
  let formObject = {
    action: 'saveUserPlatform',
    userplatform: formValues,
  };
  return await axiosInstance.post('securedHandle', formObject);
};

export const getUserAccessoryInformation = async (userId) => {
  let formObject = {
    action: 'getUserAccessories',
    user: {
      userId: userId,
    },
  };
  const response = await axiosInstance.post('securedHandle', formObject);
  return response.data.data;
};

export const saveUserAccessoryInformation = async (formValues) => {
  let formObject = {
    action: 'saveUserAccessories',
    useraccessories: formValues,
  };
  return await axiosInstance.post('securedHandle', formObject);
};

export const getUserConnections = async (userId) => {
  let formObject = {
    action: 'getUserConnections',
    user: {
      userId: userId,
    },
  };
  const response = await axiosInstance.post('securedHandle', formObject);
  return response.data.data;
};

export const getUserFollowedOrganizations = async (userId) => {
  let formObject = {
    action: 'getUserOrganizations',
    getOrganizationsPayload: {
      userId: userId,
      isManager: 0,
    },
  };
  const response = await axiosInstance.post('securedHandle', formObject);
  return response.data.data;
};

export const getUserConnectionDetails = async (userIdArray) => {
  let formObject = {
    action: 'connectionDetail',
    users: {
      userIds: userIdArray,
    },
  };
  return await axiosInstance.post('securedHandle', formObject);
};

export const getUserDetailsWithCode = async (code) => {
  let formObject = {
    action: 'getUserByCode',
    name: {
      name: code,
    },
  };

  const response = await axiosInstance.post('securedHandle', formObject);
  return response.data.data;
};

export const getUserDetailsWithId = async (id) => {
  let formObject = {
    action: 'getUserById',
    id: {
      id: id,
    },
  };

  const response = await axiosInstance.post('securedHandle', formObject);
  return response.data.data;
};

export const getFollowers = async (userId) => {
  let formObject = {
    action: 'getUserFollowers',
    user: {
      userId: userId,
    },
  };

  const response = await axiosInstance.post('securedHandle', formObject);
  return response.data.data;
};

export const followUserAction = async (followerObject) => {
  let formObject = {
    action: 'followUser',
    followpayload: followerObject,
  };

  return await axiosInstance.post('securedHandle', formObject);
};

export const sendConnectionRequestAction = async (requestObject) => {
  let formObject = {
    action: 'connectionRequest',
    followpayload: requestObject,
  };

  return await axiosInstance.post('securedHandle', formObject);
};

export const removeConnection = async (userId) => {
  let formObject = {
    action: 'removeConnection',
    followpayload: {
      follower_user_id: userId,
    },
  };

  return await axiosInstance.post('securedHandle', formObject);
};

export const getNotifications = async (userId) => {
  let formObject = {
    action: 'getNotifications',
    user: {
      userId: userId,
    },
  };

  const response = await axiosInstance.post('securedHandle', formObject);
  return response.data.data;
};

export const getChatChannels = async () => {
  let formObject = {
    action: 'getUserChatChannels',
  };

  const response = await axiosInstance.post('securedHandle', formObject);
  return response.data.data;
};

export const getUnreadChatChannels = async () => {
  let formObject = {
    action: 'getUnreadChatChannels',
  };

  const response = await axiosInstance.post('securedHandle', formObject);
  if (response.data.data.length > 0) {
    return response.data.data.map((i) => i.id);
  }
  return response.data.data;
};

export const getChannelMessages = async (channelId) => {
  let formObject = {
    action: 'getChatmessagesByChannelId',
    chatPayload: {
      chatchannelId: channelId,
    },
  };

  const response = await axiosInstance.post('securedHandle', formObject);
  return response.data.data;
};

export const readMessage = async (channelId) => {
  let formObject = {
    action: 'readUserChatChannel',
    userChannelPayload: {
      channelId: channelId,
    },
  };
  const response = await axiosInstance.post('securedHandle', formObject);
  return response.data.data;
};

export const postMessageHandler = async (channelId, message) => {
  let formObject = {
    action: 'postChat',
    chatPayload: {
      chatchannelId: channelId,
      message: message,
    },
  };

  const response = await axiosInstance.post('securedHandle', formObject);
  return response.data.data;
};

export const readNotifications = async (notifications) => {
  let formObject = {
    action: 'readNotifications',
    notificationPayload: {
      notifications: notifications,
    },
  };

  const response = await axiosInstance.post('securedHandle', formObject);
  return response.data.data;
};

export const getRequests = async (userId) => {
  let formObject = {
    action: 'getConnectionRequests',
    user: {
      userId: userId,
    },
  };

  const response = await axiosInstance.post('securedHandle', formObject);
  return response.data.data;
};

export const getRequestDetail = async (requestId) => {
  let formObject = {
    action: 'connectionRequestDetail',
    users: {
      userIds: [requestId],
    },
  };

  const response = await axiosInstance.post('securedHandle', formObject);
  return response.data.data.requestDetails;
};

export const answerRequest = async ({ request_id, status }) => {
  let formObject = {
    action: 'answerRequest',
    answerRequest: {
      request_id: request_id,
      status: status,
    },
  };

  return await axiosInstance.post('securedHandle', formObject);
};

export const createUserChatChannel = async ({ initiatingUserId, receivingUserId }) => {
  let formObject = {
    action: 'createChannels',
    createChannelPayload: {
      initiatingUserId: initiatingUserId,
      receivingUserId: receivingUserId,
    },
  };

  return await axiosInstance.post('securedHandle', formObject);
};

export const getConnectionDetail = async (connections, page) => {
  let formObject = {
    action: 'connectionDetail',
    users: {
      userIds: connections,
      page: page,
    },
  };

  const response = await axiosInstance.post('securedHandle', formObject);
  return response.data.data;
};

export const getUserGallery = async (userId, page) => {
  let formObject = {
    action: 'getFeedAttachments',
    getFeed: {
      userId: userId,
      page: page,
      limit: 6,
    },
  };

  const response = await axiosInstance.post('securedHandle', formObject);
  return response.data.data;
};

export const checkConnection = async (profileId) => {
  let formObject = {
    action: 'checkRequest',
    followpayload: {
      follower_user_id: profileId,
    },
  };

  const response = await axiosInstance.post('securedHandle', formObject);
  return response.data.data;
};

export const getUserBlockHandler = async () => {
  let formObject = {
    action: 'userBlocks',
  };

  const response = await axiosInstance.post('securedHandle', formObject);
  return response.data.data;
};

export const userBlockHandler = async (userId) => {
  let formObject = {
    action: 'blockuser',
    blockpayload: {
      blocked_user_id: userId,
    },
  };
  const response = await axiosInstance.post('securedHandle', formObject);
  return response.data.data;
};

export const userUnblockHandler = async (userId) => {
  const response = await axiosInstance.post('', userId);
  return response.data.data;
};

export const getUserDetailsByIdHandler = async (userIdArray, page) => {
  let formObject = {
    action: 'followerDetail',
    users: {
      userIds: userIdArray,
      page: page,
      limit: 6,
    },
  };

  const response = await axiosInstance.post('securedHandle', formObject);
  return response.data.data.followDetails;
};

export const saveUserDevice = async (token) => {
  let formObject = {
    action: 'userDevice',
    userDevicePayload: {
      device_token: token,
    },
  };

  const response = await axiosInstance.post('securedHandle', formObject);
  return response.data.data;
};

export const deleteUserDevice = async (token) => {
  let formObject = {
    action: 'deleteUserDevice',
    userDevicePayload: {
      device_token: token,
    },
  };

  const response = await axiosInstance.post('securedHandle', formObject);
  return response.data.data;
};

export const activatedUserHandler = async (userKey) => {
  let formObject = {
    action: 'activateUser',
    tokenPayload: {
      token: userKey,
    },
  };
  return await axiosInstance.post('handle', formObject);
};
