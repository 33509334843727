import './MentionList.scss';

import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Button, Typography } from 'antd';

const MentionList = forwardRef((props, ref) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { Text } = Typography;

  const selectItem = (index) => {
    const item = props.items[index];

    if (item) {
      props.command({ id: { ...item } });
    }
  };

  const upHandler = () => {
    setSelectedIndex((selectedIndex + props.items.length - 1) % props.items.length);
  };

  const downHandler = () => {
    setSelectedIndex((selectedIndex + 1) % props.items.length);
  };

  const enterHandler = () => {
    selectItem(selectedIndex);
  };

  useEffect(() => setSelectedIndex(0), [props.items]);

  useImperativeHandle(ref, () => ({
    onKeyDown: ({ event }) => {
      if (event.key === 'ArrowUp') {
        upHandler();
        return true;
      }

      if (event.key === 'ArrowDown') {
        downHandler();
        return true;
      }

      if (event.key === 'Enter') {
        enterHandler();
        return true;
      }

      return false;
    },
  }));

  return (
    <div className='p-4 pl-2 !rounded-[8px]'>
      {props.items.length ? (
        props.items.map((item, index) => (
          <Button
            className={`item ${index === '' ? 'is-selected' : ''}`}
            key={index}
            onClick={() => selectItem(index)}
          >
            {item.name}
          </Button>
        ))
      ) : (
        <Text className='p-4 !rounded-[8px]'>No result</Text>
      )}
    </div>
  );
});

MentionList.displayName = 'MentionList';

export default MentionList;
