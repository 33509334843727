import { Col, Flex, Image, Row, Skeleton, Typography } from 'antd';
import { FormattedMessage } from 'react-intl';
import styles from '../../style/notifications.module.scss';
import { useUserDetailsWithId, useUserDetailsWithCode } from '../../hooks/queries/UserQueries';
import { dateFormatter } from '../../helpers/utils/DateFormatter';
import { FieldTimeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import defaultProfilePhoto from '../../assets/images/defaultProfilePhoto.jpeg';
import { useGetFeedDetails } from '../../hooks/queries/FeedQueries';
import SpinWrapper from '../utility/wrappers/SpinWrapper';

const CommentNotification = ({ notification }) => {
  const { data: feedDetails } = useGetFeedDetails(parseInt(notification.related_parameter));
  const { data: profileDetailsById } = useUserDetailsWithId(notification.content);
  const { data: profileDetailsByCode } = useUserDetailsWithCode(notification.content);

  const profileDetails = profileDetailsById || profileDetailsByCode;
  const Text = Typography;

  let timeStamp = dateFormatter(notification.created_at);

  if (!profileDetails?.id) {
    return <SpinWrapper />;
  }

  const textHandler = () => {
    if (parseInt(notification.extra_parameter) - 1 > 0) {
      return (
        <>
          {profileDetails?.first_name} {profileDetails?.last_name} <FormattedMessage id='and' />{' '}
          {parseInt(notification.extra_parameter) - 1} <FormattedMessage id='commentNotification' />
        </>
      );
    } else {
      return (
        <>
          {profileDetails?.first_name} {profileDetails?.last_name}{' '}
          <FormattedMessage id='commentNotificationSingle' />
        </>
      );
    }
  };

  return (
    <div className='w-full margin-0 cursor-default'>
      <Flex gap='1rem'>
        <Link to={`/profile/${notification?.content}`}>
          <Image
            className='rounded-full object-cover'
            width={40}
            height={40}
            preview={false}
            placeholder={<Skeleton avatar active />}
            src={
              profileDetails?.userPhotos?.length > 0
                ? profileDetails?.userPhotos[0]?.profile_photo?.length > 0
                  ? profileDetails?.userPhotos[0]?.profile_photo
                  : defaultProfilePhoto
                : defaultProfilePhoto
            }
          />
        </Link>
        <Link to={`/shared/${feedDetails?.share}`}>
          <Text>{textHandler()}</Text>
        </Link>
      </Flex>
      <div className={styles['timeStamp']}>
        <Text className={styles['timeStampText']}>
          <FieldTimeOutlined /> {timeStamp[0]} <FormattedMessage id={timeStamp[1]} />
        </Text>
      </div>
    </div>
  );
};

export default CommentNotification;
