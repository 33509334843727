import Hero from '../components/preregister/Hero';
import { styles } from '../styles';
import { useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../providers/AuthProvider';



const PreRegister = ({oauthMode}) => {
  const navigate = useNavigate();
  const { userKey } = useParams();
  const { checkUserInformationOauth } = useContext(AuthContext);

  useEffect(() => {
    if (localStorage.getItem('access_token')) {
      if(oauthMode){
        checkUserInformationOauth();
      }
      navigate('/feed');
    }
  }, []);

  return (
    <div className='relative z-0'>
      <div className={styles.padding}>
        <Hero userKey={userKey}/>
      </div>
    </div>
  );
};

export default PreRegister;
