import React, { useContext } from 'react';
import { Typography } from 'antd';
import gameActorLogo from '../assets/images/game-actor-logo.png';
import gameActorLogoWhite from '../assets/images/game-actor-white.svg';
import { UtilityContext } from '../providers/UtilityProvider';
const TermsofServices = () => {
  const { theme } = useContext(UtilityContext);
  const getLanguageBrowser = navigator.language;
  const { Text, Title } = Typography;

  if (getLanguageBrowser === 'tr') {
    return (
      <>
        <div className='flex items-center justify-center md:justify-between'>
          <img
            src={theme ? gameActorLogoWhite : gameActorLogo}
            alt='logo'
            style={{ maxWidth: '240px' }}
            className='object-contain'
          />
        </div>
        <Title className='mt-12' level={2}>
          Hizmet Şartları
        </Title>
        <Title level={3}>Yürürlük Tarihi: 26.08.2024</Title>
        <Text>
          Game Actor’a hoş geldiniz! Bu Hizmet Şartları ("Şartlar"), Game Actor platformunun
          kullanımınızı, platform tarafından sunulan içerik, özellikler ve hizmetler dahil olmak
          üzere düzenler. Game Actor’u kullanarak, bu Şartlara uymayı ve bunlarla bağlı olmayı kabul
          etmiş olursunuz.
        </Text>
        <br />
        <br />
        <Title level={4} className='font-bold'>
          1. Şartların Kabulü
        </Title>
        <Text>
          Game Actor’a erişerek veya kullanarak, bu Şartlara, Gizlilik Politikamıza ve sağladığımız
          diğer politika veya yönergelere bağlı olmayı kabul edersiniz. Bu Şartları kabul
          etmiyorsanız, lütfen platformumuzu kullanmayın.
        </Text>

        <br />
        <br />
        <Title level={4}>2. Uygunluk</Title>
        <Text>
          Game Actor’u kullanabilmek için en az 13 yaşında olmalısınız. Platformu kullanarak, bu yaş
          gereksinimini karşıladığınızı ve gönderdiğiniz tüm kayıt bilgilerinin doğru ve eksiksiz
          olduğunu beyan ve taahhüt etmiş olursunuz.
        </Text>

        <br />
        <br />

        <Title level={4}>3. Hesap Kaydı</Title>
        <Text>
          Game Actor’un belirli özelliklerine erişmek için bir hesap oluşturmanız gerekebilir. Kayıt
          sırasında, doğru, güncel ve eksiksiz bilgi sağlamayı kabul edersiniz. Hesabınızın ve
          şifrenizin gizliliğini korumaktan siz sorumlusunuz ve hesabınız altında gerçekleşen tüm
          faaliyetlerden sorumlu olmayı kabul edersiniz.
        </Text>
        <br />
        <br />

        <Title level={4}>4. Kullanıcı Davranışı</Title>
        <Text>
          <strong>
            Game Actor’u yalnızca yasal amaçlar için ve bu Şartlarla ve geçerli yasalar ve
            düzenlemelerle uyumlu bir şekilde kullanmayı kabul edersiniz. Aşağıdaki faaliyetlerde
            bulunmamayı kabul edersiniz:
          </strong>
        </Text>
        <ul>
          <li>
            <Text>
              Yasadışı, saldırgan, iftira niteliğinde veya üçüncü taraf haklarını ihlal eden içerik
              yayınlamak, paylaşmak veya iletmek.
            </Text>
          </li>
          <li>
            <Text>Platformun diğer kullanıcılarına taciz, suistimal veya zarar vermek.</Text>
          </li>
          <li>
            <Text>
              Başka bir kişi veya kurumu taklit etmek veya başka bir kişi veya kurumla ilişkinizi
              yanlış beyan etmek.
            </Text>
          </li>
          <li>
            <Text>
              Platformun işlevselliğini, güvenliğini veya performansını bozmak veya kesintiye
              uğratmak.
            </Text>
          </li>
          <li>
            <Text>
              Diğer kullanıcıların hesaplarına izinsiz erişim dahil olmak üzere, herhangi bir
              dolandırıcılık faaliyetiyle meşgul olmak.
            </Text>
          </li>
        </ul>
        <br />
        <br />

        <Title level={4}>5. İçerik Mülkiyeti ve Lisans</Title>
        <ul>
          <li>
            <Text>
              Sizin İçeriğiniz: Game Actor’da paylaştığınız içeriğin mülkiyeti size aittir. İçerik
              yayınlayarak, Game Actor’a içeriğinizi platformla bağlantılı olarak kullanmak,
              çoğaltmak, dağıtmak, sergilemek ve performe etmek için dünya çapında, telifsiz,
              münhasır olmayan bir lisans vermiş olursunuz.
            </Text>
          </li>
          <li>
            <Text>
              Game Actor İçeriği: Game Actor ile ilişkili tüm içerik, ticari markalar, logolar ve
              fikri mülkiyet hakları, Game Actor veya lisans verenlerinin mülkiyetindedir. Game
              Actor içeriğini yazılı iznimiz olmadan kullanamaz, çoğaltamaz veya dağıtamazsınız.
            </Text>
          </li>
        </ul>
        <br />
        <br />

        <Title level={4}>6. Fesih</Title>
        <Text>
          Bu Şartları ihlal ettiğinize veya yasa dışı ya da zararlı faaliyetlerde bulunduğunuza
          inanırsak, hesabınızı herhangi bir zamanda, bildirimde bulunarak veya bulunmayarak askıya
          alma veya sonlandırma hakkını saklı tutarız. Fesih durumunda, Game Actor’u kullanma
          hakkınız derhal sona erer ve hesabınıza bağlı olan içerik veya veriler silinebilir.
        </Text>

        <br />
        <br />

        <Title level={4}>7. Feragatnameler</Title>
        <Text>
          Game Actor, "olduğu gibi" ve "mevcut olduğu gibi" sağlanmaktadır. Platformun doğruluğu,
          güvenilirliği veya kullanılabilirliği konusunda herhangi bir garanti veya beyan
          vermemekteyiz. Yasaların izin verdiği en geniş ölçüde, ticarete elverişlilik, belirli bir
          amaca uygunluk ve ihlal etmeme gibi açık veya zımni tüm garantilerden feragat ederiz.
        </Text>
        <br />
        <br />
        <Title level={4}>8. Sorumluluğun Sınırlandırılması</Title>
        <Text>
          Yasaların izin verdiği en geniş ölçüde, Game Actor ve iştirakleri, platformun kullanımıyla
          ilgili olarak ortaya çıkan dolaylı, tesadüfi, özel, sonuç olarak meydana gelen veya cezai
          zararlardan, kâr kaybı dahil, sorumlu tutulamaz.
        </Text>
        <br />
        <br />
        <Title level={4}>9. Bu Şartlardaki Değişiklikler</Title>
        <Text>
          Bu Şartları zaman zaman güncelleyebiliriz. Önemli değişiklikler yaparsak, güncellenmiş
          Şartları platformda yayınlayarak veya diğer iletişim kanalları aracılığıyla sizi
          bilgilendireceğiz. Game Actor’u kullanmaya devam etmeniz, yeni Şartları kabul ettiğiniz
          anlamına gelir.
        </Text>
        <br />
        <br />

        <Title level={4}>10. Geçerli Hukuk</Title>
        <Text>
          Bu Şartlar, Türkiye Cumhuriyeti yasalarına tabi olacak ve bu yasalara göre
          yorumlanacaktır. Bu Şartlardan doğan herhangi bir uyuşmazlık, Türkiye Cumhuriyeti
          mahkemelerinde münhasıran çözülecektir.
        </Text>
        <br />
        <br />

        <Title level={4}>11. İletişim</Title>
        <Text>
          <strong>
            Bu Şartlar hakkında herhangi bir sorunuz veya endişeniz varsa, lütfen bizimle iletişime
            geçin:
          </strong>
        </Text>
        <br />
        <Text>
          <strong>E-posta:</strong> info@game.actor
        </Text>
        <br />
        <Text>
          <strong>Adres:</strong> Maslak, Eski Büyükdere Cd. No:21, 34485 Sarıyer/İstanbul
        </Text>
        <br />
        <br />
      </>
    );
  } else if (getLanguageBrowser === 'en' || getLanguageBrowser === 'en-US') {
    return (
      <>
        <div className='flex items-center justify-center md:justify-between'>
          <img
            src={theme ? gameActorLogoWhite : gameActorLogo}
            alt='logo'
            style={{ maxWidth: '240px' }}
            className='object-contain'
          />
        </div>
        <Title className='mt-12' level={2}>
          Terms of Service
        </Title>
        <Title level={3}>Effective Date: 26.08.2024</Title>
        <Text>
          Welcome to Game Actor! These Terms of Service (“Terms”) govern your use of the Game Actor
          platform, including any content, features, and services provided by or through the
          platform. By using Game Actor, you agree to comply with and be bound by these Terms.
        </Text>
        <br />
        <br />
        <Title level={4} className='font-bold'>
          1. Acceptance of Terms
        </Title>
        <Text>
          By accessing or using Game Actor, you agree to be bound by these Terms, our Privacy
          Policy, and any other policies or guidelines we provide. If you do not agree to these
          Terms, please do not use our platform.
        </Text>
        <br />
        <br />
        <Title level={4}>2. Eligibility</Title>
        <Text>
          To use Game Actor, you must be at least 13 years old. By using the platform, you represent
          and warrant that you meet this age requirement and that all registration information you
          submit is accurate and truthful.
        </Text>
        <br />
        <br />
        <Title level={4}>3. Account Registration</Title>
        <Text>
          You may need to create an account to access certain features of Game Actor. When
          registering, you agree to provide accurate, current, and complete information. You are
          responsible for maintaining the confidentiality of your account and password, and you
          agree to accept responsibility for all activities that occur under your account.
        </Text>
        <br />
        <br />
        <Title level={4}>4. User Conduct</Title>
        <Text>
          <strong>
            You agree to use Game Actor only for lawful purposes and in a manner consistent with
            these Terms and any applicable laws and regulations. You agree not to:
          </strong>
        </Text>
        <ul>
          <li>
            <Text>
              Post, share, or transmit content that is illegal, offensive, defamatory, or violates
              any third-party rights.
            </Text>
          </li>
          <li>
            <Text>Harass, abuse, or harm other users of the platform.</Text>
          </li>
          <li>
            <Text>
              Impersonate any person or entity, or falsely state or misrepresent your affiliation
              with any person or entity.
            </Text>
          </li>
          <li>
            <Text>
              Disrupt or interfere with the platform’s functionality, security, or performance.
            </Text>
          </li>
          <li>
            <Text>
              Engage in any fraudulent activity, including unauthorized access to other users’
              accounts.
            </Text>
          </li>
        </ul>
        <br />
        <br />
        <Title level={4}>5. Content Ownership and License</Title>
        <ul>
          <li>
            <Text>
              <strong>Your Content:</strong> You retain ownership of the content you post on Game
              Actor. By posting content, you grant Game Actor a non-exclusive, worldwide,
              royalty-free license to use, reproduce, distribute, display, and perform your content
              in connection with the platform.
            </Text>
          </li>
          <li>
            <Text>
              <strong>Game Actor Content:</strong> All content, trademarks, logos, and intellectual
              property associated with Game Actor are the property of Game Actor or its licensors.
              You may not use, reproduce, or distribute any Game Actor content without our prior
              written consent.
            </Text>
          </li>
        </ul>
        <br />
        <br />
        <Title level={4}>6. Termination</Title>
        <Text>
          We reserve the right to suspend or terminate your account at any time, with or without
          notice, if we believe you have violated these Terms or engaged in unlawful or harmful
          activities. Upon termination, your right to use Game Actor will immediately cease, and any
          content or data associated with your account may be deleted.
        </Text>
        <br />
        <br />
        <Title level={4}>7. Disclaimers</Title>
        <Text>
          Game Actor is provided on an “as-is” and “as-available” basis. We make no warranties or
          representations regarding the accuracy, reliability, or availability of the platform. To
          the fullest extent permitted by law, we disclaim all warranties, express or implied,
          including warranties of merchantability, fitness for a particular purpose, and
          non-infringement.
        </Text>
        <br />
        <br />
        <Title level={4}>8. Limitation of Liability</Title>
        <Text>
          To the fullest extent permitted by law, Game Actor and its affiliates shall not be liable
          for any indirect, incidental, special, consequential, or punitive damages, including lost
          profits, arising out of or in connection with your use of the platform.
        </Text>
        <br />
        <br />
        <Title level={4}>9. Changes to These Terms</Title>
        <Text>
          We may update these Terms from time to time. If we make significant changes, we will
          notify you by posting the updated Terms on the platform or through other communication
          channels. Your continued use of Game Actor after any changes indicates your acceptance of
          the new Terms.
        </Text>
        <br />
        <br />
        <Title level={4}>10. Governing Law</Title>
        <Text>
          These Terms shall be governed by and construed in accordance with the laws of Turkish
          Justice System. Any disputes arising under these Terms shall be resolved exclusively in
          the courts of Turkish Justice System.
        </Text>
        <br />
        <br />
        <Title level={4}>11. Contact Us</Title>
        <Text>
          <strong>
            If you have any questions or concerns about these Terms, please contact us at:
          </strong>
        </Text>
        <br />
        <Text>
          <strong>Email:</strong> info@game.actor
        </Text>
        <br />
        <Text>
          <strong>Address:</strong> Maslak, Eski Büyükdere Cd. No:21, 34485 Sarıyer/İstanbul
        </Text>
        <br />
        <br />
      </>
    );
  }

  return <></>;
};

export default TermsofServices;
