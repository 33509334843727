import RequestNotification from './RequestNotification';
import { Card, Divider, Empty, Typography } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';

const Requests = ({ requestList }) => {
  const { Text } = Typography;
  const translator = useIntl();
  return (
    <Card className='absolute top-[60px] left-[-185px]'>
      <div className='max-h-[400px] min-w-[380px] overflow-x-hidden rounded-[8px] cursor-default'>
        {requestList?.length > 0 ? (
          requestList.map((item, index) => {
            return (
              <>
                <RequestNotification key={index} notification={item} />
                <Divider />
              </>
            );
          })
        ) : (
          <Empty
            description={
              <Text className='!text-base'>
                <FormattedMessage id='noRequests' />
              </Text>
            }
          />
        )}
      </div>
    </Card>
  );
};

export default Requests;
