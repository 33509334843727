import { Button, Col, Form, Input, Row, Radio, Checkbox, Flex, Image, Select } from 'antd';
import { useCreateEvent, useUpdateEvent } from '../../../hooks/queries/EventQueries';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { CustomDatePicker, SelectComponent } from '../filters/Inputs';
import TipTap from '../../editor/TipTap';
import FileUploader from '../../../helpers/utils/FileUploader';
import useFileUploader from '../../../hooks/useFileUploader';
import { REQUIRED_RULE } from '../../../helpers/constants/constantHelpers';
import { useClientError } from '../../../hooks/useClientError';
import { UtilityContext } from '../../../providers/UtilityProvider';
import { AuthContext } from '../../../providers/AuthProvider';
import dayjs from 'dayjs';
import { DeleteOutlined } from '@ant-design/icons';

const EventCreateForm = ({ eventDetails, eventTags, closer }) => {
  const { userOwnedPages } = useContext(AuthContext);
  const { openNotification } = useContext(UtilityContext);
  const [loading, setLoading] = useState(false);
  const [isOrganization, setIsOrganization] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [existingFileList, setExistingFileList] = useState(eventDetails?.event_attachments);

  const { mutateAsync } = useCreateEvent();
  const { mutateAsync: updateEvent } = useUpdateEvent();
  const { handleFileUpload } = useFileUploader();

  const [eventForm] = Form.useForm();
  const translator = useIntl();
  const onClientError = useClientError();

  const formHandler = async (formValues) => {
    setLoading(true);
    const uploadedFiles = await handleFileUpload(fileList);
    let eventTags = [];
    if (formValues['event_filters']?.length > 0) {
      for (let option of formValues['event_filters']) {
        eventTags.push({
          filter_key: 'tag',
          value: option,
        });
      }
    }

    formValues['event_filters'] = eventTags;
    formValues['event_attachments'] = uploadedFiles;

    if (eventDetails) {
      let updatedFiles = existingFileList.map((item) => ({
        url: item.attachmentUrl,
        type: item.type,
      }));
      formValues['event_attachments'] = [...formValues['event_attachments'], ...updatedFiles];

      updateEvent({ ...formValues, id: eventDetails?.id })
        .then((response) => {
          if (response.status === 202) {
            openNotification('eventUpdated', 'success');
            eventForm.resetFields();
            closer();
          }
        })
        .catch((err) => onClientError(err))
        .finally(() => setLoading(false));
    } else {
      mutateAsync(formValues)
        .then((response) => {
          if (response.status === 202) {
            openNotification('eventCreated', 'success');
            eventForm.resetFields();
            closer();
          }
        })
        .catch((err) => onClientError(err))
        .finally(() => setLoading(false));
    }
  };

  const tipTapOnChangeHandler = (tipTapValue) => {
    eventForm.setFieldValue('description', tipTapValue);
  };

  const disabledDate = (current) => {
    return current && current < dayjs().startOf('day');
  };

  const handleExistingFileList = (url) => {
    let shallowArray = [...existingFileList];
    let indexOfItem = shallowArray.findIndex((file) => file.attachmentUrl === url);
    shallowArray.splice(indexOfItem, 1);
    setExistingFileList(shallowArray);
  };

  useEffect(() => {
    if (eventDetails) {
      setIsOrganization(eventDetails.organizationId > 0);
      eventForm.setFieldsValue({
        ...eventDetails,
        organization_id: eventDetails?.organizationId,
        event_filters: eventDetails?.event_filters.map((i) => i.value),
      });
    }
  }, [eventDetails]);

  return (
    <Form
      initialValues={{ event_type: 1, organization_id: eventDetails?.organizationId }}
      layout='vertical'
      form={eventForm}
      onFinish={formHandler}
    >
      <Row gutter={[16, 0]}>
        <Col span={24}>
          <Form.Item name='event_type'>
            <Radio.Group>
              <Radio value={1}>Online</Radio>
              <Radio value={2}>Offline</Radio>
            </Radio.Group>
          </Form.Item>
        </Col>
        <Col className='flex flex-col gap-4 mb-4' span={24}>
          <Checkbox
            checked={isOrganization}
            onChange={(event) => setIsOrganization(event.target.checked)}
          >
            <FormattedMessage id='createFromOrganization' />
          </Checkbox>
        </Col>
        {isOrganization && (
          <Col span={24}>
            <Form.Item
              name='organization_id'
              label={translator.formatMessage({ id: 'organization' })}
            >
              <SelectComponent
                options={userOwnedPages}
                translationId='organization'
                fieldNames={{
                  label: 'name',
                  value: 'id',
                }}
                fieldValue={eventForm.getFieldValue('organization_id')}
                filterOption={(input, option) =>
                  (option.name.toLocaleLowerCase() ?? '').includes(input.toLocaleLowerCase())
                }
                onChangeRemote={(values) => eventForm.setFieldValue('organization_id', values)}
              />
            </Form.Item>
          </Col>
        )}
        <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
          <Form.Item
            rules={REQUIRED_RULE}
            name='name'
            label={translator.formatMessage({ id: 'eventName' })}
          >
            <Input placeholder={translator.formatMessage({ id: 'eventName' })} />
          </Form.Item>
        </Col>
        <Col xxl={12} xl={12} lg={12} md={12} sm={24} xs={24}>
          <Form.Item
            rules={REQUIRED_RULE}
            name='event_date'
            label={translator.formatMessage({ id: 'eventDate' })}
          >
            <CustomDatePicker time={true} disabledDate={disabledDate} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item
            rules={REQUIRED_RULE}
            name='address'
            label={translator.formatMessage({ id: 'eventAddress' })}
          >
            <Input placeholder={translator.formatMessage({ id: 'eventAddress' })} />
          </Form.Item>
        </Col>
        <Col span={24}>
          <Form.Item name='event_filters' label={translator.formatMessage({ id: 'tags' })}>
            <Select
              placeholder={translator.formatMessage({ id: 'tags' })}
              options={eventTags?.data}
              mode='multiple'
              fieldNames={{
                label: 'name',
                value: 'value',
              }}
              filterOption={(input, option) =>
                (option.name.toLocaleLowerCase() ?? '').includes(input.toLocaleLowerCase())
              }
            />
          </Form.Item>
        </Col>
        {!!eventDetails && (
          <Col span={24}>
            <Flex wrap={true}>
              <>
                {existingFileList?.map(({ attachmentUrl }, index) => {
                  return (
                    <div className='relative' key={index}>
                      <DeleteOutlined
                        onClick={() => handleExistingFileList(attachmentUrl)}
                        className='absolute right-[5px] top-[5px] z-10 cursor-pointer text-red-600 hover:bg-red-200 p-2 rounded-full transition-all dark:hover:bg-red-600 dark:hover:text-white'
                      />
                      <Image
                        src={attachmentUrl}
                        preview={false}
                        width={160}
                        height='auto'
                        className='rounded-[8px] object-contain'
                      />
                    </div>
                  );
                })}
              </>
            </Flex>
          </Col>
        )}
        <Col span={24}>
          <FileUploader
            formName='event_attachments'
            accept={window.config.acceptedMediaTypes}
            placeholderId='photos'
            multiple={true}
            split={true}
            bucketCallBack={(file) => setFileList(file)}
            placeholderInline='photos'
          />
        </Col>
        <Col span={24}>
          <Form.Item name='description'>
            <TipTap
              defaultValue={eventForm.getFieldValue('description')}
              isEmojiVisible={false}
              isGifVisible={false}
              forwardOnChange={tipTapOnChangeHandler}
            />
          </Form.Item>
        </Col>
      </Row>
      <Button htmlType='submit' type='primary' loading={loading}>
        {eventDetails ? <FormattedMessage id='update' /> : <FormattedMessage id='create' />}
      </Button>
    </Form>
  );
};

export default EventCreateForm;
