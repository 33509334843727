import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Highlight from '@tiptap/extension-highlight';
import Table from '@tiptap/extension-table';
import Link from '@tiptap/extension-link';
import Image from '@tiptap/extension-image';
import TableRow from '@tiptap/extension-table-row';
import Typography from '@tiptap/extension-typography';
import React, { useEffect, useRef, useState } from 'react';
import styles from '../../style/editor.module.scss';
import '../../style/editor.module.scss';
import {
  LowerCase,
  UpperCase,
  CustomExtension,
  TipTapExtensionTableCell,
  TipTapExtensionTableHeader,
  CustomBulletList,
} from './modules/CustomExtensions';
import ToolBar from './modules/ToolBar';
import { useIntl } from 'react-intl';
import { Placeholder } from '@tiptap/extension-placeholder';
import EmojiPickerComponent from '../utility/EmojiPickerComponent';
import Mention from '@tiptap/extension-mention';
import suggestion from './Suggestion.jsx';
import { Extension } from '@tiptap/core';
import { Youtube } from '@tiptap/extension-youtube';
import GifPickerComponent from '../utility/GifPickerComponent';
import useWindowSize from '../../hooks/useWindowSize.jsx';
import CharacterCount from '@tiptap/extension-character-count';
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import { FormattedMessage } from 'react-intl';

const TipTap = ({
  isEmojiVisible,
  isGifVisible,
  gifViewHandler,
  chat = 0,
  comment = false,
  formFinish,
  toolbar = true,
  placeholder = 'postTextareaPlaceHolder',
  forwardOnChange,
  defaultValue,
}) => {
  const { width } = useWindowSize();
  const limit = 280;
  const EnterKeyHandler = Extension.create({
    addKeyboardShortcuts() {
      return {
        Enter: (e) => {
          if (this.editor) {
            if (comment || (width < 760 && chat > 0)) return false;
          }
          formFinish();
          return true;
        },
      };
    },
  });

  const translator = useIntl();

  const editorConfigs = useEditor({
    extensions: [
      chat > 0 && EnterKeyHandler,
      Document,
      Paragraph,
      Text,
      CharacterCount.configure(chat > 0 && { limit }),
      StarterKit.configure({
        blockquote: {
          HTMLAttributes: {
            style: 'padding-left: 2rem; margin: 0;',
          },
        },
        bulletList: false,
      }),
      Mention.configure({
        HTMLAttributes: {
          class: 'mention',
        },
        suggestion,
        renderHTML({ options, node }) {
          return [
            'a',
            {
              href:
                node.attrs.id?.type === 1
                  ? `/profile/${node.attrs.id?.name}`
                  : `/organization/${node.attrs.id?.name}`,
              style: 'color : #69b1ff',
              target: '_self',
            },
            `${node.attrs.id?.name}`,
          ];
        },
      }),
      Link.configure({
        autolink: false,
        linkOnPaste: false,
        openOnClick: true,
      }),
      CustomBulletList,
      Table.configure({
        resizable: true,
      }),
      TableRow,
      TipTapExtensionTableCell,
      TipTapExtensionTableHeader,
      Typography,
      UpperCase,
      LowerCase,
      Highlight.configure({
        multicolor: true,
      }),
      CustomExtension,
      Placeholder.configure({
        placeholder: translator.formatMessage({ id: placeholder }),
      }),
      Youtube.configure({
        inline: true,
        width: '100%',
        height: 400,
        nocookie: true,
      }),
      Image.configure({
        inline: true,
        HTMLAttributes: {
          style: 'height: 200px; display:block; margin:0 auto; object-fit:contain;',
        },
      }),
    ],
    content: `
        <p>
          Let‘s make sure people can’t write more than 280 characters. I bet you could build one of the biggest social networks on that idea.
        </p>
      `,
    editable: true,
    onUpdate: ({ editor }) => {
      forwardOnChange(editor.getHTML());
    },
  });

  const emojiClickHandler = (returnedValue) => {
    editorConfigs.commands.insertContent(returnedValue.native);
  };

  const gifClickHandler = (returnedValue) => {
    editorConfigs.chain().focus().setImage({ src: returnedValue.url }).run();
    gifViewHandler();
  };

  useEffect(() => {
    editorConfigs?.chain().setContent(defaultValue).run();
  }, [editorConfigs, defaultValue]);

  useEffect(() => {
    if (chat > 0 && editorConfigs) {
      editorConfigs?.commands.focus();
    }
  }, [chat, editorConfigs]);

  if (!editorConfigs) {
    return null;
  }

  const percentage = editorConfigs
    ? Math.round((100 / limit) * editorConfigs.storage.characterCount.characters())
    : 0;

  return (
    <div className={styles['editorWrapper']}>
      {toolbar && <ToolBar editor={editorConfigs} />}
      <div className='mt-2'>
        <EditorContent
          className='border border-solid border-border-color dark:border-dark-mode-light-border-color duration-300 rounded-md'
          editor={editorConfigs}
        />
        {chat > 0 && !comment && (
          <div className='flex pt-3'>
            <svg className='mr-4' height='20' width='20' viewBox='0 0 20 20'>
              <circle r='10' cx='10' cy='10' fill='#5A5A5A' />
              <circle
                r='5'
                cx='10'
                cy='10'
                fill='transparent'
                stroke='currentColor'
                strokeWidth='10'
                strokeDasharray={`calc(${percentage} * 31.4 / 100) 31.4`}
                transform='rotate(-90) translate(-20)'
              />
              <circle r='6' cx='10' cy='10' fill='#323232' />
            </svg>
            {editorConfigs.storage.characterCount.characters()} / {limit}{' '}
            <FormattedMessage id='characters' /> {editorConfigs.storage.characterCount.words()}{' '}
            <FormattedMessage id='words' />
          </div>
        )}
      </div>
      {isEmojiVisible && <EmojiPickerComponent emojiClickCallBack={emojiClickHandler} />}
      {isGifVisible && <GifPickerComponent gifClickCallback={gifClickHandler} />}
    </div>
  );
};

export default TipTap;
