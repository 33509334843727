import { Col, Row, Space, Typography } from 'antd';
import SteamLibrary from '../../integrations/steam/SteamLibrary';
import { FormattedMessage, useIntl } from 'react-intl';
import { WarningTwoTone } from '@ant-design/icons';
import GameCard from '../../cards/GameCard';
import { useContext } from 'react';
import { AuthContext } from '../../../providers/AuthProvider';
import { useGetUserSteamLibrary } from '../../../hooks/queries/IntegrationQueries';
import SteamAchievements from '../../integrations/steam/SteamAchievements';
import { DrawerProviderContext } from '../../../providers/DrawerProvider';
import SpinWrapper from '../../utility/wrappers/SpinWrapper';

const { Text } = Typography;

const SteamIntegrationTab = ({ profileDetails, isOwner, showMoreSteam }) => {
  const { openDrawer } = useContext(DrawerProviderContext);
  const { data: userSteamLibrary, isLoading } = useGetUserSteamLibrary(profileDetails?.id);
  const userSteamId = profileDetails?.steam_id;
  const translator = useIntl();

  const achievementDrawerHandler = (steamApp) => {
    openDrawer({
      title: translator.formatMessage({ id: 'achievements' }),
      content: (
        <SteamAchievements
          userId={profileDetails?.id}
          isOwner={isOwner}
          image={steamApp.header_image}
          id={steamApp.app_id}
        />
      ),
      drawerProps: {},
      footer: null,
    });
  };

  const renderNoSteamIntegrated = () => (
    <Text>
      <FormattedMessage id='noSteamIntegrated' />
    </Text>
  );

  const renderSteamIntegrationWarning = () => (
    <Text className='text-sm mb-2 block'>
      <WarningTwoTone className='mr-1' twoToneColor='red' />
      <FormattedMessage id='steamIntegrationPublicWarn' />
    </Text>
  );

  const renderGameCards = () => (
    <Row gutter={[16, 16]}>
      {userSteamLibrary?.slice(0, 3).map(({ steamapp, time_played }, index) => (
        <Col key={index} xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
          <GameCard
            genres={steamapp?.steamappgenres}
            gameTime={time_played}
            url={steamapp?.header_image}
            showAchievements={() => achievementDrawerHandler(steamapp)}
          />
        </Col>
      ))}
      <Col xxl={12} xl={12} lg={12} md={24} sm={24} xs={24}>
        <GameCard showMore={showMoreSteam} more={true} />
      </Col>
    </Row>
  );

  return (
    <Space direction='vertical' className='gap-[16px]'>
      {isOwner ? <SteamLibrary profileDetails={profileDetails} userSteamId={userSteamId} /> : ''}
      {isLoading ? (
        <SpinWrapper />
      ) : !userSteamId || userSteamId?.length === 0 ? (
        renderNoSteamIntegrated()
      ) : userSteamLibrary?.length === 0 ? (
        renderSteamIntegrationWarning()
      ) : (
        renderGameCards()
      )}
    </Space>
  );
};

export default SteamIntegrationTab;
