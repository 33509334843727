import { Button, Flex, Form, Input, Modal, Typography } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { CheckOutlined, DeleteOutlined, CloseOutlined, RobotOutlined } from '@ant-design/icons';
import { CountrySelector, SelectComponent } from '../filters/Inputs';
import CropperComponent from '../../../helpers/utils/CropperComponent.tsx';
import React, { useContext, useState, useEffect } from 'react';
import { UtilityContext } from '../../../providers/UtilityProvider';
import {
  useDeleteOrganization,
  useUpdateOrganization,
  useGetOrganizationDetailByCodeHandler
} from '../../../hooks/queries/OrganizationQueries';
import { uploadFile } from '../../../requests/UtilityManager';
import { AuthContext } from '../../../providers/AuthProvider';
import { specialCharacters } from '../../../helpers/constants/constantHelpers';

const { Paragraph, Text } = Typography;

const OrganizationAboutForm = ({ organization, formCallBackHandler, isFounder }) => {
  const { user } = useContext(AuthContext);
  const { organizationCategories, openNotification, theme } = useContext(UtilityContext);
  const { mutate: updateOrganization } = useUpdateOrganization(organization?.code);
  const { mutateAsync: deleteOrganization } = useDeleteOrganization(user?.id);
  const [codeSlug, setCodeSlug] = useState(organization?.code);
  const { data: organizationByCode } = useGetOrganizationDetailByCodeHandler(codeSlug);

  const [organizationAbout] = Form.useForm();
  const [logoFile, setLogoFile] = useState();
  const [bannerFile, setBannerFile] = useState();
  const [loading, setLoading] = useState(false);
  const [deleteModalStatus, setDeleteModalStatus] = useState(false);

  const translator = useIntl();

  const teamNameSlugCreator = (inputValue) => {
    let charMap = {
      Ç: 'C',
      Ö: 'O',
      Ş: 'S',
      İ: 'I',
      I: 'i',
      Ü: 'U',
      Ğ: 'G',
      ç: 'c',
      ö: 'o',
      ş: 's',
      ı: 'i',
      ü: 'u',
      ğ: 'g',
    };

    let str_array = inputValue.split('');

    for (let i = 0, len = str_array.length; i < len; i++) {
      str_array[i] = charMap[str_array[i]] || str_array[i];
    }

    let newValue = str_array.join('').replace(/[çöşüğı]/gi, '');
    let slug = newValue.toLocaleLowerCase().replaceAll(' ', '-');

    if (slug[slug.length - 1] !== '-') {
      setCodeSlug(slug);
    }
  };

  useEffect(() => {
    if (organization?.code === codeSlug) {
      setCodeSlug(codeSlug);
    } else if (codeSlug !== '' && organizationByCode?.code === codeSlug) {
      let randomNum = Math.floor(Math.random() * 10);
      setCodeSlug(`${codeSlug}_${randomNum}`);
    }
    organizationAbout.setFieldValue('code', codeSlug);
  }, [codeSlug, organizationByCode]);

  const createTeamHandler = () => {
    let formValues = organizationAbout.getFieldsValue();
    if (specialCharacters.test(formValues.name)) {
      openNotification('organizationNameNotAccepted', 'error');
    } else {
      organizationAbout.submit();
    }
  };

  const deleteOrganizationHandler = () => {
    setLoading(true);
    let payload = { id: organization?.id };
    deleteOrganization(payload)
      .then((response) => {
        if (response.status === 202) {
          openNotification('organizationDeleted', 'success');
        }
      })
      .finally(() => {
        setLoading(false);
        setDeleteModalStatus(false);
      });
  };

  const organizationEditHandler = async (formValues) => {
    setLoading(true);
    if (logoFile?.base64) {
      let fileContentType = logoFile.contentType.includes('image')
        ? 'image'
        : logoFile.contentType.includes('video')
        ? 'video'
        : null;

      if (fileContentType === 'image') {
        await uploadFile(logoFile).then((response) => {
          if (response.status === 202) {
            formValues['logo_url'] = response.data.data;
          }
        });
      }
    }

    if (bannerFile?.base64) {
      let fileContentType = bannerFile.contentType.includes('image')
        ? 'image'
        : bannerFile.contentType.includes('video')
        ? 'video'
        : null;

      if (fileContentType === 'image') {
        await uploadFile(bannerFile).then((response) => {
          if (response.status === 202) {
            formValues['banner_url'] = response.data.data;
          }
        });
      }
    }

    formValues['id'] = organization?.id;
    formValues['userId'] = user?.id;
    updateOrganization(formValues);
    setLoading(false);
    formCallBackHandler();
  };

  const fileUploadHandler = (type, value) => {
    if (type === 'logo') {
      setLogoFile(value);
    }
    if (type === 'banner') {
      setBannerFile(value);
    }
  };

  return (
    <Form
      layout='vertical'
      initialValues={{
        ...organization,
        category_id: organization.categoryId,
      }}
      onFinish={organizationEditHandler}
      form={organizationAbout}
    >
      <SelectComponent
        labelId='category'
        translationId='category'
        serviceUrl='getCategories'
        options={organizationCategories}
        formName='category_id'
        required={true}
        fieldNames={{
          label: 'name',
          value: 'id',
        }}
      />
      <CountrySelector formName='country' withLabel={true} />
      <Form.Item label={translator.formatMessage({ id: 'websiteUrl' })} name='website'>
        <Input placeholder={translator.formatMessage({ id: 'websiteUrlPlaceholder' })} />
      </Form.Item>
      <Form.Item
        rules={[
          { required: true, message: '' },
          { max: 30, message: translator.formatMessage({ id: 'formLengthMessageShort' }) },
        ]}
        label={translator.formatMessage({ id: 'name' })}
        name='name'
      >
        <Input
          onChange={(element) => teamNameSlugCreator(element.target.value)}
          placeholder={translator.formatMessage({ id: 'enterOrganizationName' })}
        />
      </Form.Item>
      <Form.Item name='code' label={translator.formatMessage({ id: 'yourUrl' })}>
        <Input
          style={{
            width: '100%',
          }}
          disabled={true}
          placeholder={translator.formatMessage({ id: 'organizationUrl' })}
        />
      </Form.Item>
      <Form.Item
        rules={[{ max: 250, message: translator.formatMessage({ id: 'formLengthMessageLong' }) }]}
        label={translator.formatMessage({ id: 'description' })}
        name='description'
      >
        <Input placeholder={translator.formatMessage({ id: 'enterDescription' })} />
      </Form.Item>
      <Flex justify='space-between'>
        <CropperComponent
          isLoading={loading}
          label='addTeamLogo'
          returnedData={(value) => fileUploadHandler('logo', value)}
          formName='logo_url'
          required={true}
          forOrganization={true}
        />
        <CropperComponent
          isLoading={loading}
          label='bannerPhoto'
          returnedData={(value) => fileUploadHandler('banner', value)}
          formName='banner_url'
          required={true}
          forOrganization={true}
        />
      </Flex>
      <Flex justify='space-between' align='center'>
        <Button
          loading={loading}
          icon={<CheckOutlined style={{ color: '#fff', marginRight: '.5rem' }} />}
          type='primary'
          onClick={createTeamHandler}
          htmlType='submit'
        >
          <Text className='text-inherit'>
            <FormattedMessage id='submit' />
          </Text>
        </Button>
        {isFounder && (
          <Button
            onClick={() => setDeleteModalStatus(true)}
            loading={loading}
            icon={<DeleteOutlined style={{ color: '#fff', marginRight: '.5rem' }} />}
            type='primary'
            danger
          >
            <Text className='text-inherit'>
              <FormattedMessage id='deleteOrganization' />
            </Text>
          </Button>
        )}
      </Flex>
      <Modal
        closeIcon={<CloseOutlined />}
        destroyOnClose={true}
        title={translator.formatMessage({ id: 'attention' })}
        open={deleteModalStatus}
        onCancel={() => setDeleteModalStatus(false)}
        onOk={deleteOrganizationHandler}
        okText={translator.formatMessage({ id: 'approve' })}
        okButtonProps={{
          loading: loading,
        }}
        cancelButtonProps={{
          danger: true,
        }}
      >
        <Paragraph>
          <FormattedMessage id='deleteOrganizationWarning' />
        </Paragraph>
      </Modal>
    </Form>
  );
};

export default OrganizationAboutForm;
