import { Button, Col, Flex, Form, Input, Modal, notification, Row, Typography } from 'antd';
import styles from '../../../style/login.module.scss';
import { FormattedMessage, useIntl } from 'react-intl';
import { useContext, useRef, useState } from 'react';
import {
  emailRegex,
  passwordRegex,
  usernameRegex,
} from '../../../helpers/constants/constantHelpers';
import { userRegister } from '../../../requests/UserInitiatorManager';
import { UtilityContext } from '../../../providers/UtilityProvider';
import { LoginOutlined } from '@ant-design/icons';
import { useClientError } from '../../../hooks/useClientError';
import { Link } from 'react-router-dom';
import { checkUserName } from '../../../requests/UserManager';
import { AuthContext } from '../../../providers/AuthProvider';
import useValidation from '../../../hooks/useValidation';

const { Text } = Typography;

const RegisterForm = ({ openLogin, setIsRegisterMessage }) => {
  const { validateUsername } = useValidation();
  const [loading, setLoading] = useState(false);

  const [registerForm] = Form.useForm();
  const intl = useIntl();
  const lang = localStorage.getItem('language');

  const onClientError = useClientError();

  const registerHandler = (registerCredentials) => {
    setLoading(true);
    userRegister({ ...registerCredentials, language: lang })
      .then((response) => {
        if (response.status === 202) {
          notification.success({
            description: intl.formatMessage({ id: 'activationMessage' }),
            className: 'errorNotification',
          });
          setIsRegisterMessage(true);
        } else {
          notification.error({
            message: intl.formatMessage({ id: 'whops' }),
            description: intl.formatMessage({ id: 'unknownError' }),
            className: 'errorNotification',
          });
        }
      })
      .catch((err) => {
        if (err.response && err.response.status === 400) {
          notification.error({
            description: intl.formatMessage({ id: 'existingEmail' }),
            className: 'errorNotification',
          });
        } else {
          onClientError(err);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <Form layout='vertical' form={registerForm} onFinish={registerHandler}>
      <Form.Item
        label={intl.formatMessage({ id: 'email' })}
        rules={[
          { required: true, message: intl.formatMessage({ id: 'fieldRequired' }) },
          { pattern: emailRegex, message: intl.formatMessage({ id: 'mailNotValid' }) },
        ]}
        name='game_actor_email'
      >
        <Input placeholder={intl.formatMessage({ id: 'enterEmail' })} size='large' />
      </Form.Item>
      <Form.Item
        label={intl.formatMessage({ id: 'username' })}
        rules={[
          { required: true, message: intl.formatMessage({ id: 'fieldRequired' }) },
          { max: 30, message: intl.formatMessage({ id: 'formLengthMessageShort' }) },
          { pattern: usernameRegex, message: intl.formatMessage({ id: 'usernameWarnMessage' }) },
          { validator: validateUsername },
        ]}
        validateTrigger='onBlur'
        name='game_actor_username'
      >
        <Input size='large' placeholder={intl.formatMessage({ id: 'enterUsername' })} />
      </Form.Item>
      <Form.Item
        label={intl.formatMessage({ id: 'password' })}
        rules={[
          { required: true, message: intl.formatMessage({ id: 'pleaseEnterPassword' }) },
          { pattern: passwordRegex, message: intl.formatMessage({ id: 'passwordNotValid' }) },
        ]}
        name='game_actor_password'
      >
        <Input
          placeholder={intl.formatMessage({ id: 'enterPassword' })}
          type='password'
          size='large'
        />
      </Form.Item>
      <Form.Item
        label={intl.formatMessage({ id: 'passwordCheck' })}
        rules={[
          { required: true, message: intl.formatMessage({ id: 'pleaseEnterPassword' }) },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('game_actor_password') === value) {
                return Promise.resolve();
              }
              return Promise.reject(intl.formatMessage({ id: 'noMatchedPassword' }));
            },
          }),
        ]}
        name='passwordCheck'
      >
        <Input
          placeholder={intl.formatMessage({ id: 'enterPasswordCheck' })}
          type='password'
          size='large'
        />
      </Form.Item>
      <Form.Item
        label={intl.formatMessage({ id: 'firstName' })}
        rules={[
          { required: true, message: intl.formatMessage({ id: 'fieldRequired' }) },
          { max: 30, message: intl.formatMessage({ id: 'formLengthMessageShort' }) },
        ]}
        name='firstName'
      >
        <Input size='large' placeholder={intl.formatMessage({ id: 'enterFirstName' })} />
      </Form.Item>
      <Form.Item
        label={intl.formatMessage({ id: 'lastName' })}
        rules={[
          { required: true, message: intl.formatMessage({ id: 'fieldRequired' }) },
          { max: 30, message: intl.formatMessage({ id: 'formLengthMessageShort' }) },
        ]}
        name='lastName'
      >
        <Input size='large' placeholder={intl.formatMessage({ id: 'enterLastName' })} />
      </Form.Item>
      {lang === 'tr' && (
        <Text>
          {'Kaydolarak; '}
          <Link to='/terms-of-service' target='_blank'>
            <Text>
              <FormattedMessage id='termsOfServices' />
            </Text>
          </Link>
          {' , '}
          <Link to='/privacy-policy' target='_blank'>
            <Text>
              <FormattedMessage id='privacyPoliciy' />
            </Text>
          </Link>
          {' ve Çerez kullanımını kabul etmiş olursun.'}
        </Text>
      )}
      {lang === 'en' && (
        <Text>
          {' By signing up, you agree to our '}
          <Link to='/terms-of-service' target='_blank'>
            <Text>
              <FormattedMessage id='termsOfServices' />
            </Text>
          </Link>
          {' , '}
          <Link to='/privacy-policy' target='_blank'>
            <Text>
              <FormattedMessage id='privacyPoliciy' />
            </Text>
          </Link>
          {' and cookie usage'}
        </Text>
      )}
      <Button
        size='large'
        className='!rounded-[8px]'
        loading={loading}
        type='primary'
        htmlType='submit'
        block
      >
        <Text className='text-inherit'>
          <FormattedMessage id='register' />
        </Text>
      </Button>
      <Button
        onClick={openLogin}
        size='large'
        className='!rounded-[8px] mt-4'
        icon={<LoginOutlined className='me-2' />}
      >
        <Text className='text-inherit'>
          <FormattedMessage id='login' />
        </Text>
      </Button>
    </Form>
  );
};

export default RegisterForm;
