import { useCallback, useContext } from 'react';
import { UtilityContext } from '../providers/UtilityProvider';
import { usernameRegex } from '../helpers/constants/constantHelpers';
import { AuthContext } from '../providers/AuthProvider';
import { checkUserName } from '../requests/UserManager';
import { useClientError } from './useClientError';

const useValidation = () => {
  const { openNotification } = useContext(UtilityContext);
  const { user } = useContext(AuthContext);
  const onClientError = useClientError();

  const validateUsername = useCallback(
    async (_, value) => {
      if (value && value.trim().length > 0) {
        if (usernameRegex.test(value)) {
          try {
            const response = await checkUserName(value);
            if (!response && value.toLowerCase() !== user.username.toLowerCase()) {
              openNotification('usernameAlreadyTaken', 'warning');
              return Promise.reject();
            }
          } catch (err) {
            onClientError(err);
            return Promise.reject();
          }
        }
      }
    },
    [checkUserName, user],
  );

  return { validateUsername };
};

export default useValidation;
