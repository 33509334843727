import { Typography, Flex, Image, Checkbox, Form, Button } from 'antd';
import React, { Fragment, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import defaultProfilePhoto from '../../../assets/images/defaultProfilePhoto.jpeg';

const InviteConnections = ({ connectionDetails, allCheckedList, setChecked, checked }) => {
  const { Text } = Typography;

  const onChange = (e, id) => {
    if (e.target.checked) {
      setChecked((prev) => [...prev, id]);
    } else {
      const newChecked = [...checked];
      const filterednewChecked = newChecked.filter((check) => check !== id);
      setChecked(filterednewChecked);
    }
  };

  return (
    <Fragment>
      {connectionDetails.map((user, index) => (
        <Flex justify='space-between' align='start' key={index}>
          <Link to={`/profile/${user.username}`}>
            <Flex gap={4}>
              <Image
                className='rounded-[8px] object-cover'
                width={55}
                height={50}
                preview={false}
                src={user.photoUrl ? user.photoUrl : defaultProfilePhoto}
              />
              <div className='flex flex-col'>
                <Text className='dark:text-dark-mode-text-color'>{user.fullname}</Text>
                <span className='text-[10px]'>{user.username}</span>
              </div>
            </Flex>
          </Link>

          <Checkbox
            checked={checked.includes(user.id) ? true : false}
            value={user.id}
            onChange={(e) => {
              onChange(e, user.id);
            }}
          />
        </Flex>
      ))}
    </Fragment>
  );
};

export default InviteConnections;
