import React from 'react';
import FollowNotification from './FollowNotification';
import AcceptNotification from './AcceptNotification';
import CommentNotification from './CommentNotification';
import LikeNotification from './LikeNotification';
import MentionNotification from './MentionNotification';
import OrganizationRoleUpdateNotification from './OrganizationRoleUpdateNotification';
import OrganizationNewRoleNotification from './OrganizationNewRoleNotification';
import InviteOrganizationNotification from './InviteOrganizationNotification';
import RequestNotification from './RequestNotification';

function NotificationItem({ item }) {
  if (item.type === 'followed') {
    return <FollowNotification notification={item} />;
  } else if (item.type === 'accepted') {
    return <AcceptNotification notification={item} />;
  } else if (item.type === 'commented') {
    return <CommentNotification notification={item} />;
  } else if (item.type === 'liked') {
    return <LikeNotification notification={item} />;
  } else if (item.type === 'mentioned') {
    return <MentionNotification notification={item} />;
  } else if (item.type === 'organizationRoleUpdated') {
    return <OrganizationRoleUpdateNotification notification={item} />;
  } else if (item.type === 'organizationRoleCreated') {
    return <OrganizationNewRoleNotification notification={item} />;
  } else if (item.type === 'inviteOrganization') {
    return <InviteOrganizationNotification notification={item} />;
  }
}

export default NotificationItem;
