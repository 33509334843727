import { styles } from '../../styles';
import { Flex, Image, Typography } from 'antd';
import LoginForm from '../form/user/LoginForm';
import React, { useContext, useState } from 'react';

const { Title, Text } = Typography;
import socialize from '../../assets/images/socialize.png';
import search from '../../assets/images/search.png';
import share from '../../assets/images/share.png';
import trophy from '../../assets/images/trophy.png';
import FlipCard from '../cards/FlipCard';
import { motion } from 'framer-motion';
import { fadeIn, textVariant } from '../../helpers/utils/motion';
import gameActorLogo from '../../assets/images/game-actor-logo.png';
import gameActorLogoWhite from '../../assets/images/game-actor-white.svg';
import RegisterForm from '../form/user/RegisterForm';
import cupLogo from '../../assets/images/cup-logo.png';
import academyLogo from '../../assets/images/academy-logo.png';
import teamLogo from '../../assets/images/team-logo.png';
import integrationsLogo from '../../assets/images/integration.png';
import layoutStyles from '../../style/layout.module.scss';
import {
  faBattleNet,
  faDiscord,
  faKickstarterK,
  faSteam,
  faTwitch,
} from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import riotGames from '../../assets/svg/riot-games.svg';
import { FormattedMessage } from 'react-intl';
import ThemeSettings from '../settings/ThemeSettings';
import { UtilityContext } from '../../providers/UtilityProvider';
import EpicGamesSVG from '../../assets/svg/EpicGamesSVG';

const ServiceCard = ({ index, title, icon, backTitle, backContent }) => (
  <FlipCard
    front={
      <motion.div
        variants={fadeIn('right', 'spring', index * 0.5, 0.75)}
        className='w-full p-[1px] rounded-[8px] border border-solid border-border-color dark:border-dark-mode-light-border-color'
      >
        <div className='rounded-[20px] py-5 px-12 min-h-[280px] flex justify-evenly items-center flex-col'>
          <img src={icon} alt='web-development' className='w-16 h-16 object-contain' />

          <h3 className='text-[20px] font-bold text-center'>{title}</h3>
        </div>
      </motion.div>
    }
    back={
      <motion.div
        variants={fadeIn('right', 'spring', index * 0.5, 0.75)}
        className='w-full p-[1px] rounded-[8px] border border-solid border-border-color dark:border-dark-mode-light-border-color'
      >
        <div className='rounded-[20px] py-5 px-12 min-h-[280px] flex justify-evenly items-center flex-col'>
          <h3 className='text-[20px] font-bold text-center'>{backTitle}</h3>
          <p>{backContent}</p>
        </div>
      </motion.div>
    }
  />
);

const Hero = ({ userKey }) => {
  const { theme } = useContext(UtilityContext);
  const [formType, setFormType] = useState(1);
  const services = [
    {
      title: (
        <Text>
          <FormattedMessage id='socialize' />
        </Text>
      ),
      icon: socialize,
      backTitle: (
        <Text>
          <FormattedMessage id='engage' />
        </Text>
      ),
      backContent: (
        <Text>
          <FormattedMessage id='engageCard' />
        </Text>
      ),
    },
    {
      title: (
        <Text>
          <FormattedMessage id='searchAndFind' />
        </Text>
      ),
      icon: search,
      backTitle: (
        <Text>
          <FormattedMessage id='findThem' />
        </Text>
      ),
      backContent: (
        <Text>
          <FormattedMessage id='findThemCard' />
        </Text>
      ),
    },
    {
      title: (
        <Text>
          <FormattedMessage id='shareAchievements' />
        </Text>
      ),
      icon: share,
      backTitle: (
        <Text>
          <FormattedMessage id='epicMemories' />
        </Text>
      ),
      backContent: (
        <Text>
          <FormattedMessage id='memoriesCard' />
        </Text>
      ),
    },
    {
      title: (
        <Text>
          <FormattedMessage id='becomeActor' />
        </Text>
      ),
      icon: trophy,
      backTitle: (
        <Text>
          <FormattedMessage id='takeAction' />
        </Text>
      ),
      backContent: (
        <Text>
          <FormattedMessage id='takeActionCard' />
        </Text>
      ),
    },
  ];
  const [isRegisterMessage, setIsRegisterMessage] = useState(false);
  return (
    <section className='relative w-full mx-auto max-w-screen-xl'>
      <div className='flex items-center justify-center md:justify-between'>
        <img
          src={theme ? gameActorLogoWhite : gameActorLogo}
          alt='logo'
          style={{ maxWidth: '240px' }}
          className='object-contain'
        />
        <div className='hidden md:flex gap-5'>
          <a href='#academy'>
            <Flex
              className='cursor-pointer opacity-80 hover:opacity-100'
              align='center'
              justify='center'
              vertical={true}
            >
              <Image width={50} src={academyLogo} preview={false} />
              <Text className='landing-page-link'>
                <FormattedMessage id='academy' />
              </Text>
            </Flex>
          </a>
          <a href='#tournaments'>
            <Flex
              className='cursor-pointer opacity-80 hover:opacity-100'
              align='center'
              justify='center'
              vertical={true}
            >
              <Image width={50} src={cupLogo} preview={false} />
              <Text className='landing-page-link'>
                <FormattedMessage id='tournaments' />
              </Text>
            </Flex>
          </a>
          <a href='#teams'>
            <Flex
              className='cursor-pointer opacity-80 hover:opacity-100'
              align='center'
              justify='center'
              vertical={true}
            >
              <Image width={50} src={teamLogo} preview={false} />
              <Text className='landing-page-link'>
                <FormattedMessage id='teams' />
              </Text>
            </Flex>
          </a>
        </div>
      </div>

      <div className='mt-[50px] inset-0 top-[120px] gap-[32px] mx-auto flex flex-row flex-wrap items-start justify-between'>
        <div className='w-full sm:max-w-full md:max-w-full lg:max-w-[410px]'>
          <Title className={`${styles.heroHeadText}`} level={1}>
            <FormattedMessage id='welcomeMessageOne' />
            <span className={`${styles.heroHeadSpan} text-focused-primary-color`}>
              <FormattedMessage id='welcomeMessageTwo' />
            </span>
          </Title>
          {!formType ? (
            !isRegisterMessage ? (
              <RegisterForm
                setIsRegisterMessage={setIsRegisterMessage}
                openLogin={() => setFormType(1)}
              />
            ) : (
              <Text>
                <FormattedMessage id='activationMessage' />
              </Text>
            )
          ) : (
            <LoginForm userKey={userKey} openRegister={() => setFormType(0)} />
          )}
        </div>

        <div>
          <motion.div className='flex' variants={textVariant()}>
            <p className={styles.sectionSubText}>
              <FormattedMessage id='whatIs' />
            </p>
          </motion.div>

          <motion.p
            variants={fadeIn('', '', 2, 5)}
            className='mt-4 text-[18px] max-w-3xl leading-[30px]'
          >
            <FormattedMessage id='whatIsDescription' />
          </motion.p>
          <div className='mt-4'>
            <motion.div className='flex' variants={textVariant()}>
              <p className={styles.sectionSubText}>
                <FormattedMessage id='theme' />
              </p>
            </motion.div>

            <ThemeSettings hasText={true} />
          </div>
        </div>
      </div>
      <div>
        <div className='mt-10 mb-10 flex flex-wrap justify-center md:justify-between lg:justify-between gap-10'>
          {services.map((service, index) => (
            <ServiceCard key={service.title} index={index} {...service} />
          ))}
        </div>

        <div className='p-4 rounded-[8px] border-2 lg:border-none border-solid border-hard-gray max-w-[740px]'>
          <motion.div id='academy' className='flex gap-2 items-center' variants={textVariant()}>
            <Image width={50} src={academyLogo} preview={false} />
            <p className={styles.sectionSubText}>
              <FormattedMessage id='academy' />
            </p>
          </motion.div>

          <motion.p
            variants={fadeIn('', '', 2, 5)}
            className='mt-4 text-[18px] max-w-3xl leading-[30px]'
          >
            <FormattedMessage id='academyDescription' />
          </motion.p>
        </div>

        <div className='p-4 rounded-[8px] border-2 lg:border-none border-solid border-hard-gray mt-10 max-w-[740px]'>
          <motion.div id='tournaments' className='flex gap-2 items-center' variants={textVariant()}>
            <Image width={50} src={cupLogo} preview={false} />
            <p className={styles.sectionSubText}>
              <FormattedMessage id='tournaments' />
            </p>
          </motion.div>

          <motion.p
            variants={fadeIn('', '', 2, 5)}
            className='mt-4 text-[18px] max-w-3xl leading-[30px]'
          >
            <FormattedMessage id='tournamentsDescriptionOne' />
            <br />
            <FormattedMessage id='tournamentsDescriptionTwo' />
          </motion.p>
        </div>

        <div className='p-4 rounded-[8px] border-2 lg:border-none border-solid border-hard-gray mt-10 max-w-[740px]'>
          <motion.div id='teams' className='flex gap-2 items-center' variants={textVariant()}>
            <Image width={50} src={teamLogo} preview={false} />
            <p className={styles.sectionSubText}>
              <FormattedMessage id='teams' />
            </p>
          </motion.div>

          <motion.p
            variants={fadeIn('', '', 2, 5)}
            className='mt-4 text-[18px] max-w-3xl leading-[30px]'
          >
            <FormattedMessage id='teamsDescriptionOne' />
            <br />
            <FormattedMessage id='teamsDescriptionTwo' />
          </motion.p>
        </div>

        <div className='p-4 rounded-[8px] border-2 lg:border-none border-solid border-hard-gray mt-10 mb-20 max-w-[740px]'>
          <motion.div id='teams' className='flex gap-2 items-center' variants={textVariant()}>
            <Image width={50} src={integrationsLogo} preview={false} />
            <p className={styles.sectionSubText}>
              <FormattedMessage id='integrations' />
            </p>
          </motion.div>

          <motion.p
            variants={fadeIn('', '', 2, 5)}
            className='mt-4 text-[18px] max-w-3xl leading-[30px]'
          >
            <FormattedMessage id='integrationsDescription' />
            <br />
            <div className='mt-8 flex items-center justify-between'>
              <FontAwesomeIcon
                icon={faSteam}
                height={150}
                style={{
                  color: theme ? 'white' : '#192b3c',
                  fontSize: '28px',
                }}
              />
              <FontAwesomeIcon
                icon={faBattleNet}
                style={{
                  color: '#158eff',
                  fontSize: '28px',
                }}
              />
              <FontAwesomeIcon
                icon={faDiscord}
                style={{
                  color: '#5765f2',
                  fontSize: '28px',
                }}
              />
              <FontAwesomeIcon
                className={layoutStyles['fontAwesomeIcon']}
                icon={faKickstarterK}
                style={{
                  color: '#47d316',
                  fontSize: '28px',
                }}
              />
              <FontAwesomeIcon
                icon={faTwitch}
                style={{
                  color: '#a970ff',
                  fontSize: '28px',
                }}
              />
              <EpicGamesSVG width={28} height={28} dark={theme} />
              <Image
                preview={false}
                width={28}
                height={28}
                src={riotGames}
                alt='riot-games-launcher'
              />
            </div>
          </motion.p>
        </div>
      </div>
    </section>
  );
};

export default Hero;
