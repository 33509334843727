import React, { useContext, useState } from 'react';
import { ConfigProvider, Layout, theme } from 'antd';
import { Header } from 'antd/es/layout/layout';
import ScrollToTop from '../components/hoc/ScrollToTop';
import InnerLayout from './InnerLayout';
import trTR from 'antd/locale/tr_TR';
import enEN from 'antd/locale/en_US';
import variables from '../style/_variables.module.scss';
import { UtilityContext } from '../providers/UtilityProvider';
import MainHeader from '../components/header/MainHeader';
import dayjs from 'dayjs';
import useIsPath from '../hooks/useIsPath';
import { useParams } from 'react-router-dom';

const LayoutContent = ({ lang }) => {
  const { theme: tailwindTheme, isUserKey } = useContext(UtilityContext);

  const { Content } = Layout;
  const params = useParams();

  const {
    primaryColor,
    lightContainerColor,
    linkHoverColor,
    focusedPrimaryColor,
    bodyTextColor,
    backgroundColor,
    primaryShadow,
    tableRowHoverBg,
    tableBorderColor,
    themeLight,
    colorTextPlaceHolder,
    white,
    lightModeBorderColor,

    darkModePrimaryColor,
    darkModeLightContainerColor,
    darkModeBorderColor,
    darkModeBgPopUp,
    darkModeTextColor,
    darkModeCream,
    colorTextDisabled,
    calendarTextColor,
    darkModeSecondaryColor,
    deleteColor,
    darkModeNotificationsBorderColor,
    darkModeFocusedPrimaryColor,
    darkModeLinkHoverColor,
    darkModeBackgroundColor,
    darkModePrimaryDark,
  } = variables;

  let isHeaderVisible = !useIsPath('/login');

  if (
    window.location.pathname === '/' ||
    window.location.pathname === '/privacy-policy' ||
    window.location.pathname === '/terms-of-service' ||
    window.location.pathname === `/activate/${isUserKey}`
  ) {
    isHeaderVisible = false;
  }

  if (window.location.pathname.includes('/reset-password')) {
    isHeaderVisible = false;
  }

  return (
    <ConfigProvider
      locale={lang === 'tr' ? trTR : enEN}
      theme={{
        algorithm: [tailwindTheme ? theme.darkAlgorithm : theme.defaultAlgorithm],
        token: {
          fontSize: 14,
          colorPrimary: tailwindTheme ? '#7D4CFF' : '#403490',
          colorText: tailwindTheme ? '#FFF' : '#000000',
          colorTextSecondary: tailwindTheme ? '#AAAAAA' : '#555555',
          colorBgBase: tailwindTheme ? '#181818' : '#FFFFFF',
          colorBgContainer: tailwindTheme ? '#202020' : '#FFF',
          colorBorder: tailwindTheme ? '#383838' : '#E0E0E0',
          colorLink: tailwindTheme ? '#7D4CFF' : '#403490',
          colorLinkHover: tailwindTheme ? '#be9eff' : '#007bff',
          colorLinkActive: tailwindTheme ? '#5c36d9' : '#004080',
          borderRadius: 8,
          paddingLG: 16,
          padding: 16,
        },
        components: {
          Layout: {
            headerBg: tailwindTheme ? '#202020' : '#FFF',
            bodyBg: tailwindTheme ? '#181818' : '#f0f2f5',
            colorText: tailwindTheme ? '#FFFFFF' : '#212529',
            colorTextBody: tailwindTheme ? '#AAAAAA' : '#343A40',
          },
          Dropdown: {
            colorBgContainer: tailwindTheme ? '#202020' : '#FFFFFF',
            colorText: tailwindTheme ? '#FFFFFF' : '#212529',
            colorTextHover: tailwindTheme ? '#AAAAAA' : '#495057',
          },
          Menu: {
            colorItemBg: tailwindTheme ? '#202020' : '#FFFFFF',
            colorItemBgSelected: tailwindTheme ? '#383838' : '#E9ECEF',
            colorItemBgHover: tailwindTheme ? '#383838' : '#F1F3F5',
            colorText: tailwindTheme ? '#FFFFFF' : '#212529',
            colorTextHover: tailwindTheme ? '#AAAAAA' : '#495057',
          },
          Modal: {
            colorBgElevated: tailwindTheme ? '#202020' : '#FFFFFF',
            colorText: tailwindTheme ? '#FFFFFF' : '#212529',
          },
          Drawer: {
            colorBgElevated: tailwindTheme ? '#202020' : '#FFFFFF',
            colorText: tailwindTheme ? '#FFFFFF' : '#212529',
          },
          Input: {
            colorBgContainer: tailwindTheme ? '#303030' : '#FFFFFF',
            colorText: tailwindTheme ? '#FFFFFF' : '#212529',
            colorPlaceholder: tailwindTheme ? '#888888' : '#6C757D',
            colorBorder: tailwindTheme ? '#555555' : '#CED4DA',
          },
          Card: {
            colorBgContainer: tailwindTheme ? '#202020' : '#FFFFFF',
            colorText: tailwindTheme ? '#FFFFFF' : '#212529',
            boxShadow: tailwindTheme
              ? '0 4px 12px rgba(0, 0, 0, 0.1)'
              : '0 4px 12px rgba(0, 0, 0, 0.05)',
          },
        },
      }}
    >
      <Layout>
        {isHeaderVisible && (
          <Header style={{ boxShadow: tailwindTheme && '0 0 6px 0 black' }}>
            <MainHeader />
          </Header>
        )}
        <Content>
          <ScrollToTop />
          <InnerLayout />
        </Content>
      </Layout>
    </ConfigProvider>
  );
};

export default LayoutContent;
