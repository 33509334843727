import { Button, Form, Input, notification, Flex, Modal, Select, Typography } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../../../providers/AuthProvider';
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  GoogleOutlined,
  KeyOutlined,
  LoginOutlined,
  MailOutlined,
  SendOutlined,
  UserOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { userLogin, createLoginRequest } from '../../../requests/UserInitiatorManager';
import { UtilityContext } from '../../../providers/UtilityProvider';
import { useChangeRequest } from '../../../hooks/queries/UtilityQueries';

const { Text } = Typography;

const LoginForm = ({ openRegister, userKey }) => {
  const { mutateAsync } = useChangeRequest();
  const [passVisible, setPassVisible] = useState(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [mailLoading, setMailLoading] = useState(false);
  const [resetFormVisibility, setResetFormVisibility] = useState(false);
  const { theme } = useContext(UtilityContext);
  const { login } = useContext(AuthContext);
  const { openNotification } = useContext(UtilityContext);

  const [loginForm] = Form.useForm();
  const [resetForm] = Form.useForm();

  const translator = useIntl();

  useEffect(() => {
    document.getElementById('password').setAttribute('autocomplete', 'new-password');
  }, []);

  const resetFormHandler = (formValues) => {
    setMailLoading(true);
    mutateAsync(formValues)
      .then((response) => {
        if (response.status === 202) {
          openNotification('requestSentForReset', 'success');
        } else {
          openNotification('unknownError', 'error');
        }
      })
      .catch((err) => {
        if (err.response.data.message === 'no user with email"') {
          openNotification('unknownError', 'noEmailRegistered');
        }
      })
      .finally(() => {
        resetForm.resetFields();
        setResetFormVisibility(false);
        setMailLoading(false);
      });
  };

  const loginHandler = (loginCredentials) => {
    setLoading(true);
    userLogin(loginCredentials)
      .then((response) => {
        if (response.status === 202) {
          login(response.data,userKey);
        } else {
          notification.error({
            message: translator.formatMessage({ id: 'whops' }),
            description: translator.formatMessage({ id: 'unknownError' }),
            className: 'errorNotification',
          });
        }
        setLoading(false);
      })
      .catch(() => {
        notification.error({
          message: translator.formatMessage({ id: 'whops' }),
          description: translator.formatMessage({ id: 'checkCredentials' }),
          className: 'errorNotification',
        });
        setLoading(false);
      });
  };

  return (
    <Form className='mx-auto' layout='vertical' form={loginForm} onFinish={loginHandler}>
      <Form.Item
        label={translator.formatMessage({ id: 'email' })}
        value={username}
        name='game_actor_email'
      >
        <Input
          placeholder={translator.formatMessage({ id: 'enterEmail' })}
          onChange={(e) => setUsername(e.target.value)}
          size='large'
          prefix={<MailOutlined className='mr-2' />}
        />
      </Form.Item>
      <Form.Item
        label={translator.formatMessage({ id: 'password' })}
        value={password}
        name='game_actor_password'
      >
        <Input
          id='password'
          type={passVisible ? 'text' : 'password'}
          size='large'
          prefix={
            passVisible ? (
              <EyeInvisibleOutlined onClick={() => setPassVisible(!passVisible)} className='mr-2' />
            ) : (
              <EyeOutlined onClick={() => setPassVisible(!passVisible)} className='mr-2' />
            )
          }
          placeholder={translator.formatMessage({ id: 'enterPassword' })}
          onChange={(e) => setPassword(e.target.value)}
        />
      </Form.Item>
      <Button
        loading={loading}
        icon={<LoginOutlined className='me-2' />}
        type='primary'
        htmlType='submit'
        size='large'
        className='!rounded-[8px]'
        block
      >
        <Text className='text-inherit'>
          <FormattedMessage id='login' />
        </Text>
      </Button>
      <Flex className='mt-4' justify='space-between'>
        <Button
          onClick={openRegister}
          icon={<UserOutlined className='me-2' />}
          size='large'
          className='!rounded-[8px]'
        >
          <Text className='text-inherit'>
            <FormattedMessage id='register' />
          </Text>
        </Button>
        <Button
          onClick={() => setResetFormVisibility(true)}
          icon={<UserOutlined className='me-2' />}
          size='large'
          className='!rounded-[8px]'
        >
          <Text className='text-inherit'>
            <FormattedMessage id='forgotPassword' />
          </Text>
        </Button>
      </Flex>
      <Modal
        closeIcon={<CloseOutlined />}
        destroyOnClose={true}
        title={translator.formatMessage({ id: 'resetPassword' })}
        open={resetFormVisibility}
        onCancel={() => setResetFormVisibility(false)}
        footer={null}
      >
        <Form
          initialValues={{ language: 'tr' }}
          className='mx-auto'
          layout='vertical'
          form={resetForm}
          onFinish={resetFormHandler}
        >
          <Form.Item label={translator.formatMessage({ id: 'emailReset' })} name='email'>
            <Input
              placeholder={translator.formatMessage({ id: 'enterEmail' })}
              onChange={(e) => setUsername(e.target.value)}
              size='large'
              prefix={<MailOutlined className='mr-2' />}
            />
          </Form.Item>
          <Form.Item
            label={translator.formatMessage({ id: 'resetPassLanguageLabel' })}
            name='language'
          >
            <Select
              style={{
                width: '100%',
              }}
              size='large'
              className='!rounded-[8px]'
              showSearch
              filterOption={(input, option) =>
                (option.label.toLocaleLowerCase() ?? '').includes(input.toLocaleLowerCase())
              }
              placeholder={translator.formatMessage({ id: 'resetPassLanguageLabel' })}
              options={[
                {
                  label: 'TR',
                  value: 'tr',
                },
                {
                  label: 'EN',
                  value: 'en',
                },
              ]}
            />
          </Form.Item>
          <Button
            loading={mailLoading}
            type='primary'
            htmlType='submit'
            icon={<SendOutlined className='me-2' />}
          >
            <Text className='text-inherit'>
              <FormattedMessage id='send' />
            </Text>
          </Button>
        </Form>
      </Modal>
    </Form>
  );
};

export default LoginForm;
