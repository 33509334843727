import { useContext } from 'react';
import DashboardLayout from '../layouts/DashboardLayout';
import AppRouter from '../router/AppRouter';
import UserLayout from '../layouts/UserLayout';
import { AuthContext } from '../providers/AuthProvider';
import MessageBar from '../components/message/desktop/MessageBar';
import LinksWithProfileMobile from '../components/header/LinksWithProfileMobile';
import useIsPath from '../hooks/useIsPath';
import useWindowSize from '../hooks/useWindowSize';
import ConfirmModal from '../components/utility/ConfirmModal';
import { ModalProviderContext } from '../providers/ModalProvider';
import { DrawerProviderContext } from '../providers/DrawerProvider';
import { Drawer } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { UtilityContext } from '../providers/UtilityProvider';

const InnerLayout = () => {
  const { drawerState } = useContext(DrawerProviderContext);
  const { modalState } = useContext(ModalProviderContext);
  const { isManager, user } = useContext(AuthContext);
  const { theme, isUserKey } = useContext(UtilityContext);
  const isPreRegister = window.location.pathname === '/';
  const isPrivacyPolicy = window.location.pathname === '/privacy-policy';
  const isTermsOfService = window.location.pathname === '/terms-of-service';
  const isActivateUserPage = window.location.pathname === `/activate/${isUserKey}`;
  const isHome = useIsPath('home');
  const isShared = useIsPath('shared');

  const { width, height } = useWindowSize();

  let dashboardLayout = (
    <DashboardLayout>
      <AppRouter />
    </DashboardLayout>
  );

  let userLayout = (
    <UserLayout>
      <AppRouter />
      {!isPreRegister &&
        !isPrivacyPolicy &&
        !isTermsOfService &&
        !isActivateUserPage &&
        !isHome &&
        (!isShared || user) &&
        width > 780 && <MessageBar />}
      {width < 1000 && (!isShared || user) && <LinksWithProfileMobile />}
      <ConfirmModal
        title={modalState?.title}
        open={modalState?.open}
        onOk={modalState?.onOk}
        okText={modalState?.okText}
        okButtonProps={modalState?.okButtonProps}
        onCancel={modalState?.onCancel}
        footer={modalState?.footer}
      >
        {modalState?.content}
      </ConfirmModal>
      <Drawer
        closeIcon={<CloseOutlined className='duration-300' />}
        title={drawerState.title}
        open={drawerState.open}
        onClose={drawerState.onCancel}
        footer={drawerState.footer}
        width={drawerState.width}
        {...drawerState.drawerProps}
      >
        {drawerState.content}
      </Drawer>{' '}
    </UserLayout>
  );

  if (isPreRegister) {
    return <AppRouter />;
  }

  return isManager ? dashboardLayout : userLayout;
};

export default InnerLayout;
