import React, { useContext, useState } from 'react';
import { Row, Col, Card, Image, Flex, Typography, Drawer } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faL, faUser, faUserLock, faUserShield } from '@fortawesome/free-solid-svg-icons';
import { useIntl } from 'react-intl';
import BlockedList from '../components/settings/BlockedList';
import useWindowSize from '../hooks/useWindowSize';
import { UtilityContext } from '../providers/UtilityProvider';
import { CloseOutlined } from '@ant-design/icons';
import AccountSettings from '../components/settings/AccountSettings';
import variables from '../style/_variables.module.scss';
const { Text } = Typography;
const UserSettings = () => {
  const { darkModeNotificationsBorderColor, tableBorderColor } = variables;
  const translator = useIntl();
  const { Text } = Typography;
  const { theme } = useContext(UtilityContext);
  const { width, height } = useWindowSize();
  const services = [
    {
      title: translator.formatMessage({ id: 'account' }),
      key: 'account',
      icon: (
        <FontAwesomeIcon
          className='text-teal-500 text-xl transition-all duration-250 rounded-full outline-none mr-1'
          icon={faUser}
        />
      ),
    },
    {
      title: translator.formatMessage({ id: 'blockedList' }),
      key: 'blockedList',
      icon: (
        <FontAwesomeIcon
          className='text-danger text-xl transition-all duration-250 rounded-full outline-none mr-1'
          icon={faBan}
        />
      ),
    },
    /*{
      title: 'Profili düzenle',
      key: 'updateProfile',
      icon: (
        <FontAwesomeIcon
          className='text-danger text-3xl transition-all duration-250 rounded-full outline-none'
          icon={faUser}
        />
      ),
    },
    {
      title: 'Bilgilerim ve izinlerim',
      key: 'informationPermission',
      icon: (
        <FontAwesomeIcon
          className='text-danger text-3xl transition-all duration-250 rounded-full outline-none'
          icon={faUserShield}
        />
      ),
    },
    {
      title: 'Şifre ve güvenlik',
      key: 'passwordSecurity',
      icon: (
        <FontAwesomeIcon
          className='text-danger text-3xl transition-all duration-250 rounded-full outline-none'
          icon={faUserLock}
        />
      ),
    },*/
  ];

  return (
    <Row className='px-4' gutter={[16, 16]}>
      <>
        {services.map((item, index) => (
          <Col span={width > 760 ? 8 : 24} key={index}>
            <Card
              bordered={true}
              title={
                <Flex align='center' gap='.25rem'>
                  <Text>{item.icon}</Text>
                  <Text>{item.title}</Text>
                </Flex>
              }
              className='h-96 w-full'
            >
              {item.key === 'account' && <AccountSettings />}
              {item.key === 'blockedList' && <BlockedList />}
            </Card>
          </Col>
        ))}
      </>
    </Row>
  );
};

export default UserSettings;
