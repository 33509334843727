import styles from '../../style/mainHeader.module.scss';
import { Button, Col, Flex, Image, Row, Typography, Modal, Space } from 'antd';
import gameActorLogo from '../../assets/images/game-actor-logo.png';
import gameActorLogoWhite from '../../assets/images/game-actor-white.svg';
import LinksWithProfile from './LinksWithProfile';
import MainSearch from './MainSearch';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import useIsPath from '../../hooks/useIsPath';
import { FormattedMessage } from 'react-intl';
import { useContext, useState } from 'react';
import { AuthContext } from '../../providers/AuthProvider';
import useWindowSize from '../../hooks/useWindowSize';
import { UtilityContext } from '../../providers/UtilityProvider';
import { CloseOutlined } from '@ant-design/icons';

const { Text } = Typography;

const MainHeader = () => {
  const { theme } = useContext(UtilityContext);
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const { width, height } = useWindowSize();
  const isShared = useIsPath('shared');
  const [loginModal, setLoginModal] = useState(false);
  const { pathname } = useLocation();
  return (
    <Row gutter={16} className={styles['mainHeader']}>
      <Col
        className={styles['mainHeaderLeft']}
        xxl={!isShared || user ? 10 : 24}
        xl={!isShared || user ? 10 : 24}
        lg={!isShared || user ? 10 : 24}
        md={24}
        sm={24}
        xs={24}
      >
        <Col span={7}>
          {!isShared || user ? (
            <Link
              onClick={() => {
                if (pathname === '/feed') {
                  navigate('/feed');
                  window.location.reload();
                }
              }}
              className='flex flex-col'
              to={'/feed'}
            >
              <Image
                className='max-w-[135px]'
                preview={false}
                src={theme ? gameActorLogoWhite : gameActorLogo}
                alt='Game Actor'
              />
            </Link>
          ) : (
            <Link
              to={'/feed'}
              onClick={() => {
                if (pathname === '/feed') {
                  navigate('/feed');
                  window.location.reload();
                }
              }}
              className='flex flex-col cursor-pointer'
            >
              <Image
                className='max-w-[135px]'
                preview={false}
                src={theme ? gameActorLogoWhite : gameActorLogo}
                alt='Game Actor'
                onClick={() => setLoginModal(true)}
              />
            </Link>
          )}
        </Col>
        <Col span={17}>
          {!isShared || user ? (
            <MainSearch />
          ) : (
            <Flex className='h-full' align='center' justify='end'>
              <Button type='primary'>
                <Link to='/'>
                  <Text className='!text-inherit'>
                    <FormattedMessage id='login' />
                  </Text>
                </Link>
              </Button>
            </Flex>
          )}
        </Col>
      </Col>
      <Modal
        closeIcon={<CloseOutlined className='duration-300' />}
        centered
        open={loginModal}
        onCancel={() => setLoginModal(false)}
        footer={() => null}
      >
        <div className='flex flex-col'>
          <Image className='max-w-[135px]' preview={false} src={gameActorLogo} alt='Game Actor' />
          <div style={{ marginTop: '13px' }}>
            <FormattedMessage id='loginModalInformation' />
          </div>
        </div>
        <div className='flex justify-end mt-4'>
          <Button type='primary'>
            <Link to='/'>
              <Text className='text-inherit'>
                <FormattedMessage id='login' />
              </Text>
            </Link>
          </Button>
        </div>
      </Modal>
      {width > 1000 && (!isShared || user) && <LinksWithProfile />}
    </Row>
  );
};

export default MainHeader;
