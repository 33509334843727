import React, { useContext, useEffect, useState } from 'react';
import { UtilityContext } from '../providers/UtilityProvider';
import { useParams } from 'react-router-dom';
import { Typography, Spin } from 'antd';
import SpinWrapper from '../components/utility/wrappers/SpinWrapper';
import { useActivatedUser } from '../hooks/queries/UserQueries';
import { AuthContext } from '../providers/AuthProvider';
import { FormattedMessage } from 'react-intl';
import { LoadingOutlined } from '@ant-design/icons';
const ActivationUser = () => {
  const { Title } = Typography;
  const { setIsUserKey, openNotification } = useContext(UtilityContext);
  const { userKey } = useParams();
  const { mutateAsync: activatedUser } = useActivatedUser();
  const [isLoading, setIsLoading] = useState(true);
  const { login } = useContext(AuthContext);
  setIsUserKey(userKey);

  useEffect(() => {
    activatedUser(userKey)
      .then((response) => {
        if (response.status === 202) {
          openNotification('userActivatedCompleted', 'success');
          login(response.data,null);
        } else {
          openNotification('unknownError', 'error');
        }
      })
      .catch((err) => openNotification('unknownError', 'error'))
      .finally(() => setIsLoading(false));
  }, []);

  return (
    <div className='w-full h-[100vh] flex justify-center items-center'>
      <Title level={3}>
        {!isLoading && <FormattedMessage id='userActivated' />}
        <SpinWrapper />
      </Title>
    </div>
  );
};

export default ActivationUser;
