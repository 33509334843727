import { Button, Col, Flex, Form, Image, Row, Typography } from 'antd';
import styles from '../../../style/postCreate.module.scss';
import { FileImageTwoTone, SmileOutlined } from '@ant-design/icons';
import FileUploader from '../../../helpers/utils/FileUploader';
import { FormattedMessage } from 'react-intl';
import PrimaryCard from '../../cards/PrimaryCard';
import variables from '../../../style/_variables.module.scss';
import React, { useContext, useEffect, useState } from 'react';
import { UtilityContext } from '../../../providers/UtilityProvider';
import { usePostOrganizationFeed } from '../../../hooks/queries/FeedQueries';
import TipTap from '../../editor/TipTap';
import OutsideClickDetector from '../../hoc/OutsideClickDetector';
import useFileUploader from '../../../hooks/useFileUploader';

const { Text } = Typography;

const CreateOrganizationPost = ({
  organizationImage,
  organizationName,
  organizationCategory,
  organizationId,
}) => {
  const [isMedia, setIsMedia] = useState(false);
  const [fileArray, setFileArray] = useState([]);
  const [emojiPickerDisplay, setEmojiPickerDisplay] = useState(false);
  const [editorString, setEditorString] = useState('');
  const [createButtonDisabled, setCreateButtonDisabled] = useState(true);
  const [postLoading, setPostLoading] = useState(false);
  const { handleFileUpload } = useFileUploader();

  const { mutateAsync: postFeed } = usePostOrganizationFeed(organizationId);

  const { openNotification } = useContext(UtilityContext);

  let [postForm] = Form.useForm();

  useEffect(() => {
    // TODO: fileArray dosya yüklendiğinde array olmaktan çıkıyor ve length undefined kalıyor.
    if (
      (editorString &&
        editorString.length > 0 &&
        editorString.replace(/<(?!img\b)[^>]*>|\s+/g, '') !== '') ||
      (fileArray && fileArray.length != 0)
    ) {
      setCreateButtonDisabled(false);
    } else {
      setCreateButtonDisabled(true);
    }
  }, [editorString, fileArray]);

  const createPostHandler = async (formValues) => {
    setPostLoading(true);

    const feedAttachmentsArray = await handleFileUpload(fileArray);

    let feedObject = {
      content: formValues.content,
      attachmentArray: feedAttachmentsArray,
    };

    postFeed(feedObject)
      .then((response) => {
        if (response.status === 202) {
          postForm.resetFields();
          setIsMedia(false);
          openNotification('postCreated', 'success');
        } else {
          openNotification('postFailed', 'error');
        }
        setCreateButtonDisabled(true);
      })
      .finally(() => setPostLoading(false));
  };

  const tipTapOnChangeHandler = (tipTapValue) => {
    postForm.setFieldValue('content', tipTapValue);
    setEditorString(tipTapValue);
  };

  const backFireOfEmojiArea = () => {
    if (emojiPickerDisplay) {
      setEmojiPickerDisplay(!emojiPickerDisplay);
    }
  };

  const { focusedPrimaryColor } = variables;

  return (
    <PrimaryCard>
      <Form
        initialValues={{
          content: '',
        }}
        className='width-100'
        onFinish={createPostHandler}
        form={postForm}
      >
        <Col className='flex justify-start mb-[1rem]' span={24}>
          <div className={`${styles['imageWrapper']}`}>
            <Image
              className='rounded-[8px]'
              width={55}
              height={45}
              preview={false}
              src={organizationImage}
            />
          </div>
          <div>
            <Text className='d-block ml-[1rem]'>{organizationName}</Text>
            <Text className={`d-block ml-[1rem] ${styles['userTeam']}`}>
              {organizationCategory}
            </Text>
          </div>
        </Col>
        <Row gutter={[16, 0]} className={styles['postCreationRow']}>
          {isMedia && (
            <Col span={24}>
              <FileUploader
                bucketCallBack={(file) => {
                  setFileArray(file);
                }}
                multiple={true}
                formName={'media'}
                placeholderInline='media'
                accept={window.config.acceptedMediaTypes}
              />
            </Col>
          )}
          <Col span={24}>
            <OutsideClickDetector onOutsideClick={backFireOfEmojiArea}>
              <Col span={24}>
                <Form.Item name='content'>
                  <TipTap
                    id='selamselam'
                    isEmojiVisible={emojiPickerDisplay}
                    emojiViewHandler={() => setEmojiPickerDisplay(!emojiPickerDisplay)}
                    forwardOnChange={tipTapOnChangeHandler}
                  />
                </Form.Item>
              </Col>
              <Col className={styles['actionWrapper']} span={6}>
                <Flex gap='1rem'>
                  <Button
                    icon={<FileImageTwoTone twoToneColor={focusedPrimaryColor} />}
                    onClick={() => setIsMedia(!isMedia)}
                    className='ml-auto'
                  />
                  <Button
                    onClick={() => setEmojiPickerDisplay(!emojiPickerDisplay)}
                    icon={<SmileOutlined />}
                  />
                  <Button
                    loading={postLoading}
                    type='primary'
                    htmlType='submit'
                    disabled={createButtonDisabled}
                  >
                    <Text className='text-inherit'>
                      <FormattedMessage id='create' />
                    </Text>
                  </Button>
                </Flex>
              </Col>
            </OutsideClickDetector>
          </Col>
        </Row>
      </Form>
    </PrimaryCard>
  );
};

export default CreateOrganizationPost;
