import styles from '../style/postpage.module.scss';
import { Row, Col, Button, Typography } from 'antd';
import { useGetFeedHandler } from '../hooks/queries/FeedQueries';
import { useInView } from 'react-intersection-observer';
import PostCard from '../components/post/PostCard';
import InterestedGames from '../components/feed/InterestedGames';
import CreatePostForm from '../components/form/post/CreatePostForm';
import Toolbar from '../components/utility/Toolbar';
import { Fragment, useEffect, useState } from 'react';
import Repost from '../components/post/Repost';
import SpinWrapper from '../components/utility/wrappers/SpinWrapper';
import { useParams, useSearchParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { useSaveUserDevice } from '../hooks/queries/UserQueries';
import EventCalendar from '../components/feed/EventCalendar';
const { Text, Title } = Typography;

const PostPage = () => {
  const [selectedFeedType, setSelectedFeedType] = useState(2);
  const { hashtag } = useParams();
  const { ref, inView } = useInView();
  const [searchParams, setSearchParams] = useSearchParams();
  const { mutate: saveUserDeviceHandler } = useSaveUserDevice();
  const {
    data: feed,
    fetchNextPage,
    isLoading,
  } = useGetFeedHandler(null, hashtag, selectedFeedType);

  useEffect(() => {
    const deviceToken = searchParams.get('device_token');
    if (deviceToken?.length > 0) {
      localStorage.setItem('device_token', deviceToken);
      saveUserDeviceHandler(deviceToken);
    }
  }, []);

  useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);

  if (isLoading) {
    return <SpinWrapper />;
  }

  return (
    <Row className={styles['postPageWrapper']} gutter={[16, 16]}>
      <Col xxl={5} xl={5} lg={5} md={5} sm={24}>
        <Row gutter={[16, 16]} className={styles['stickyRow']}>
          {/*<Col span={24}>
            <QuickTeamDetails />
          </Col>*/}
          <Col className='flex gap-4' span={24}>
            <Button
              onClick={() => setSelectedFeedType(2)}
              type={selectedFeedType === 2 ? 'primary' : 'default'}
            >
              <Text className='text-inherit'>
                <FormattedMessage id='discover' />
              </Text>
            </Button>
            <Button
              onClick={() => setSelectedFeedType(1)}
              type={selectedFeedType === 1 ? 'primary' : 'default'}
            >
              <Text className='text-inherit'>
                <FormattedMessage id='myFeed' />
              </Text>
            </Button>
          </Col>
          <Col xxl={0} xl={0} lg={0} md={0} sm={24} xs={24}>
            <Toolbar />
          </Col>
          <Col span={24}>
            <InterestedGames />
          </Col>
        </Row>
      </Col>
      <Col className={styles['feedWrapper']} xxl={13} xl={13} lg={13} md={13}>
        <Row justify='center' gutter={[16, 16]}>
          <Col span={24}>
            <CreatePostForm />
          </Col>
          <>
            {feed?.pages.map((page, pageIndex) => (
              <Fragment key={pageIndex}>
                {page.rows.map((post, index) => {
                  if ((index + 1) % 3 === 0) {
                    return (
                      <Col ref={index + 1 === page.rows.length ? ref : null} key={index} span={24}>
                        {post.relatedFeedId > 0 ? (
                          <Repost postDetail={post} postId={post.id} boosted={false} />
                        ) : (
                          <PostCard
                            key={post.id}
                            postDetail={post}
                            share={post?.share}
                            postId={post.id}
                            boosted={false}
                          />
                        )}
                      </Col>
                    );
                  } else {
                    return (
                      <Col ref={index + 1 === page.rows.length ? ref : null} key={index} span={24}>
                        {post.relatedFeedId > 0 ? (
                          <Repost postDetail={post} postId={post.id} boosted={false} />
                        ) : (
                          <PostCard
                            postDetail={post}
                            share={post?.share}
                            postId={post.id}
                            boosted={false}
                          />
                        )}
                      </Col>
                    );
                  }
                })}
              </Fragment>
            ))}
          </>
        </Row>
      </Col>
      <Col xxl={6} xl={6} lg={6} md={6} sm={0} xs={0}>
        <Row gutter={[16, 16]} className={styles['stickyRow']}>
          <Col span={24}>
            <Toolbar />
          </Col>
          <Col span={24}>
            <EventCalendar />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default PostPage;
